import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/shared/auth.service';
import { ServerFile } from '../../core-services/model/server-file';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { UploadSaveEvent } from '../../projects-modules/machine-services/model/upload-save-event';
import { ProjectDocument } from '../../projects-services/model/project-document';
import { MachineDocument } from '../../settings-modules/machine-documents/model/machine-document';

@Injectable({
  providedIn: 'root'
})
export class ManageProjectDocumentsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';

  private logger: ILogger;
  private projectId: string;

  constructor(private authService: AuthService, private http: HttpClient, logger: LoggerService) {
    this.http = http;
    this.logger = logger.getLogger('ManageProjectDocumentsService');
  }

  public setProject(projectId: string): ManageProjectDocumentsService {
    this.projectId = projectId;
    return this;
  }

  public create(file: File): Observable<any> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Creating document for project {0}', this.projectId);

    const method = 'POST';
    let url = [this.apiUrl, this.projectId, 'documents'].join('/');

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .request<ProjectDocument>(
        new HttpRequest(method, url, formData, {
          reportProgress: true
        })
      )
      .pipe(
        map((event) => {
          const saveEvent = new UploadSaveEvent();

          if (event.type === HttpEventType.UploadProgress) {
            saveEvent.progress = Math.round((100 * event.loaded) / event.total);
          } else if (event.type === HttpEventType.Response) {
            saveEvent.completed = true;

            if (event.body) {
              saveEvent.id = event.body['id'];
            }
          }
          return saveEvent;
        }),
        catchError((err, caught) => {
          this.logger.error('Error saving project document: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }

  public update(projectDocument: ProjectDocument): Observable<ProjectDocument> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Creating document for project {0}', this.projectId);

    let url = [this.apiUrl, this.projectId, 'documents', projectDocument.id].join('/');

    return this.http
      .request<ProjectDocument>('PUT', url, {
        body: projectDocument
      })
      .pipe(
        catchError((err, caught) => {
          this.logger.error('Error updating project document: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }

  public getProjectDocumentURL(doc: ProjectDocument): Observable<string> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Getting document {0} url for project {1}', doc.id, this.projectId);

    const url = [this.apiUrl, this.projectId, 'documents', doc.id].join('/');

    return this.http.get(url, { responseType: 'blob' }).pipe(map((blob) => window.URL.createObjectURL(blob)));
  }

  public getProjectDocumentPreviewURL(doc: ProjectDocument, size: string, extra?: string): Observable<string> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Getting document {0} url for project {1}', doc.id, this.projectId);

    const url = [this.apiUrl, this.projectId, 'documents', doc.id, 'preview'].join('/') + '?size=' + size + (extra ? '&' + extra : '');

    return this.http.get(url, { responseType: 'blob' }).pipe(map((blob) => window.URL.createObjectURL(blob)));
  }

  public download(): Observable<ServerFile>;
  public download(documents: Array<string>): Observable<ServerFile>;
  public download(documents?: Array<string>): Observable<ServerFile> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Downloading all documents for project {0}', this.projectId);

    let url: string = [this.apiUrl, this.projectId, 'documents'].join('/');

    if (documents) {
      url = `${url}?ids=${documents.join('&ids=')}`;
    }

    const httpOptions = {};
    httpOptions['responseType'] = 'blob';
    httpOptions['observe'] = 'response';

    return this.http.get<HttpResponse<Blob>>(url, httpOptions).pipe(
      tap((_) => {
        this.logger.debug('Documents downloaded');
      }),
      map((response) => {
        const disposition = response.headers.get('Content-Disposition');
        let filename = '';

        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        return {
          blob: response.body,
          name: filename
        };
      }),
      catchError((err, caught) => {
        this.logger.error('Error downloading documents: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public delete(documents: Array<string>): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Deleting {0} project documents in project {1}', documents ? documents.length : 0, this.projectId);

    let url = [this.apiUrl, this.projectId, 'documents'].join('/');

    return this.http
      .request<void>('DELETE', url, {
        body: documents
      })
      .pipe(
        tap(() => {
          this.logger.debug('Project documents deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting project documents: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }

  public archive(documents: Array<string>): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Archiving {0} project documents in project {1}', documents ? documents.length : 0, this.projectId);

    let url = [this.apiUrl, this.projectId, 'documents', 'archive'].join('/');

    return this.http
      .request<void>('POST', url, {
        body: documents
      })
      .pipe(
        tap(() => {
          this.logger.debug('Project documents archived');
        }),
        catchError((err, caught) => {
          this.logger.error('Error archiving project documents: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }
}
