import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { LoggerService, ILogger } from '../../shared/logger.service';
import { AuthService } from '../../../auth/shared/auth.service';

@Component({
  selector: 'font-size',
  templateUrl: './font-size.component.html',
  styleUrls: ['./font-size.component.scss']
})
export class FontSizeComponent implements OnInit {

  public large: boolean;

  private logger: ILogger;

  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    logger: LoggerService
  ) { 
    this.logger = logger.getLogger('Layout:FontSizeComponent');
  }

  public ngOnInit(): void {
    this.logger.debug('Initializing component');

    this.large = this.getFontSize() !== 'small';
    this.setBodyClass();
  }

  public toggle(): void {
    this.logger.debug('Toggling font size');

    this.large = !this.large;
    
    this.setBodyClass();
    this.setFontSize(this.large ? 'large' : 'small');
  }

  private setBodyClass(): void {
    if (this.large) {
      this.renderer.removeClass(document.documentElement, 'font-small');
      this.renderer.addClass(document.documentElement, 'font-large');
    } else {
      this.renderer.removeClass(document.documentElement, 'font-large');
      this.renderer.addClass(document.documentElement, 'font-small');
    }
  }

  private getFontSize(): string {
    return localStorage.getItem(`mcomplus/${this.authService.user.id}/font`);
  }

  private setFontSize(size: string): void {
    localStorage.setItem(`mcomplus/${this.authService.user.id}/font`, size);
  }
}
