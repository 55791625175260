
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Confirmation } from '../model/Confirmation';




@Injectable()
export class ConfirmationService {

  private confirmationCache: { 
    [key: string]: { 
      confirmation: Confirmation,
      observer: Subject<boolean> 
    } 
  } = {};

  public register(confirmation: Confirmation): void {
    this.confirmationCache[confirmation.name] = { confirmation: confirmation, observer: undefined };
  }

  public unregister(confirmation: Confirmation): void {
    this.confirmationCache[confirmation.name] = undefined;
  }

  public cancel(confirmation: Confirmation): void {
    const cached = this.confirmationCache[confirmation.name];

    if (cached != null && cached.observer != null) {
      cached.observer.next(false);
      cached.observer.complete();
      cached.observer = undefined;
    }
  }

  public confirm(confirmation: Confirmation): void {
    const cached = this.confirmationCache[confirmation.name];

    if (cached != null && cached.observer != null) {
      cached.observer.next(true);
      cached.observer.complete();
      cached.observer = undefined;
    }
  }

  public show(name: string, scrollToConfirmation: boolean= false): Observable<boolean> {
    const cached = this.confirmationCache[name];

    if (cached != null) {
      cached.confirmation.show = true;
      
      if(scrollToConfirmation) {
        cached.confirmation.scrollToConfirmation();
      }

      cached.observer = new Subject<boolean>();

      return cached.observer;
    } else {
      return observableThrowError(`Confirmation '${name}' is not registered`);
    }
  }
}
