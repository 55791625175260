
import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ElementRef } from '@angular/core';

import { ConfirmationService } from '../shared/confirmation.service';
import { Confirmation } from '../model/Confirmation';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'button-confirmation',
  templateUrl: './button-confirmation.component.html',
  styleUrls: ['./button-confirmation.component.scss']
})
export class ButtonConfirmationComponent implements OnInit, OnDestroy, Confirmation {

  @Input() name: string;
  @Input() group: string;
  @Input() message: string = this.translateService.instant('confirmation.delete');
  @Input() btnClasses: string[] = [];

  @Input()
  get show(): boolean {
    return this.isShowing;
  }
  set show(val: boolean) {
    this.isShowing = val;
    this.showChange.emit(this.isShowing);
  }

  @Output() showChange = new EventEmitter();

  private isShowing = false;

  constructor(
    private element: ElementRef<HTMLElement>,
    private confirmationService: ConfirmationService,
    private translateService:TranslateService) { }

  ngOnInit(): void {
    if (!this.name) throw new Error("Confirmation must have a 'name' attribute.");

    this.confirmationService.register(this);
  }

  ngOnDestroy(): void {
    this.confirmationService.unregister(this);
  }

  public cancel(): void {
    this.show = false;
    this.confirmationService.cancel(this);
  }

  public confirm() {
    this.show = false;
    this.confirmationService.confirm(this);
  }

  public scrollToConfirmation(): void {
    this.element.nativeElement.scrollIntoView({behavior: "smooth", block: "end"});
  }
}

