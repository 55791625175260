import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { MachineDeclaration } from '../../machine-services/model/machine-declaration';
import { ServerFile } from '../../../core-services/model/server-file';

@Injectable()
export class MachineDeclarationsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('MachineDeclarationsService');
  }

  public setProject(projectId: string): MachineDeclarationsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): MachineDeclarationsService {
    this.machineId = machineId;
    return this;
  }

  public getDeclarations(filter?: QueryFilter): Observable<QueryResult<MachineDeclaration>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting control integrities for machine {0} from project {1}', this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'declarations'].join('/');

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<MachineDeclaration>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No machine declarations found');
          return result;
        }

        this.logger.debug('Machine declarations found');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine declarations: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getDeclaration(id: string): Observable<MachineDeclaration> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting machine declaration {0} for machine {1} from project {2}', id, this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'declarations', id].join('/');

    return this.http.get<MachineDeclaration>(url).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No machine declaration found');
          return result;
        }

        this.logger.debug('Machine declaration found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine declaration: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public generate(declaration: MachineDeclaration): Observable<ServerFile> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug(
      'Generating machine declaration with id {0} in machine {1} from project {2}',
      declaration.id,
      this.machineId,
      this.projectId
    );

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'declarations', declaration.id, 'report'].join('/');

    const httpOptions = {};
    httpOptions['responseType'] = 'blob';
    httpOptions['observe'] = 'response';

    return this.http.post<HttpResponse<Blob>>(url, null, httpOptions).pipe(
      tap((_) => {
        this.logger.debug('Report generated');
      }),
      map((response) => {
        const disposition = response.headers.get('Content-Disposition');
        let filename = '';

        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        return {
          blob: response.body,
          name: filename
        };
      }),
      catchError((err, caught) => {
        this.logger.error('Error generating machine declaration: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
