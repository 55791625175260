import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { ProjectDocument } from '../model/project-document';
import { ProjectsService } from './projects.service';
import { AuthService } from '../../auth/shared/auth.service';

@Injectable()
export class ProjectDocumentsService {
  private logger: ILogger;
  private projectId: string;

  constructor(private authService: AuthService, private projectsService: ProjectsService, loggerService: LoggerService) {
    this.logger = loggerService.getLogger('ProjectDocumentsService');
  }

  public setProject(projectId: string): ProjectDocumentsService {
    this.projectId = projectId;
    return this;
  }

  public getProjectDocuments(): Observable<Array<ProjectDocument>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Getting documents for project {0}', this.projectId);

    return this.projectsService.getProject(this.projectId).pipe(
      first(),
      map((project) => {
        const providerId = this.authService.user.provider;
        const organizationId = this.authService.user.organization;
        let allowedCategories = [];

        if (organizationId === providerId) {
          allowedCategories = ['mandatory', 'customer', 'operator', '', null, undefined];
        } else {
          if (organizationId === project.company.id) {
            allowedCategories = ['mandatory', 'customer'];
          } else if (project.operator && organizationId === project.operator.id) {
            allowedCategories = ['mandatory', 'operator'];
          }
        }

        const documents = (project.documents && project.documents.filter((item) => allowedCategories.includes(item.category))) || [];
        return JSON.parse(JSON.stringify(documents)); //create copy because project instance is shared
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting project machine documents: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public getProjectDocument(documentId: string): Observable<ProjectDocument> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Getting document with id {0} from project {1}', documentId, this.projectId);

    return this.projectsService.getProject(this.projectId).pipe(
      first(),
      map((project) => {
        const providerId = this.authService.user.provider;
        const organizationId = this.authService.user.organization;
        let allowedCategories = [];

        if (organizationId === providerId) {
          allowedCategories = ['mandatory', 'customer', 'operator', '', null, undefined];
        } else {
          if (organizationId === project.company.id) {
            allowedCategories = ['mandatory', 'customer'];
          } else if (project.operator && organizationId === project.operator.id) {
            allowedCategories = ['mandatory', 'operator'];
          }
        }

        const projectDocument =
          project.documents &&
          project.documents.filter((item) => allowedCategories.includes(item.category)).find((item) => item.id === documentId);

        return projectDocument && JSON.parse(JSON.stringify(projectDocument)); //create copy because project instance is shared
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting project document: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
