import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { FormGroup, FormGroupDirective, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appRequiredLabel]'
})
export class RequiredLabelDirective implements AfterViewInit {
  constructor(private fg: FormGroupDirective, private el: ElementRef) {}

  ngAfterViewInit() {
    const formGroup = this.fg.form;
    this.processFormGroup(formGroup);
  }
  processFormGroup(formGroup) {
    this.processFormControls(formGroup.controls);
    const formGroups = Object.keys(formGroup.controls).filter((key) => formGroup.controls[key] instanceof FormGroup);

    if (formGroups.length > 0) {
      formGroups.forEach((key) => {
        this.processFormGroup(formGroup.controls[key]);
      });
    }
  }

  processFormControls(controls: { [key: string]: AbstractControl }) {
    for (const key in controls) {
      if (controls[key].validator && controls[key].validator({} as any) && controls[key].validator({} as any).required) {
        const label = this.el.nativeElement.querySelector(`label[for="${key}"]`);
        if (label) {
          label.innerHTML = label.innerText + '*';
        }
      }
    }
  }
}
