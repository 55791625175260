import { Injectable } from '@angular/core';
import { AuthInfo } from '../model/AuthInfo';
import { AuthUser } from '../model/AuthUser';

@Injectable()
export class TokenService {
  private readonly storageKeys: { [key: string]: string } = {
    authInfo: 'mcomplus/auth/data',
    user: 'mcomplus/auth/user',
    organization: 'mcomplus/auth/toggledOrganization'
  };
  private authInfoCached: AuthInfo;

  public get authInfo(): AuthInfo {
    if (!this.authInfoCached) {
      this.authInfoCached = Object.assign({}, JSON.parse(this.read(this.storageKeys.authInfo)));
    }

    return this.authInfoCached;
  }

  public set authInfo(authInfo: AuthInfo) {
    this.authInfoCached = authInfo;
    this.store(this.storageKeys.authInfo, JSON.stringify(authInfo));
  }

  public get userProfile(): AuthUser {
    return Object.assign({}, JSON.parse(this.read(this.storageKeys.user)));
  }

  public set userProfile(user: AuthUser) {
    this.store(this.storageKeys.user, JSON.stringify(user));
  }

  public clear(): void {
    this.delete(this.storageKeys.authInfo);
    this.delete(this.storageKeys.user);
    this.delete(this.storageKeys.organization);
  }

  public hasAuthInfo(): boolean {
    return this.authInfoCached && Object.keys(this.authInfoCached).length > 0;
  }

  public decodeToken(token: string): any {
    if (token === null) {
      return null;
    }

    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error(
        "The inspected token doesn't appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more."
      );
    }

    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token.');
    }

    return JSON.parse(decoded);
  }

  public getTokenExpirationDate(token: string): Date {
    const decoded = this.decodeToken(token);

    if (!decoded.hasOwnProperty('exp')) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  public isTokenExpired(token: string, offsetSeconds?: number): boolean {
    if (token === null || token === '') {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;

    if (date === null) {
      return true;
    }

    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  protected store(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  protected read(key: string): string {
    return sessionStorage.getItem(key);
  }

  protected delete(key: string): void {
    sessionStorage.removeItem(key);
  }

  private urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw 'Illegal base64url string!';
      }
    }
    return this.b64DecodeUnicode(output);
  }

  // credits for decoder goes to https://github.com/atk
  private b64decode(str: string): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';

    str = String(str).replace(/=+$/, '');

    if (str.length % 4 === 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }

    for (
      // initialize result and counters
      let bc = 0, bs: any, buffer: any, idx = 0;
      // get next character
      (buffer = str.charAt(idx++));
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer &&
      ((bs = bc % 4 ? bs * 64 + buffer : buffer),
      // and if not first of each 4 characters,
      // convert the first 8 bits to one ascii character
      bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }

  private b64DecodeUnicode(str: any) {
    return decodeURIComponent(
      Array.prototype.map.call(this.b64decode(str), (c: any) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')
    );
  }
}
