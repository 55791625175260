import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { QueryFilter } from '../../core/query-filter/model/QueryFilter';
import { UserNotification } from '../model/user-notification';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { QueryResult } from '../../core/query-filter/model/QueryResult';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { UserNotificationHandler } from './user-notification-handler';
import { HazardImplementationHandlerService } from './hazard-implementation-handler.service';
import { ProjectMachineDocumentHandlerService } from './project-machine-document-handler.service';
import { ProjectDocumentHandlerService } from './project-document-handler.service';

@Injectable()
export class UserNotificationsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/notifications';
  private logger: ILogger;
  private notificationsResolver: { [key: string]: BehaviorSubject<boolean> } = {};

  constructor(
    private http: HttpClient,
    private injector: Injector,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('UserNotificationsService');
  }

  public init(): Observable<boolean> {
    this.logger.debug('Initializing service');

    setInterval(_ => {
      this.logger.debug('Refreshing user notifications');

      Object.keys(this.notificationsResolver).forEach(key => {
        const subject = this.notificationsResolver[key];
        subject.next(true);
      })
    }, 1000 * 60 * 10); //update every 10 minutes

    return of(true);
  }

  public getNotificationTypes(): Array<{ id: string, label: string }> {
    return []
      .concat(this.injector.get(HazardImplementationHandlerService).getNotificationTypes())
      .concat(this.injector.get(ProjectMachineDocumentHandlerService).getNotificationTypes())
  }

  public getHandler(notification: UserNotification): UserNotificationHandler {
    this.logger.debug('Getting user notification handler for type', notification.type);

    if (notification.type.startsWith('hazard_')) {
      this.logger.debug('Found Hazard Implementation handler, returning');
      return this.injector.get(HazardImplementationHandlerService);
    } else if (notification.type.startsWith('projectMachineDocument_')) {
      this.logger.debug('Found project machine document handler, returning');
      return this.injector.get(ProjectMachineDocumentHandlerService);
    } else if (notification.type.startsWith('projectDocument_')) {
      this.logger.debug('Found project document handler, returning');
      return this.injector.get(ProjectDocumentHandlerService);
    }

    this.logger.debug('No user notification handler found');

    return null;
  }

  public getNotifications(queryFilter?: QueryFilter): Observable<QueryResult<UserNotification>> {
    this.logger.debug('Getting user notifications');

    const id = queryFilter && JSON.stringify(queryFilter.whereArray()) || 'all';
    let resolver = this.notificationsResolver[id];

    if (!resolver) {
      this.logger.debug('Creating resolver');
      this.notificationsResolver[id] = resolver = new BehaviorSubject<boolean>(true);
    } else {
      this.logger.debug('Returning cached resolver');
    }

    return resolver
      .pipe(
        switchMap(_ => {
          let url = this.apiUrl;

          if (queryFilter) {
            url += queryFilter.toQueryString();
          }

          return this.http.get<QueryResult<UserNotification>>(url)
            .pipe(
              tap(result => {
                if (!result || !result.data || result.data.length === 0) {
                  this.logger.debug('No user notifications found');
                  return result;
                }

                this.logger.debug('{0} user notifications found', result.data.length);
              }),
              catchError(error => {
                this.logger.error('Error getting user notifications: {0} - {1}', error['status'], error['message']);
                return throwError(error);
              })
            );
        })
      );

  }
}

