import { Injectable } from '@angular/core';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { ActionDocument } from '../model/action-document';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageMachineHazardActionDocumentsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';

  private logger: ILogger;
  private machineId: string;
  private projectId: string;
  private hazardId: string;
  private actionId: string;

  constructor(
    private http: HttpClient,
    loggerService: LoggerService
  ) {
    this.logger = loggerService.getLogger('ManageMachineHazardActionDocumentsService');
  }

  public setProject(projectId: string): ManageMachineHazardActionDocumentsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): ManageMachineHazardActionDocumentsService {
    this.machineId = machineId;
    return this;
  }

  public setHazard(hazardId: string): ManageMachineHazardActionDocumentsService {
    this.hazardId = hazardId;
    return this;
  }

  public setAction(actionId: string): ManageMachineHazardActionDocumentsService {
    this.actionId = actionId;
    return this;
  }

  public delete(documents: Array<string>): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Hazard Id not set. Use setHazard() first');
    }

    if (!this.actionId) {
      throw Error('Action Id not set. Use setAction() first');
    }

    this.logger.debug('Deleting {0} hazard action documents for action {1} in project {2}, machine {3} and hazard {4}', documents ? documents.length : 0, this.actionId, this.projectId, this.machineId, this.hazardId);

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'hazards',
      this.hazardId,
      'actions',
      this.actionId,
      'documents'
    ].join('/');

    return this.http.request<void>('DELETE', url, {
      body: documents
    })
      .pipe(
        tap(() => {
          this.logger.debug('Hazard action documents deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting hazard action documents: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }
}
