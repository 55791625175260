import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';

import { MachineComponent } from '../../machine-services/model/machine-components';


@Injectable()
export class InternalMachineComponentsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private machineId: string;
  private projectId: string;

  constructor(
    private http: HttpClient,
    logger: LoggerService
    ) {
      this.logger = logger.getLogger('InternalMachineComponentsService');
  }

  public setProject(projectId: string): InternalMachineComponentsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalMachineComponentsService {
    this.machineId = machineId;
    return this;   
  }

  public save(machineComponent: MachineComponent): Observable<MachineComponent> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Saving Machine Component with id {0} in machine {1} from project {2}', machineComponent.id, this.machineId, this.projectId);

    const method = machineComponent.id != null ? 'PUT' : 'POST';

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'components',
      (machineComponent.id != null ? machineComponent.id : '')
    ].join('/');

    return this.http.request<MachineComponent>(method, url, {
      body: machineComponent
    }).pipe(
      tap(result => {
        this.logger.debug('Machine Component saved');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving Machine Component: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public delete(machineComponent: MachineComponent): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }
    
    this.logger.debug('Deleting Machine Component with id {0} in machine {1} from project {2}', machineComponent.id, this.machineId, this.projectId);

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'components',
      machineComponent.id
    ].join('/');

    return this.http.delete<void>(url)
      .pipe(
        tap(result => {
          this.logger.debug('Machine Component deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting Machine Component: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
