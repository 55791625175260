import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';

import { MachineComponent } from '../../machine-services/model/machine-components';

@Injectable()
export class MachineComponentsService {
  private projectId: string;
  private machineId: string;
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('MachineComponentsService');
  }

  public setProject(projectId: string): this {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): this {
    this.machineId = machineId;
    return this;
  }

  public getComponents(filter?: QueryFilter): Observable<QueryResult<MachineComponent>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting components for machine {0} from project {1}', this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'Components'].join('/');

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<MachineComponent>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No components found');
          return result;
        }

        this.logger.debug('components found');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting components: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getComponent(id: string): Observable<MachineComponent> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting component {0} for machine {1} from project {2}', id, this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'Components', id].join('/');

    return this.http.get<MachineComponent>(url).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No component found');
          return result;
        }

        this.logger.debug('component found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting component: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
