import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MachineAssessmentExamination } from '../../machine-services/model/machine-assessment-examination';

@Injectable()
export class InternalExaminationsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalExaminationsService');
  }

  public setProject(projectId: string): InternalExaminationsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalExaminationsService {
    this.machineId = machineId;
    return this;
  }

  public update(examination: MachineAssessmentExamination): Observable<MachineAssessmentExamination> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Updating examination {0} for machine {1} from project {2}', examination.id, this.machineId, this.projectId);

    const method = examination.id != null ? 'PUT' : 'POST';
    const url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'examinations', examination.id].join('/');

    return this.http
      .request<MachineAssessmentExamination>(method, url, {
        body: examination
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Examination updated');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating examination: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
