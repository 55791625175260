import { Injectable, Optional } from '@angular/core';
import { ProjectMachine } from '../model/project-machine';
import { MachineAssessmentChecklist } from '../model/machine-assessment-checklist';
import { MachineAssessmentSection } from '../model/machine-assessment-section';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../auth/shared/auth.service';

@Injectable()
export class InternalManageMachinesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private authService: AuthService;

  constructor(private http: HttpClient, logger: LoggerService, authService: AuthService) {
    this.logger = logger.getLogger('InternalManageMachinesService');
    this.authService = authService;
  }

  public setProject(projectId: string): InternalManageMachinesService {
    this.projectId = projectId;
    return this;
  }

  public update(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section: MachineAssessmentSection,
    updateAction: (checklist: MachineAssessmentChecklist, section?: MachineAssessmentSection) => Array<string>,
    resetMeasurements?: boolean
  ): Observable<ProjectMachine> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!machine.assessment || !machine.assessment.checklists) {
      this.logger.error('Checklist with id {0} not found', checklist.id);
      throw Error('Asessment not found');
    }

    const internalChecklist = machine.assessment.checklists.find((item) => item.id === checklist.id);

    if (!internalChecklist) {
      this.logger.error('Checklist with id {0} not found', checklist.id);
      throw Error('Checklist not found');
    }

    let updatedIds = [];

    if (section) {
      const internalSection = internalChecklist.sections.find((item) => item.id === section.id);

      if (!internalSection) {
        this.logger.error('Section with id {0} not found', checklist.id);
        throw Error('Section not found');
      }

      updatedIds = updateAction(internalChecklist, internalSection);
    } else {
      updatedIds = updateAction(internalChecklist);
    }

    if (updatedIds && updatedIds.length > 0) {
      // remove reference from hazards to updated examinations
      machine.identifiedHazards
        .filter((item) => item.examination && updatedIds.includes(item.examination.id))
        .forEach((item) => (item.examination = null));
      if (resetMeasurements) {
        machine.measurements = machine.measurements.filter(
          (m) => m.engineer.id !== this.authService.user.id && !updatedIds.includes(m.examinationsId)
        );
      }
    }

    return this.performUpdate(machine);
  }

  performUpdate(machine: ProjectMachine): Observable<ProjectMachine> {
    this.logger.debug('Updating project machine with id {0} for project {1}', machine.id, this.projectId);

    const method = machine.id != null ? 'PUT' : 'POST';
    const url = [this.apiUrl, this.projectId, 'projectmachines'].join('/') + (machine.id != null ? '/' + machine.id : '');

    return this.http
      .request<ProjectMachine>(method, url, {
        body: machine
      })
      .pipe(
        map((_) => {
          this.logger.debug('Project machine saved');
          return machine;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating project machine: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }
}
