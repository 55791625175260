import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { MachineDocument } from '../../settings-modules/machine-documents/model/machine-document';
import { ProjectsService } from '../../projects-services/shared/projects.service';
import { AuthService } from '../../auth/shared/auth.service';
import { DocumentsProjectMachinesService } from './documents-project-machines.service';

@Injectable()
export class DocumentsProjectMachineDocumentsService {
  private logger: ILogger;
  private machineId: string;

  constructor(private authService: AuthService, private machinesService: DocumentsProjectMachinesService, loggerService: LoggerService) {
    this.logger = loggerService.getLogger('DocumentsProjectMachineDocumentsService');
  }

  public setProjectMachine(machineId: string): DocumentsProjectMachineDocumentsService {
    this.machineId = machineId;
    return this;
  }

  public getDocuments(): Observable<Array<MachineDocument>> {
    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting documents for machine {0}', this.machineId);

    return this.machinesService.getDocumentsProjectMachine(this.machineId).pipe(
      first(),
      map((machine) => {
        const providerId = this.authService.user.provider;
        const organizationId = this.authService.user.organization;
        let allowedCategories = [];

        if (organizationId === providerId) {
          allowedCategories = ['mandatory', 'customer', 'operator', '', null, undefined];
        } else {
          if (organizationId === machine.company.id) {
            allowedCategories = ['mandatory', 'customer'];
          } else {
            allowedCategories = ['mandatory', 'operator'];
          }
        }

        const documents = (machine.documents && machine.documents.filter((item) => allowedCategories.includes(item.category))) || [];
        return JSON.parse(JSON.stringify(documents)); //create copy because machine instance is shared
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting documents project machine documents: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public getDocument(documentId: string): Observable<MachineDocument> {
    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting document with id {0} from machine {1}', documentId, this.machineId);

    return this.machinesService.getDocumentsProjectMachine(this.machineId).pipe(
      first(),
      map((machine) => {
        const providerId = this.authService.user.provider;
        const organizationId = this.authService.user.organization;
        let allowedCategories = [];

        if (organizationId === providerId) {
          allowedCategories = ['mandatory', 'customer', 'operator', '', null, undefined];
        } else {
          if (organizationId === machine.company.id) {
            allowedCategories = ['mandatory', 'customer'];
          } else {
            allowedCategories = ['mandatory', 'operator'];
          }
        }

        const machineDocument =
          machine.documents &&
          machine.documents.filter((item) => allowedCategories.includes(item.category)).find((item) => item.id === documentId);

        return machineDocument && JSON.parse(JSON.stringify(machineDocument)); //create copy because machine instance is shared
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting documents project machine document: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
