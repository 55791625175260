import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'table-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  private _currentPage: number = 1;

  @Input()
  set currentPage(page: number) {
    this._currentPage = page;
  }

  get currentPage(): number {
    return this._currentPage;
  }
  private totalPages: number = 1;

  @Input() from: number = 0;
  @Input() pageSize: number = 10;
  @Input() count: number;

  @Output() fromChange: EventEmitter<number> = new EventEmitter();
  @Output() onChange: EventEmitter<number> = new EventEmitter();
  @Output() onPrevious: EventEmitter<number> = new EventEmitter();
  @Output() onNext: EventEmitter<number> = new EventEmitter();
  @Output() onJumpTo: EventEmitter<number> = new EventEmitter();

  constructor() {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {
    const count = changes['count'];
    const from = changes['from'];

    if (count && count.currentValue) {
      this.totalPages = Math.ceil(count.currentValue / this.pageSize);
    }

    if (from && from.currentValue) {
      this.currentPage = Math.ceil(from.currentValue / this.pageSize) + 1;
    }
  }

  public previous(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.from = (this.currentPage - 1) * this.pageSize;

      this.fromChange.emit(this.from);
      this.onChange.emit(this.currentPage);
      this.onPrevious.emit();
    }
  }

  public next(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.from = (this.currentPage - 1) * this.pageSize;

      this.fromChange.emit(this.from);
      this.onChange.emit(this.currentPage);
      this.onNext.emit();
    }
  }

  public jumpTo(value: number): void {
    value = +value;

    if (value >= 1 && value <= this.totalPages) {
      this.currentPage = value;
      this.from = (this.currentPage - 1) * this.pageSize;

      this.fromChange.emit(this.from);
      this.onChange.emit(this.currentPage);
      this.onJumpTo.emit();
    }
  }

}
