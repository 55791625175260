import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { AuthProvider } from '../model/AuthProvider';

@Directive({
  selector: '[isProviderEngineer]'
})
export class IsProviderEngineerDirective {

  @Input()
  public isProviderEngineer: AuthProvider;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {

  }

  public ngOnInit(): void {

    if (this.authService.isProviderEngineer(this.isProviderEngineer)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
