
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MachineHazard } from '../../machine-services/model/machine-hazard';

@Injectable()
export class ManageMachineHazardsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('ManageMachineHazardsService');
  }

  public setProject(projectId: string): ManageMachineHazardsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): ManageMachineHazardsService {
    this.machineId = machineId;
    return this;
  }

  public update(hazard: MachineHazard): Observable<MachineHazard> {

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Updating machine hazard {0} for project {1} and machine {2}', hazard.id, this.projectId, this.machineId);

    const method = 'PUT';
    const url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'hazards',
      hazard.id
    ].join('/');

    return this.http.request<MachineHazard>(method, url, {
      body: hazard
    }).pipe(
      tap(_ => {
        this.logger.debug('Machine hazard saved');
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving machine hazard: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public updateMany(hazards: Array<MachineHazard>): Observable<Array<string>> {

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Updating {0} machine hazards for project {1} and machine {2}', hazards.length, this.projectId, this.machineId);

    const method = 'PUT';
    const url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'hazards'
    ].join('/');

    if (!hazards || hazards.length === 0) {
      this.logger.debug('Nothing to update, skipping');
      return of([]);
    }

    return this.http.request(method, url, {
      body: hazards
    }).pipe(
      tap(_ => {
        this.logger.debug('Machine hazards updated');
      }),
      map(results => results['ids']),
      catchError((err, caught) => {
        this.logger.error('Error updating machine hazards: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
