import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { Site } from '../model/site';

@Injectable()
export class SitesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/Sites';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('SitesService');
  }

  public getSites(filter?: QueryFilter): Observable<QueryResult<Site>> {
    this.logger.debug('Getting sites');

    let url = this.apiUrl;

    return this.http.put<QueryResult<Site>>(url, filter && filter.toJson()).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No sites found');
          return result;
        }

        this.logger.debug('{0} sites found', result.data.length);

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting sites: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getSite(id: string): Observable<Site> {
    this.logger.debug('Getting site with id', id);

    if (!id) {
      this.logger.error('Site id is null, returning null');
      return of(null);
    }

    return this.http.get<Site>(this.apiUrl + '/' + id).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No site found');
          return result;
        }

        this.logger.debug('Site found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting site: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
