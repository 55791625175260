<div class="auth-offline">
  <div class="auth-offline-container">
    <p class="mb-4">
      <i class="material-icons icon-8x">cloud_off</i>
    </p>

    <p class="mb-4">
      {{'auth-offline.title'|translate}}
    </p>

    <a class="btn btn-primary" href="/">
      {{'auth-offline.go-to-login'|translate}}
    </a>
  </div>
</div>