import { Directive, ElementRef, HostListener, Renderer2, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as moment from 'moment';

export const defaultFormat = 'D.M.YYYY';

@Directive({
  selector: 'input[type="text"][formControlName][useValueAsDate]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateValueAccessorDirective),
      multi: true
    }
  ]
})
export class DateValueAccessorDirective implements ControlValueAccessor, OnChanges {
  
  @HostListener('input', ['$event.target.value']) onInputChange (value: string) {
    const date = moment(value, this.format, true);
    this.onChange(date.isValid() ? date : null);
  };

  @HostListener('blur', []) onTouched = () => { };

  @Input('useValueAsDate') format: string;

  private onChange: any;

  constructor(
    private renderer: Renderer2, 
    private elementRef: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.format) this.format = defaultFormat;
  }

  writeValue(date: Date): void {
    let calendar = moment(date);
    let value = null;

    if (calendar.isValid()) {
      value = calendar.format(this.format);
    }

    this.renderer.setProperty(
      this.elementRef.nativeElement, 
      'value', 
      value
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

}