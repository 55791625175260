import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'logo-placeholder',
  templateUrl: './logo-placeholder.component.html',
  styleUrls: ['./logo-placeholder.component.scss']
})
export class LogoPlaceholderComponent implements OnInit {

  @Input()
  public original: string;

  public loaded: { [key: string]: boolean } = {};
  public error: { [key: string]: boolean } = {};

  constructor() { }

  public ngOnInit(): void {
  }

}
