<div class="layout">
  <nav class="navbar navbar-tuv">
    <div class="mr-auto w-25">
      <a class="navbar-brand" [routerLink]="['/']" title="{{ 'menu.tooltip.home' | translate }}">
        <img src="../assets/img/logo.png" />
      </a>
    </div>

    <div class="navbar-nav mx-auto">
      <ng-container *isNotDesktop>
        <div class="nav-item" *ngIf="canViewDashboard()">
          <a class="nav-link" [routerLink]="dashboardLink" routerLinkActive="active" title="{{ 'menu.tooltip.dashboard' | translate }}">{{
            'menu.dashboard' | translate
          }}</a>
        </div>
      </ng-container>
      <div class="nav-item">
        <a class="nav-link" [routerLink]="['/settings']" routerLinkActive="active" title="{{ 'menu.tooltip.settings' | translate }}">{{
          'menu.settings.title' | translate
        }}</a>
      </div>
      <div class="nav-item" *ngIf="canViewAssessments()">
        <a
          class="nav-link"
          [routerLink]="['/assessments']"
          routerLinkActive="active"
          title="{{ 'menu.tooltip.assessments' | translate }}"
          >{{ 'menu.assessments' | translate }}</a
        >
      </div>
      <ng-container *ngIf="canViewDocuments()">
        <div class="nav-item" *isNotDesktop>
          <a class="nav-link" [routerLink]="['/documents']" routerLinkActive="active" title="{{ 'menu.tooltip.documents' | translate }}">{{
            'menu.documents' | translate
          }}</a>
        </div>
      </ng-container>
      <ng-container *ngIf="canViewImplementations()">
        <div class="nav-item" *isNotDesktop>
          <a class="nav-link" [routerLink]="['/implementations']" routerLinkActive="active">{{ 'menu.implementations' | translate }}</a>
        </div>
      </ng-container>
    </div>

    <div class="navbar-nav navbar-right ml-auto w-25 justify-content-end">
      <ng-container *ngIf="showNavBarIcons">
        <div class="nav-item" *isNotDesktop>
          <a class="nav-link help" (click)="help()">
            <i class="material-icons">help_center</i>
          </a>
        </div>

        <div class="nav-item" *isDesktop>
          <a class="nav-link cloud" [ngClass]="{ active: !hidden['sync'] }" (click)="toggle('sync')">
            <icon-synchronization></icon-synchronization>
          </a>
        </div>

        <div class="nav-item" *isNotDesktop>
          <a
            class="nav-link notifications"
            [ngClass]="{ active: !hidden['notifications'] }"
            (click)="toggle('notifications')"
            title="{{ 'menu.tooltip.notifications' | translate }}"
          >
            <icon-notifications [showBadge]="showNotificationsBadge"></icon-notifications>
          </a>
        </div>

        <div class="nav-item">
          <a
            class="nav-link user"
            [ngClass]="{ active: !hidden['user'] }"
            (click)="toggle('user')"
            title="{{ 'menu.tooltip.user' | translate }}"
          >
            <span class="avatar" [ngClass]="getAvatarClass(authService.user?.id)">{{ authService.getUserInitials() }}</span>
          </a>
        </div>
      </ng-container>
    </div>

    <div class="badge badge-warning environment" *ngIf="environmentName">
      {{ environmentName }}
    </div>
  </nav>

  <div class="content">
    <router-outlet></router-outlet>

    <div class="sync-menu" (window:keyup)="keyup($event, 'sync')" [ngClass]="{ 'from-right': hidden['sync'] }" *isDesktop>
      <ul class="nav flex-column">
        <li class="nav-item">
          <div class="nav-link">
            <label>{{ 'menu.synchronization.state.title' | translate }}</label>
            <input
              type="text"
              class="form-control-plaintext"
              disabled="true"
              value="{{ 'menu.synchronization.state.' + syncState | translate }}"
            />
          </div>
        </li>

        <li class="nav-item">
          <div class="nav-link">
            <label>{{ 'menu.synchronized' | translate }}</label>
            <input type="text" class="form-control-plaintext" disabled="true" value="{{ syncDate | async | calendar : 'Never' }}" />
          </div>
        </li>

        <li class="nav-item">
          <div class="nav-link">
            <button type="button" class="btn btn-primary btn-block" (click)="synchronize()">
              {{ 'menu.synchronization.synchronize-now' | translate }}
            </button>
          </div>
        </li>
      </ul>
    </div>

    <div class="notifications-menu" (window:keyup)="keyup($event, 'notifications')" [ngClass]="{ 'from-right': hidden['notifications'] }">
      <div class="scroll-wrapper">
        <div class="scroll-wrapper-inner">
          <div class="table-header">
            <button class="btn btn-outline-secondary" type="button" (click)="refreshNotifications(); resetNotificationsLastSeen()">
              <i class="material-icons">refresh</i>
            </button>
          </div>

          <div class="table-content" *ngIf="!hidden['notifications']">
            <!-- //need to hide notifications table in order to prevent table updates due to digest cycle  -->
            <table class="table table-hover table-md table-edit">
              <thead>
                <tr>
                  <th class="notification"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let notification of notifications" (click)="selectNotification(notification)">
                  <td class="notification">
                    <div class="notification-title">
                      <span class="new-indicator" *ngIf="notification.isNew"></span>
                      <span class="notification-type" [ngClass]="notification.type"></span>
                      <span class="text-truncate" [ngClass]="{ 'font-weight-bold': notification.isNew }">
                        {{ formatNotificationType(notification) }}
                      </span>
                    </div>
                    <div class="notification-content" title="">
                      {{ formatNotification(notification) }}
                    </div>
                    <div class="row">
                      <div class="col">
                        <span class="small text-muted">{{ notification.createdBy?.name }}</span>
                      </div>
                      <div class="col-auto">
                        <span class="small text-muted">{{ notification.createdAt | dateFormated : 'time' }}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <spinner name="layout-user-notifications" class="table-spinner"></spinner>

            <div class="table-not-found" *ngIf="notificationsNotFound()">
              <i class="material-icons">search</i>
              {{ 'modal.not-found' | translate }}
            </div>
          </div>
        </div>
      </div>

      <footer class="notifications-footer small text-muted" *ngIf="notifications?.length > 0">
        <a
          class="btn btn-primary btn-block"
          [routerLink]="['', { outlets: { notifications: ['notifications'] } }]"
          (click)="toggle('notifications')"
        >
          {{ 'menu.notifications.see-all' | translate }} ({{ notificationsCount }})
        </a>
      </footer>
    </div>

    <div
      class="user-menu row"
      (window:keyup)="keyup($event, 'user')"
      [ngClass]="{ 'from-right': hidden['user'], 'user-menu-engineer': canViewEquipmentMenuAndAutoSavePanel() }"
    >
      <div class="menu-content col-auto">
        <ul class="nav flex-column">
          <li class="nav-item">
            <div class="nav-link user">
              <div class="row no-gutters">
                <div class="col-auto mr-3">
                  <span class="avatar" [ngClass]="getAvatarClass(authService.user?.id)">{{ authService.getUserInitials() }}</span>
                </div>
                <div class="col">
                  <ul class="list-unstyled">
                    <li>{{ authService.user?.name }}</li>
                  </ul>

                  <ul class="list-unstyled small text-muted">
                    <li>{{ authService.user?.email }}</li>
                  </ul>

                  <ul class="list-unstyled small text-muted">
                    <li>
                      {{ formatRoles(authService.user) }}
                      <span class="badge badge-warning" *ngIf="isSiteRestricted()">Restricted</span>
                    </li>
                    <li class="company">{{ organization?.name }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <ng-template [ngIf]="isSiteRestricted()">
            <li class="nav-item" *ngIf="formatDivisions(authService.user?.division)">
              <div class="nav-link">
                <label for="divisions">{{ 'menu.restricted-to-divisions' | translate }}</label>
                <input type="text" class="form-control-plaintext" [value]="formatDivisions(authService.user?.division)" id="divisions" />
              </div>
            </li>
            <li class="nav-item" *ngIf="formatSites(authService.user?.site)">
              <div class="nav-link">
                <label for="sites">{{ 'menu.restricted-to-sites' | translate }}</label>
                <input type="text" class="form-control-plaintext" [value]="formatSites(authService.user?.site)" id="sites" />
              </div>
            </li>
          </ng-template>

          <span class="small-divider"></span>

          <li class="nav-item">
            <div class="nav-link">
              <div class="row">
                <div class="col-auto">
                  <label for="language">{{ 'menu.language.title' | translate }}</label>
                </div>
                <div class="col text-right">
                  <ng-template #tooltipContentLanguage>
                    <div class="tooltip-content">
                      <label>{{ 'menu.tooltip.language' | translate }}</label>
                      <p>{{ 'menu.tooltip.language-content' | translate }}</p>
                    </div>
                  </ng-template>
                  <button
                    type="button"
                    class="btn btn-link btn-tooltip"
                    [ngbTooltip]="tooltipContentLanguage"
                    placement="top-right"
                    triggers="click"
                    tabindex="-1"
                  >
                    <i class="material-icons">help</i>
                  </button>
                </div>
              </div>
              <select #language class="custom-select" (change)="changeLanguage(language.value)" [value]="currentLanguage" id="language">
                <option value="de">{{ 'menu.language.german' | translate }}</option>
                <option value="en">{{ 'menu.language.english' | translate }}</option>
                <option value="ko">{{ 'menu.language.korean' | translate }}</option>
                <option value="zh">{{ 'menu.language.chinese' | translate }}</option>
                <option value="ja">{{ 'menu.language.japanese' | translate }}</option>
                <option value="es">{{ 'menu.language.spanish' | translate }}</option>
                <option value="it">{{ 'menu.language.italian' | translate }}</option>
                <option value="fr">{{ 'menu.language.french' | translate }}</option>
              </select>
            </div>
          </li>

          <app-user-profile-auto-save-panel *ngIf="canViewEquipmentMenuAndAutoSavePanel()"></app-user-profile-auto-save-panel>

          <form [formGroup]="form" novalidate>
            <div class="card content-card">
              <div class="form-group with-error">
                <label>{{ 'users.edit.organization.organization' | translate }}</label>
                <ng-select [items]="organizations" bindLabel="name" formControlName="organization"></ng-select>
              </div>
            </div>
          </form>

          <li class="nav-item">
            <div class="nav-link">
              <font-size></font-size>
            </div>
          </li>

          <span class="small-divider"></span>

          <ng-container *isNotDesktop>
            <!-- Commented out until desktop app is supported again !-->
            <!-- <li class="nav-item">
                <a class="nav-link" [href]="installationUrl" target="_blank" download>{{ 'menu.install-desktop-app' | translate }}</a>
              </li> -->

            <li class="nav-item">
              <a class="nav-link" (click)="editProfile()">{{ 'menu.edit-profile' | translate }}</a>
            </li>
          </ng-container>

          <ng-container *isDesktop>
            <ng-container *ngIf="isDesktopDevelopment()">
              <li class="nav-item">
                <a class="nav-link" (click)="showDevTools()">Developer Tools</a>
              </li>
            </ng-container>
            <ng-container *ngIf="isOnline | async">
              <li class="nav-item">
                <a class="nav-link" (click)="logout()">{{ 'menu.logout' | translate }}</a>
              </li>
            </ng-container>
          </ng-container>

          <ng-container *isNotDesktop>
            <li class="nav-item">
              <a class="nav-link" (click)="logout()">{{ 'menu.logout' | translate }}</a>
            </li>
          </ng-container>
        </ul>

        <footer class="user-footer small text-muted">
          <span [title]="version">&copy; 2018-2021 TÜV SÜD</span>
        </footer>
      </div>

      <div class="col" *ngIf="canViewEquipmentMenuAndAutoSavePanel()">
        <app-equipment-list (editEquipmentModalOpen)="toggleNavBarIcons($event)"></app-equipment-list>
      </div>
    </div>
  </div>

  <ng-container *isDesktop>
    <div class="sync-menu-overlay" [ngClass]="{ hidden: hidden['sync'] }" (click)="toggle('sync')" *ngIf="!hidden['sync']"></div>
  </ng-container>
  <ng-container *isNotDesktop>
    <div
      class="notifications-menu-overlay"
      [ngClass]="{ hidden: hidden['notifications'] }"
      (click)="toggle('notifications')"
      *ngIf="!hidden['notifications']"
    ></div>
  </ng-container>
  <div class="user-menu-overlay" [ngClass]="{ hidden: hidden['user'] }" (click)="toggle('user')" *ngIf="!hidden['user']"></div>
</div>

<modal-confirmation name="user-logout">
  <div [innerHTML]="getSafeHTML('menu.confirmation.user-logout' | translate)"></div>
</modal-confirmation>

<page-spinner name="clear-data">
  <div>{{ 'menu.deleting' | translate }}</div>
</page-spinner>
