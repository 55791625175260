import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap, first } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MachineDocument } from '../../../settings-modules/machine-documents/model/machine-document';
import { ProjectMachinesService } from '../../machine-services/shared/project-machines.service';

@Injectable()
export class MachineHazardActionDocumentsService {
  private logger: ILogger;
  private machineId: string;
  private projectId: string;
  private hazardId: string;
  private actionId: string;

  constructor(private projectMachinesService: ProjectMachinesService, loggerService: LoggerService) {
    this.logger = loggerService.getLogger('HazardActionDocumentsService');
  }

  public setProject(projectId: string): MachineHazardActionDocumentsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): MachineHazardActionDocumentsService {
    this.machineId = machineId;
    return this;
  }

  public setHazard(hazardId: string): MachineHazardActionDocumentsService {
    this.hazardId = hazardId;
    return this;
  }

  public setAction(actionId: string): MachineHazardActionDocumentsService {
    this.actionId = actionId;
    return this;
  }

  public getActionDocuments(): Observable<Array<MachineDocument>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Hazard Id not set. Use setHazard() first');
    }

    if (!this.actionId) {
      throw Error('Action Id not set. Use setAction() first');
    }

    this.logger.debug(
      'Getting hazard action documents for action {0} in project {1}, machine {2} and hazard {3}',
      this.actionId,
      this.projectId,
      this.machineId,
      this.hazardId
    );

    return this.projectMachinesService
      .setProject(this.projectId)
      .getProjectMachine(this.machineId)
      .pipe(
        first(),
        map((machine) => {
          if (!machine.identifiedHazards) {
            this.logger.error('Identified hazards not found on machine', machine.id);
            return throwError({ message: 'Identified hazards not found' });
          }

          const hazard = machine.identifiedHazards.find((item) => item.id === this.hazardId);

          if (!hazard || !hazard.implementation || !hazard.implementation.actions) {
            this.logger.error('Hazards {0} not found', this.hazardId);
            return throwError({ message: 'Hazards not found' });
          }

          const action = hazard.implementation.actions.find((item) => item.id === this.actionId);

          if (!action) {
            this.logger.error('Hazard action {0} not found', this.actionId);
            return throwError({ message: 'Hazard action not found' });
          }

          return JSON.parse(JSON.stringify(action.documents)); //create copy because ProjectMachine instance is shared
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting machine documents: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }

  public getActionDocument(documentId: string): Observable<MachineDocument> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Hazard Id not set. Use setHazard() first');
    }

    if (!this.actionId) {
      throw Error('Action Id not set. Use setAction() first');
    }

    this.logger.debug(
      'Getting hazard action document {0} for action {1} in project {2}, machine {3} and hazard {4}',
      documentId,
      this.actionId,
      this.projectId,
      this.machineId,
      this.hazardId
    );

    return this.projectMachinesService
      .setProject(this.projectId)
      .getProjectMachine(this.machineId)
      .pipe(
        map((machine) => {
          if (!machine.identifiedHazards) {
            this.logger.error('Identified hazards not found on machine', machine.id);
            return throwError({ message: 'Identified hazards not found' });
          }

          const hazard = machine.identifiedHazards.find((item) => item.id === this.hazardId);

          if (!hazard || !hazard.implementation || !hazard.implementation.actions) {
            this.logger.error('Hazards {0} not found', this.hazardId);
            return throwError({ message: 'Hazards not found' });
          }

          const action = hazard.implementation.actions.find((item) => item.id === this.actionId);

          if (!action) {
            this.logger.error('Hazard action {0} not found', this.actionId);
            return throwError({ message: 'Hazard action not found' });
          }

          return JSON.parse(JSON.stringify(action.documents.find((item) => item.id === documentId))); //create copy because ProjectMachine instance is shared
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting hazard action document: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }
}
