import BaseAppender from 'log4ts/build/appenders/BaseAppender';
import { IAppender } from 'log4ts/build/IAppender';
import { LogEntry } from 'log4ts/build/LogEntry';
import { AppAnalytics } from '../shared/analytics';

export class AnalyticsAppender extends BaseAppender implements IAppender {

	append(entry: LogEntry) {
		AppAnalytics.event('log', JSON.parse(JSON.stringify(entry)));
	}

	clear() {
		//do nothing
	}
}
