import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'icon-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input('showBadge')
  public set showBadge(value: boolean) {
    this.state = value ? this.states[1] : this.states[0];
  }

  public readonly states: Array<string> = ['notification', 'badge'];
  public state: string = this.states[0];

  constructor(
  ) { }

  public ngOnInit(): void {
  }
}
