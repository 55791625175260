import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService, ILogger } from '../../core/shared/logger.service';
import { AuthService } from './auth.service';

@Injectable()
export class IsAdministratorGuardService implements CanActivate {

  private logger: ILogger;

  constructor(
    private router: Router,
    private authService: AuthService,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('IsAdministratorGuardService');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    if (this.authService.user.roles &&
       (this.authService.user.roles.includes('Administrator') ||
        this.authService.user.roles.includes('CustomerAdministrator'))) {
      this.logger.debug('Is Administrator or Customer Administrator. Can activate.');
      return true;
    } else {
      this.logger.debug('Permission denied. Cannot activate. Redirecting to start.');
      this.router.navigate(['start']);
      return false;
    }
  }
}
