
<div class="container-fluid">
	<auth-refresh></auth-refresh>

	<router-outlet></router-outlet>

	<router-outlet name="notifications"></router-outlet>

	<notification-center></notification-center>

	<auth-relogin></auth-relogin>
</div>