<div class="list-title pb-2">{{ 'menu.equipment-list.title' | translate }}</div>
<div class="row align-items-center table-header">
  <div class="col-auto">
    <div class="list-inline">
      <div class="list-inline-item">
        <form [formGroup]="quickSearchForm" novalidate (submit)="quickSearch()">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="equipmentReferenceFilter"
              placeholder="{{ 'model.equipment.reference' | translate }}"
              formControlName="equipmentReferenceFilter"
              autocomplete="off"
            />

            <div class="input-group-append">
              <button type="submit" class="btn btn-outline-secondary">
                <i class="material-icons">search</i>
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="list-inline-item">
        <button class="btn btn-outline-secondary" type="button" (click)="refresh(true)">
          <i class="material-icons">refresh</i>
        </button>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="canWrite()">
    <div class="col text-right">
      <button type="button" class="btn btn-primary btn-action" (click)="add()">{{ 'menu.equipment.add-equipment' | translate }}</button>
    </div>
  </ng-template>
</div>

<div class="table-content">
  <table class="table table-hover table-md table-edit">
    <thead>
      <tr>
        <th class="equipmentReference">
          <table-sorting [filter]="filter" property="reference" (sort)="refresh()">
            {{ 'model.equipment.reference' | translate }}
          </table-sorting>
        </th>

        <th class="type">
          <table-sorting [filter]="filter" property="manufacturer" (sort)="refresh()">
            {{ 'model.equipment.manufacturer' | translate }}
          </table-sorting>
        </th>

        <th class="calibrationDue">
          <table-sorting [filter]="filter" property="calibrationDue" (sort)="refresh()">
            {{ 'model.equipment.calibration-due' | translate }}
          </table-sorting>
        </th>

        <th class="testDuration">
          <table-sorting [filter]="filter" property="testDuration" (sort)="refresh()">
            {{ 'model.equipment.test-duration' | translate }}
          </table-sorting>
        </th>
        <th class="assetNumber">
          <table-sorting [filter]="filter" property="assetNumber" (sort)="refresh()">
            {{ 'model.equipment.asset-number' | translate }}
          </table-sorting>
        </th>

        <th class="model">
          <table-sorting [filter]="filter" property="model" (sort)="refresh()">
            {{ 'model.equipment.model' | translate }}
          </table-sorting>
        </th>
        <th class="assignedMeasurementTypes">
          <table-sorting [filter]="filter" property="assignedMeasurementTypes" (sort)="refresh()">
            {{ 'model.equipment.assigned-measurement-types' | translate }}
          </table-sorting>
        </th>
        <th class="is-active">
          <table-sorting [filter]="filter" property="isActive" (sort)="refresh()">
            {{ 'model.equipment.is-active' | translate }}
          </table-sorting>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let equipment of equipments" (click)="edit(equipment)">
        <td class="equipmentReference">{{ equipment.reference | truncate : 50 }}</td>
        <td class="manufacturer">{{ equipment.manufacturer | truncate : 30 }}</td>
        <td class="calibrationDue">{{ equipment.calibrationDue | dateFormated }}</td>
        <td class="testDuration">{{ equipment.testDuration | truncate : 30 }}</td>
        <td class="assetNumber">{{ equipment.assetNumber | truncate : 30 }}</td>
        <td class="model">{{ equipment.model | truncate : 30 }}</td>
        <td class="assignedMeasurementTypes">
          <span *ngFor="let measurementType of equipment.assignedMeasurementTypes; let i = index">
            {{ measurementType.name }}<span *ngIf="i < equipment.assignedMeasurementTypes.length - 1">, </span>
          </span>
        </td>
        <td class="is-active">
          <i class="material-icons text-success" *ngIf="equipment.isActive">check_circle</i>
          <i class="material-icons text-danger" *ngIf="!equipment.isActive">highlight_off</i>
        </td>
      </tr>
    </tbody>
  </table>

  <spinner name="measurements" show="true" class="table-spinner"></spinner>

  <div class="table-not-found" *ngIf="notFound()">
    <i class="material-icons">search</i>
    {{ 'table.no-results' | translate }}
  </div>
</div>

<div class="table-footer" *ngIf="!notFound() && equipments?.length > 0">
  <div class="row">
    <div class="col">
      <table-pagination [count]="filter.count" [(from)]="filter.offset" [pageSize]="filter.limit" (onChange)="refresh(true)">
      </table-pagination>
    </div>
    <div class="col-auto">
      <span class="small"
        >{{ filter.count }}
        <span [ngPlural]="filter.count">
          <ng-template ngPluralCase="=1">{{ 'table.footer.record' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ 'table.footer.records' | translate }}</ng-template>
        </span>
      </span>
    </div>
  </div>
</div>

<app-equipment-edit
  *ngIf="showEdit"
  [editMode]="editMode"
  [equipment]="selectedEquipment"
  (modalHid)="closeEdit($event)"
></app-equipment-edit>
