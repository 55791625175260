<div class="placeholder">
  <div class="loading" [ngClass]="{loaded: !loaded['original'] && !error['original']}">
    <div class="loading-content">
      <span class="badge badge-secondary">
        <i class="material-icons">crop_original</i>
      </span>
    </div>
  </div>

  <img [src]="original" (load)="loaded['original'] = true" (error)="error['original'] = true" [ngClass]="{loaded: loaded['original']}"
    *ngIf="original">

  <div class="error" [ngClass]="{loaded: error['original']}">
    <div class="error-content">
      <span class="badge badge-secondary">
        <i class="material-icons">business</i>
      </span>
    </div>
  </div>
</div>