import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { MachineDocumentAction } from '../../../settings-modules/machine-documents/model/machine-document-action';

@Injectable()
export class ManageProjectMachineDocumentActionsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;
  private documentId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('ManageProjectMachineDocumentActionsService');
  }

  public setProject(projectId: string): ManageProjectMachineDocumentActionsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): ManageProjectMachineDocumentActionsService {
    this.machineId = machineId;
    return this;
  }

  public setMachineDocument(documentId: string): ManageProjectMachineDocumentActionsService {
    this.documentId = documentId;
    return this;
  }

  public save(action: MachineDocumentAction): Observable<MachineDocumentAction> {
    this.logger.debug('Saving machine document action');

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.documentId) {
      throw Error('Project Machine document id not set. Use setMachineDocument() first');
    }

    const url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'documents', this.documentId, 'actions'].join('/');

    return this.http.post<MachineDocumentAction>(url, action).pipe(
      tap((_) => {
        this.logger.debug('Project machine document action saved');
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving machine document action: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
