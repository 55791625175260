import { Directive, ElementRef, HostListener, Renderer2, forwardRef, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

export const defaultPattern = '^\\d+$';

@Directive({
  selector: 'input[type="text"][formControlName][useValueAsNumber], select[formControlName][useValueAsNumber]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberValueAccessorDirective),
    multi: true
    }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => NumberValueAccessorDirective),
    multi: true
  }]
})
export class NumberValueAccessorDirective implements ControlValueAccessor, Validator, OnChanges {
  
  @HostListener('input', ['$event.target.value']) onInputChange(value: string) {
    const isNumber = RegExp(this.pattern);

    this.value = value;

    if (isNumber.test(value)) {
      const number = value.replace(/[.]+/g, '').replace(/[,]/g, '.');
      this.onChange(Number.parseFloat(number));
    } else {
      this.onChange(null);
    }
  };
  @HostListener('blur', []) onTouched = () => { };

  @Input('useValueAsNumber') options: any;

  private value: string;
  private pattern: string = defaultPattern;
  private isDecimal: boolean = false;
  private decimals: number = 16;
  private onChange: (_: any) => void;
  private onValidatorChange: () => void;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.options) {
      if (typeof this.options === 'object') {
        if (this.options.pattern) this.pattern = this.options.pattern;
        if (this.options.isDecimal) this.isDecimal = this.options.isDecimal;
        if (this.options.decimals) this.decimals = this.options.decimals;
      } else {
        this.pattern = this.options;
      }
    }
  }

  writeValue(num: any): void {
    this.value = null;

    if (num != null && !isNaN(num)) {
      num = Number(num);

      if (this.isDecimal) {
        const fixedNum = num.toFixed(this.decimals);
        this.value = fixedNum % 1 === 0 ? fixedNum.toFixed(0) : fixedNum.toString().replace(/[.]+/g, ',');
      } else {
        this.value = num.toFixed(0);
      }
    }

    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      this.value
    );
  }

  registerOnChange(fn: (_:any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  public validate(c: AbstractControl): { [key: string]: any; } {
    const isNumber = RegExp(this.pattern);

    if (this.value != null && this.value != '' && !isNumber.test(this.value)) {
      return {
        useValueAsNumber: true
      };
    } 

    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }
}