
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpEventType } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';
import { Photo } from '../../machine-services/model/photo';
import { IdName } from '../../../core/model/id-name';
import { UploadSaveEvent } from '../../machine-services/model/upload-save-event';


@Injectable()
export class InternalMachinePhotosService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor( 
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('InternalMachinePhotosService');
  }

  public setProject(projectId: string): InternalMachinePhotosService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalMachinePhotosService {
    this.machineId = machineId;
    return this;
  }

  public create(file: File): Observable<UploadSaveEvent> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Creating photo for machine {0} from project {1}', this.machineId, this.projectId);

    const method = 'POST';
    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'photos'
    ].join('/');

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.request<Photo>(new HttpRequest(method, url, formData, {
      reportProgress: true
    })).pipe(
      map(event => {
        const saveEvent = new UploadSaveEvent();

        if (event.type === HttpEventType.UploadProgress) {
          saveEvent.progress = Math.round(100 * event.loaded / event.total);
        } else if (event.type === HttpEventType.Response) {
          saveEvent.completed = true;

          if (event.body) {
            saveEvent.id = event.body['id'];
          }
        }

        return saveEvent;
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving machine photo: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public update(photo: Photo, fileBlob: Blob, originalFileBlob: Blob): Observable<Photo> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Creating photo for machine {0} from project {1}', this.machineId, this.projectId);

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'photos',
      photo.id
    ].join('/');

    const formData: FormData = new FormData();
    formData.append('photo', JSON.stringify(photo));

    if (fileBlob) {
      fileBlob['lastModified'] = Date.now().valueOf();
      fileBlob['name'] = photo.name;

      const file = <File>fileBlob;
      formData.append('file', file, file.name);
    }

    if (originalFileBlob) {
      originalFileBlob['lastModified'] = Date.now().valueOf();
      originalFileBlob['name'] = photo.name;

      const originalFile = <File>originalFileBlob;
      formData.append('originalFile', originalFile, originalFile.name);
    }

    return this.http.request<Photo>('PUT', url, {
      body: formData
    })
    .pipe(
      catchError((err, caught) => {
        this.logger.error('Error saving machine photo: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

}
