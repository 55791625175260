import { Injectable } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';


@Injectable()
export class SpinnerService {

  private spinnerCache = new Set<SpinnerComponent>();

  public register(spinner: SpinnerComponent): void {
    this.spinnerCache.add(spinner);
  }

  public unregister(spinnerToRemove: SpinnerComponent): void {
    this.spinnerCache.forEach(spinner => {
      if (spinner === spinnerToRemove) {
        this.spinnerCache.delete(spinner);
      }
    });
  }

  public unregisterGroup(spinnerGroup: string): void {
    this.spinnerCache.forEach(spinner => {
      if (spinner.group === spinnerGroup) {
        this.spinnerCache.delete(spinner);
      }
    });
  }

  public unregisterAll(): void {
    this.spinnerCache.clear();
  }

  public show(spinnerName: string): void {
    this.spinnerCache.forEach(spinner => {
      if (spinner.name === spinnerName) {
        spinner.show = true;
      }
    });
  }

  public hide(spinnerName: string): void {
    let found = false;

    this.spinnerCache.forEach(spinner => {
      if (spinner.name === spinnerName) {
        found = true;
        spinner.show = false;
      }
    });

    //spinner may not habe been yet registered (happens when calling hide in ngOnInit), need to wait for a while
    if (!found) {
      setTimeout(() => {
        this.spinnerCache.forEach(spinner => {
          if (spinner.name === spinnerName) {
            spinner.show = false;
          }
        });
      }, 10);
    }
  }

  public showGroup(spinnerGroup: string): void {
    this.spinnerCache.forEach(spinner => {
      if (spinner.group === spinnerGroup) {
        spinner.show = true;
      }
    });
  }

  public hideGroup(spinnerGroup: string): void {
    this.spinnerCache.forEach(spinner => {
      if (spinner.group === spinnerGroup) {
        spinner.show = false;
      }
    });
  }

  public showAll(): void {
    this.spinnerCache.forEach(spinner => spinner.show = true);
  }

  public hideAll(): void {
    this.spinnerCache.forEach(spinner => spinner.show = false);
  }

  public isShowing(spinnerName: string): boolean | undefined {
    let showing = undefined;
    this.spinnerCache.forEach(spinner => {
      if (spinner.name === spinnerName) {
        showing = spinner.show;
      }
    });
    return showing;
  }
}