<div class="page-not-found">
  <div class="page-not-found-container">

    <p class="mb-4">
      <i class="material-icons icon-8x">cloud_off</i>
    </p>

    <p class="mb-4">
      {{'page-not-found.title'|translate}}
    </p>

    <a class="btn btn-primary" routerLink="/">
      {{'page-not-found.go-to-start'|translate}}
    </a>

  </div>
</div>
