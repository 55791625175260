import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, type Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import type { QueryResult } from '../../query-filter/model/QueryResult';
import { ILogger, LoggerService } from '../../shared/logger.service';
import { Equipment } from './../../../projects-modules/machine-services/model/equipment';
import { QueryFilter } from '../../query-filter/model/QueryFilter';

@Injectable({
  providedIn: 'root'
})
export class EquipmentListService {
  private userId: string;
  private readonly apiUrl: string = environment.serverUrl + '/v1/equipment'; // remove user from path here
  private logger: ILogger;
  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('EquipmentListService');
  }

  public setUserId(userId: string): this {
    this.userId = userId;
    return this;
  }

  save(equipment: Equipment): Observable<Equipment> {
    this.logger.debug('Saving equipment with id', equipment.id);
    const method = equipment.id != null ? 'PUT' : 'POST';
    const url = this.apiUrl + (equipment.id != null ? '/' + equipment.id : '');

    return this.http
      .request<Equipment>(method, url, {
        body: equipment
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Equipment saved');
          return result;
        }),
        catchError((err) => {
          this.logger.debug('Error updating equipment: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }

  public delete(equipmentId: string): Observable<void> {
    this.logger.debug('Deleting equipment with id', equipmentId);
    return this.http.delete<void>([this.apiUrl, equipmentId].join('/')).pipe(
      tap((result) => {
        this.logger.debug('Equipment deleted');
        return result;
      }),
      catchError((err) => {
        this.logger.debug('Error deleting equipment: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }

  public getUserEquipmentList(filter: QueryFilter): Observable<QueryResult<Equipment>> {
    if (!this.userId) {
      throw Error('User Id not set. Use setUserId() first');
    }

    this.logger.debug('Getting equipment for machine {0} from project {1}', this.userId);

    let url = this.apiUrl; // add user path to url

    filter.equalTo('assignedEngineer.id', this.userId);
    filter.equalTo('deleted', false);

    url += filter.toQueryString();

    return this.http.get<QueryResult<Equipment>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No equipment found');
          return result;
        }

        return result;
      }),
      catchError((err) => {
        console.error('Error getting equipment: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }

  public getUserMeasurementEquipment(measurementTypeId: string): Observable<QueryResult<Equipment>> {
    if (!this.userId) {
      throw Error('User Id not set. Use setUserId() first');
    }

    let url = this.apiUrl; // add user path to url

    const filter = new QueryFilter();
    filter.equalTo('assignedEngineer.id', this.userId);
    filter.equalTo('deleted', false);
    filter.equalTo('isActive', true);
    filter.containedIn('assignedMeasurementTypes.id', [measurementTypeId]);

    url += filter.toQueryString();

    return this.http.get<QueryResult<Equipment>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No measurement equipment found');
          return result;
        }

        return result;
      }),
      catchError((err) => {
        console.error('Error getting measurement equipment: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }
}
