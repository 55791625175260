import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/shared/auth.service';
import { ILogger, LoggerService } from '../shared/logger.service';
import { StartupService } from '../shared/startup.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  private logger: ILogger;

  constructor(
    private startupService: StartupService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('LoadingComponent');
  }

  public ngOnInit(): void {
    this.logger.debug('Initializing LoadingComponent');

    this.startupService.isAppStarted().subscribe(null, null, () => {
      this.logger.debug('App started, redirecting to projects');

      const route = ['/'];
      //TODO potential candidate for refactoring, currently unknowm why this was implemented seperate from routing
      if (this.authService.isOnlyCustomerAdministrator()) {
        route.push('settings');
      } else if (this.authService.isCustomer()) {
        route.push('dashboard');
      } else if (this.authService.isEngineer() || this.authService.isPowerEngineer()) {
        route.push('dashboard', 'internal-dashboard');
      } else {
        route.push('settings');
      }

      this.router.navigate(route, { relativeTo: this.activeRoute });
    });
  }

}
