
<div class="pagination" *ngIf="count > pageSize">
  <div class="left">
    <button class="btn btn-outline-primary btn-arrow" (click)="previous()" [disabled]="currentPage === 1">
      <i class="material-icons icon-2x">chevron_left</i>
    </button>
  </div>
  <div class="jump">
    <input #jumpToPage type="text" class="form-control" [ngModel]="currentPage" (keyup.enter)="jumpTo(jumpToPage.value)"> {{'table.footer.of'|translate}}
    <span class="of">{{ totalPages }}</span>
  </div>
  <div class="right">
    <button class="btn btn-outline-primary btn-arrow" (click)="next()" [disabled]="currentPage === totalPages">
      <i class="material-icons icon-2x">chevron_right</i>
    </button>
  </div>
</div>