import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';

@Injectable()
export class InternalExaminationHazardsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalExaminationHazardsService');
  }

  public setProject(projectId: string): InternalExaminationHazardsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalExaminationHazardsService {
    this.machineId = machineId;
    return this;
  }

  public delete(hazards: Array<string>): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Deleting {0} hazards machine {1} from project {2}', hazards.length, this.machineId, this.projectId);

    const method = 'DELETE';
    const url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards'].join('/');

    return this.http
      .request<void>(method, url, {
        body: hazards
      })
      .pipe(
        tap((_) => {
          this.logger.debug('Hazards deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting hazards: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
