import { MultiHazardActionDto } from './multi-hazard-action-dto';
import { MachineHazard } from './machine-hazard';

export class MultiHazardActionByMachineDto {
  public projectId: string;
  public state: string;
  public notes: string;
  public projectMachines: ProjectMachinesHazardIdsDto[];

  constructor(init?: Partial<MultiHazardActionByMachineDto>) {
    Object.assign(this, init);
  }

  static makeDtoFromAssessedHazards(assessedHazards: MachineHazard[], action: MultiHazardActionDto): MultiHazardActionByMachineDto[] {
    const notes = action.notes ? action.notes : '';
    const state = action.state ? action.state : '';
    const projectGroups = assessedHazards.reduce((accumulator, hazard) => {
      const projectId = hazard.project.id;
      if (!accumulator[projectId]) {
        accumulator[projectId] = [];
      }
      accumulator[projectId].push(hazard);
      return accumulator;
    }, {});

    const multiHazardActionByMachineDtos = Object.keys(projectGroups).map((pId) => {
      const hazards = projectGroups[pId];
      const projectMachinesHazardIdsDto = hazards.reduce((accumulator, hazard) => {
        const machineId = hazard.machine.id;
        if (!accumulator[machineId]) {
          accumulator[machineId] = { projectMachineId: machineId, hazardIds: [] };
        }
        accumulator[machineId].hazardIds.push(hazard.id);
        return accumulator;
      }, {});

      return new MultiHazardActionByMachineDto({
        projectId: pId,
        state, // Assuming default or derived state
        notes, // Assuming default or derived notes
        projectMachines: Object.values(projectMachinesHazardIdsDto)
      });
    });

    return multiHazardActionByMachineDtos;
  }
}

export class ProjectMachinesHazardIdsDto {
  projectMachineId: string;
  hazardIds: string[];

  constructor(init?: Partial<ProjectMachinesHazardIdsDto>) {
    Object.assign(this, init);
  }
}
