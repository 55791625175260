<div class="notifications-container">

  <div class="notification" *ngIf="state === states[0]">
    <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1,0,0,1,-1,1)">
        <path id="notification" d="M20,2L4,2C2.9,2 2,2.9 2,4L2,22L6,18L20,18C21.1,18 22,17.1 22,16L22,4C22,2.9 21.1,2 20,2Z"
          style="fill-rule:nonzero;" />
      </g>
      <rect x="0" y="0" width="24" height="24" style="fill:none;" />
    </svg>
  </div>

  <div class="notification-badge" *ngIf="state === states[1]">
    <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="24" height="24" style="fill:none;" />
      <g transform="matrix(1,0,0,1,-1,1)">
        <path id="notification" 
          d="M15.083,2C15.028,2.325 15,2.659 15,3C15,6.311 17.689,9 21,9C21.341,9 21.675,8.972 22,8.917L22,16C22,17.1 21.1,18 20,18L6,18L2,22L2,4C2,2.9 2.9,2 4,2L15.083,2Z"
          style="fill-rule:nonzero;" />
      </g>
      <g transform="matrix(1.33333,0,0,1.33333,-5.33333,-2.66667)">
        <circle id="badge" cx="19" cy="5" r="3" />
      </g>
    </svg>
  </div>

</div>
