import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { QueryFilter } from '../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../core/query-filter/model/QueryResult';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { DocumentsProjectMachine } from '../model/documents-project-machine';

@Injectable()
export class DocumentsProjectMachinesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/documents';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('DocumentsProjectMachinesService');
  }

  public getDocumentsProjectMachines(filter?: QueryFilter): Observable<QueryResult<DocumentsProjectMachine>> {
    this.logger.debug('Getting document project machines');

    let url = this.apiUrl;

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<DocumentsProjectMachine>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No project machines found');
        }

        this.logger.debug('{0} documents project machines found', result.data.length);
      }),
      catchError((err) => {
        this.logger.error('Error getting documents project machines: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public getDocumentsProjectMachine(id: string): Observable<DocumentsProjectMachine> {
    this.logger.debug('Getting document project machine with id', id);

    const url = [this.apiUrl, id].join('/');

    return this.http.get<DocumentsProjectMachine>(url).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No project machine found');
        }

        this.logger.debug('Documents project machine found');
      }),
      catchError((err) => {
        this.logger.error('Error getting documents project machine: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
