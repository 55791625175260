import { Component, OnInit } from '@angular/core';
import { SynchronizationService } from '../../shared/synchronization.service';

@Component({
  selector: 'icon-synchronization',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.scss']
})
export class SyncComponent implements OnInit {

  public readonly states: Array<string> = ['done', 'inprogress', 'error', 'offline'];
  public state: string = this.states[0];

  constructor(
    private syncService: SynchronizationService
  ) { }

  public ngOnInit(): void {
    this.syncService.synchronization().subscribe(state => {
      this.state = this.states[state];
    });
  }
}
