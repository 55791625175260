import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[stopEnterPropagation]'
})
export class StopEnterPropagationDirective {
  constructor(
    private el: ElementRef
  ) { }
  
  @HostListener('keyup.enter', ['$event'])
  public onKeyupEnter(event: any): void {
    event.stopPropagation();
  }
}
