import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonConfirmationComponent } from './button-confirmation/button-confirmation.component';
import { ModalCloseConfirmationComponent } from './modal-close-confirmation/modal-close-confirmation.component';
import { ModalConfirmationComponent } from './modal-confirmation/modal-confirmation.component';
import { EditModalModule } from '../edit-modal/edit-modal.module';
import { TranslateModule } from '../../../../node_modules/@ngx-translate/core';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';

@NgModule({
  imports: [CommonModule, EditModalModule, TranslateModule],
  declarations: [ButtonConfirmationComponent, ModalCloseConfirmationComponent, ModalConfirmationComponent, ModalBasicComponent],
  exports: [ButtonConfirmationComponent, ModalCloseConfirmationComponent, ModalConfirmationComponent, ModalBasicComponent]
})
export class ConfirmationModule {}
