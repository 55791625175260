
<div class="synchronized-container" [ngClass]="state">
  <i class="material-icons background">cloud</i>

  <div class="synchronized-icon synchronized" *ngIf="state === states[0]">
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
      <path id="_" data-name="" d="M5.637,13.772a0.753,0.753,0,0,0,1.1,0l9.045-8.85A0.726,0.726,0,0,0,16,4.38a0.726,0.726,0,0,0-.215-0.542L14.651,2.754a0.689,0.689,0,0,0-.537-0.241,0.786,0.786,0,0,0-.567.241L6.189,9.979,2.755,6.608a0.786,0.786,0,0,0-.567-0.241,0.689,0.689,0,0,0-.537.241L0.517,7.691a0.791,0.791,0,0,0,0,1.084Z" />
    </svg>
  </div>

  <i class="material-icons synchronized-icon upload" *ngIf="state === states[1]">autorenew</i>
  
  <div class="synchronized-icon error" *ngIf="state === states[2]">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g>
        <rect stroke="#fff" id="svg_3" height="6.800076" width="1.070878" y="2.538522" x="7.464561" stroke-width="1.5" fill="#fff"/>
        <rect stroke="#fff" id="svg_5" height="0.96379" width="1.070878" y="12.497688" x="7.464561" fill-opacity="null" stroke-opacity="null" stroke-width="1.5" fill="#fff"/>
      </g>
    </svg>
  </div>

  <i class="material-icons synchronized-icon offline" *ngIf="state === states[3]">clear</i>
</div>
