import { Injectable } from '@angular/core';
import { LogosURLResolver } from './logos-url-resolver';
import { Company } from '../model/company';
import { environment } from '../../../../environments/environment';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CompanyLogosURLResolverService implements LogosURLResolver {
  
  private apiUrl: string = environment.serverUrl + '/v1/companies';

  constructor(private http: HttpClient) { }

  public getURL(company: Company, size: string): Observable<string | SafeUrl> {
    const url = [
      this.apiUrl,
      company.id,
      'logo',
      company.logo
    ].join('/') + '?size=' + size;

    return this.http.get(url, { responseType: 'blob' }).pipe(map((blob) => window.URL.createObjectURL(blob)));
  }
}
