export class Pointer {
  public name: string;
  public id: string;
  public reference: string;

  constructor(id?: string, reference?: string, name?: string) {
    this.id = id;
    this.reference = reference;
    this.name = name;
  }
}
