import { Injectable } from '@angular/core';
import { MachineHazard } from '../../machine-services/model/machine-hazard';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { Observable, throwError } from 'rxjs';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class MachineHazardsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;

  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('MachineHazardsService');
  }

  public setProject(id: string): MachineHazardsService {
    this.projectId = id;
    return this;
  }

  public setProjectMachine(id: string): MachineHazardsService {
    this.machineId = id;
    return this;
  }

  public getMachineHazards(filter?: QueryFilter): Observable<QueryResult<MachineHazard>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting machine hazards for project {0} and machine {1}', this.projectId, this.machineId);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards'].join('/');

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<MachineHazard>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No machine hazards found');
          return result;
        }

        this.logger.debug('Machine hazards found');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine hazards: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public getMachineHazard(id: string): Observable<MachineHazard> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting machine hazard with id', id);

    const url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards', id].join('/');

    return this.http.get<MachineHazard>(url).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No machine hazard found');
          return result;
        }

        this.logger.debug('Machine hazard found');
        return result;
      }),
      catchError((err) => {
        this.logger.error('Error getting machine hazard {0}: {1} - {2}', id, err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
