import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../auth/shared/auth.service';
import { Equipment } from '../../../projects-modules/machine-services/model/equipment';
import { User } from '../../../projects-modules/machine-services/model/user';
import { NotificationsService } from '../../notifications/shared/notifications.service';
import { QueryFilter } from '../../query-filter/model/QueryFilter';
import { FormService, type Form } from '../../shared/form.service';
import { ILogger, LoggerService } from '../../shared/logger.service';
import { MessageBrokerService } from '../../shared/message-broker.service';
import { SpinnerService } from '../../spinner/shared/spinner.service';
import { EquipmentListService } from './equipment-list.service';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss']
})
export class EquipmentListComponent implements OnInit {
  @Output() editEquipmentModalOpen = new EventEmitter<boolean>();
  public filter: QueryFilter = new QueryFilter().take(10);
  public equipments: Array<Equipment>;
  public quickSearchForm: Form;
  public showEdit = false;
  public editMode = false;
  public currentUser: User;

  selectedEquipment: Equipment;
  referenceFilterString: string;
  private logger: ILogger;
  private subscriptions: Subscription[] = [];
  private refreshSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private formService: FormService,
    private equipmentListService: EquipmentListService,
    private messageBroker: MessageBrokerService,
    private notificationsService: NotificationsService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,

    logger: LoggerService
  ) {
    this.logger = logger.getLogger('EquipmentListComponent');
  }

  public ngOnInit() {
    this.logger.debug('Initializing component');

    this.quickSearchForm = this.formService.form({
      equipmentReferenceFilter: null
    });

    this.filter.ascending('reference');
    this.equipmentListService.setUserId(this.authService.user.id);
    this.refresh();

    this.subscriptions.push(
      this.messageBroker.from('equipment.save').subscribe((_) => {
        this.refresh(true);
      }),
      this.messageBroker.from('equipment.delete').subscribe((_) => {
        this.refresh(true);
      })
    );
  }

  public add(): void {
    this.logger.debug('Redirecting to add equipment');

    this.currentUser = this.authService.user;

    // set selected equipment to a new equipment object
    this.selectedEquipment = {
      id: null,
      reference: null,
      testDuration: null,
      calibration: null,
      calibrationDue: null,
      description: null,
      assetNumber: null,
      manufacturer: null,
      model: null,
      assignedMeasurementTypes: null,
      isActive: true,
      assignedEngineer: this.currentUser
    } as Equipment;

    this.showEdit = true;
    this.editMode = false;
    this.editEquipmentModalOpen.emit(true);
  }

  public closeEdit(event: any): void {
    this.logger.debug('closeEdit', event);
    this.showEdit = false;
    this.editEquipmentModalOpen.emit(false);
  }

  public canWrite(): boolean {
    return this.authService.isEngineer() || this.authService.isPowerEngineer();
  }

  public edit(equipment: Equipment): void {
    this.logger.debug('edit equipment with id', equipment.id);
    this.selectedEquipment = equipment;
    this.showEdit = true;
    this.editMode = true;
    this.editEquipmentModalOpen.emit(true);
  }

  public notFound(): boolean {
    return !this.spinnerService.isShowing('measurements') && this.equipments && this.equipments.length === 0;
  }

  public refresh(preservePaging?: boolean): void {
    this.logger.debug('Refreshing equipments');
    this.logger.debug('filter: ', this.filter);

    this.equipments = [];
    this.spinnerService.show('measurements');

    if (!preservePaging) {
      this.filter.from(0);
    }

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

    this.refreshSubscription = this.equipmentListService.getUserEquipmentList(this.filter).subscribe(
      (query) => {
        this.filter.count = query.count;
        this.equipments = query.data;
        this.spinnerService.hide('measurements');
      },
      (error) => {
        this.notificationsService.danger(
          this.translateService.instant('project-machine-measurements.list.notification.error-loading'),
          error
        );

        this.spinnerService.hide('measurements');
      }
    );
  }

  public quickSearch(): void {
    if (this.quickSearchForm.canSubmit()) {
      this.logger.debug('Running quick search');

      this.referenceFilterString = null;
      this.referenceFilterString = this.quickSearchForm.value['equipmentReferenceFilter'];

      this.logger.debug('quick search null?', !Boolean(this.referenceFilterString));
      this.logger.debug('Running quick search ', this.referenceFilterString);

      this.filter.clearWhere(['reference']);

      if (this.referenceFilterString) {
        this.filter.contains('reference', this.referenceFilterString);
      } else if (this.referenceFilterString === '') {
        this.filter.clearWhere(['reference']);
      } else {
        this.filter.clearWhere(['reference']);
      }
      this.refresh();
    } else {
      this.logger.debug('Cannot submit quick search form');
    }
  }
}
