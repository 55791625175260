<dx-data-grid
  #subgrid
  [dataSource]="dataSource"
  [keyExpr]="config.keyExpr"
  [showBorders]="false"
  [columnAutoWidth]="true"
  (onCellPrepared)="onCellPrepared($event)"
  (onRowPrepared)="onRowPrepared($event)"
  (onInitialized)="onGridInitialized()"
  (onRowUpdated)="onRowUpdated($event)"
  (onRowClick)="onRowClick($event)"
  (onRowInserted)="onRowInserted($event)"
  (onRowRemoved)="onRowRemoved($event)"
  (onInitNewRow)="onInitNewRow($event)"
>
  <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false" [newRowPosition]="newRowPosition">
    <!-- empty string is required to disable delete confirmation popup -->
    <dxo-texts confirmDeleteMessage=""> </dxo-texts>
  </dxo-editing>
  <dxo-scrolling columnRenderingMode="virtual" [useNative]="true"></dxo-scrolling>

  <ng-container *ngFor="let column of columns">
    <dxi-column
      *ngIf="!config.hideEmptyColumns || nonEmptyColumns.has(column.dataField)"
      cssClass="_column"
      [dataField]="column.dataField"
      [dataType]="column.dataType"
      [caption]="column.captionKey | translate"
      [alignment]="column.alignment"
      [format]="column.format"
      [allowSorting]="false"
      [allowEditing]="column.allowEditing === undefined ? config.gridActionsEnabled : column.allowEditing"
      [headerCellTemplate]="column.customHeaderTemplate ?? 'headerTemplate'"
    >
      <dxi-validation-rule *ngIf="column.required" type="required"></dxi-validation-rule>
      <dxi-validation-rule
        *ngIf="column.forbidDecimalComma"
        type="custom"
        [validationCallback]="validateForbidDecimalComma"
        message="Only numbers with decimal points are permitted"
      ></dxi-validation-rule>
    </dxi-column>
  </ng-container>

  <dxi-column
    *ngIf="config.gridActionsEnabled"
    [allowSorting]="false"
    headerCellTemplate="headerActionTemplate"
    cellTemplate="actionTemplate"
    cssClass="_action-column"
    [width]="50"
  >
  </dxi-column>

  <div *dxTemplate="let h of 'headerTemplate'" class="_header d-flex">
    <span class="_caption">{{ h.column.caption }}</span>
  </div>

  <div *dxTemplate="let c of customHeaderTemplate.minMax" class="_min-max-header d-flex flex-column">
    <span class="_caption">{{ c.column.caption }}</span>
    <ng-container *ngIf="minMaxHeaderValues[c.column.dataField].min || minMaxHeaderValues[c.column.dataField].max; else defineMinMax">
      <div class="d-flex my-2">
        <div class="d-flex justify-content-center align-items-center _tag _min">
          <span>{{ 'grid.min-prefix' | translate }}&nbsp;{{ minMaxHeaderValues[c.column.dataField].min }}</span>
        </div>
        <div class="d-flex justify-content-center align-items-center _tag _max">
          <span>{{ 'grid.max-prefix' | translate }}&nbsp;{{ minMaxHeaderValues[c.column.dataField].max }}</span>
        </div>
        <div class="d-flex justify-content-center align-items-center" *ngIf="minMaxHeaderValues[c.column.dataField].definedByUser">
          <a (click)="openMinMaxEdit()">
            <i class="material-icons ml-1 min-max-edit">edit</i>
          </a>
        </div>
      </div>
    </ng-container>
    <ng-template #defineMinMax>
      <button type="button" class="btn btn-action min-max-define" (click)="openMinMaxEdit()">Define min/max</button>
    </ng-template>
  </div>

  <div *dxTemplate="let h of 'headerActionTemplate'">
    <div class="d-flex justify-content-center">
      <a (click)="onRowAddClick()">
        <i class="material-icons _action _add">add</i>
      </a>
    </div>
  </div>

  <div *dxTemplate="let cellInfo of 'actionTemplate'">
    <ng-container *ngIf="cellInfo.row.isEditing">
      <div class="d-flex justify-content-between">
        <a (click)="onSaveClick(cellInfo)">
          <i class="material-icons _action pl-1">save</i>
        </a>
        <a (click)="onCancelClick()">
          <i class="material-icons _action _cancel pr-1">clear</i>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="!cellInfo.row.isEditing">
      <div class="d-flex justify-content-center">
        <a (click)="onDeleteClick(cellInfo)">
          <i class="material-icons _action">delete</i>
        </a>
      </div>
    </ng-container>
  </div>
</dx-data-grid>
<app-min-max-edit *ngIf="showMinMaxEdit" (saveMinMax)="saveMinMaxEdit($event)" [columns]="minMaxHeaderValues"> </app-min-max-edit>
