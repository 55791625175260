import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditModalHeaderComponent } from './edit-modal-header/edit-modal-header.component';
import { EditModalContentComponent } from './edit-modal-content/edit-modal-content.component';
import { EditModalComponent } from './edit-modal/edit-modal.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    EditModalComponent,
    EditModalHeaderComponent, 
    EditModalContentComponent
  ],
  exports: [
    EditModalComponent,
    EditModalHeaderComponent,
    EditModalContentComponent
  ]
})
export class EditModalModule { }
