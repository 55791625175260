import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthNGuardService {

  private logger: ILogger;

  constructor(
    private router: Router,
    private authService: AuthService,

    logger: LoggerService
  ) {
    this.logger = logger.getLogger('AuthNGuardService');
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {    
    if (this.authService.user != null) {
      this.logger.debug('Authenticated. Can activate.');
      return true;
    } else {
      this.logger.debug('Unauthenticated. Cannot activate. Redirecting to login.');
      this.router.navigate(['/', 'auth', 'login']);
      return false;
    }
  }
  
}
