import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateFormated'})
export class DateFormatedPipe implements PipeTransform {

  constructor(private datePipe: DatePipe){}

  transform(dateValue: Date, format?: string): string {
    let defaultFormat = 'yyy-MM-dd'

    if (format != null) {
      if (format == 'time') {
        return this.datePipe.transform(dateValue, defaultFormat + ', hh:mm:ss a');
      }
      else {
        return this.datePipe.transform(dateValue, format);
      }
    }

    return this.datePipe.transform(dateValue, defaultFormat);
  }
}