import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Machine } from '../model/machine';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { ProjectMachine } from '../../../projects-modules/machine-services/model/project-machine';

@Injectable()
export class MachinesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/machines';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('MachinesService');
  }

  public getMachines(filter?: QueryFilter): Observable<QueryResult<Machine>> {
    this.logger.debug('Getting machines');

    let url = this.apiUrl;

    return this.http.put<QueryResult<Machine>>(url, filter && filter.toJson()).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No machine found');
          return result;
        }

        this.logger.debug('{0} machine found', result.data.length);

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machines: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getMachine(id: string): Observable<Machine> {
    this.logger.debug('Getting machine with id', id);

    if (!id) {
      this.logger.error('Machine id is null, returning null');
      return null;
    }

    return this.http.get<Machine>(this.apiUrl + '/' + id).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No machine found');
          return result;
        }

        this.logger.debug('Machine found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getProjectMachine(id: string): Observable<ProjectMachine> {
    this.logger.debug('Getting machine with id', id);

    if (!id) {
      this.logger.error('Machine id is null, returning null');
      return null;
    }

    return this.http.get<ProjectMachine>(this.apiUrl + '/' + id + '/projectmachine').pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No Project Machine found');
          return result;
        }

        this.logger.debug('Project Machine found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting Project Machine: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
