
<div class="alert" 
     [class.alert-success]="isSuccess()" 
     [class.alert-info]="isInfo()" 
     [class.alert-warning]="isWarning()"
     [class.alert-danger]="isDanger()"
     (click)="dismissPositive()">
  <button *ngIf="isDismissEnabled()" type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="dismiss()">
    <span class="material-icons">close</span>
  </button>
  <i class="material-icons" *ngIf="isSuccess()">check_circle</i>
  <i class="material-icons" *ngIf="isDanger()">error_outline</i>
  <i class="material-icons" *ngIf="isInfo()">info_outline</i>
  <i class="material-icons" *ngIf="isWarning()">warning</i>
  <span [innerHtml]="getSafeHTML(notification.text)"></span>
  
  <ng-container *ngIf="hasDetails()">
    &nbsp;<button class="btn btn-link show-details" (click)="isShowDetails = true" *ngIf="!isShowDetails">{{ 'notification.details' | translate}}</button>
    <div class="details-container" *ngIf="isShowDetails">
      <textarea class="details" [ngModel]="notification.details" readonly autosize maxHeight="20.125rem"></textarea>
    </div>
  </ng-container>
</div>
