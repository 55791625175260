<div class="row no-gutters align-items-end">
	<div class="col-auto mr-1">
		<ng-content></ng-content>
	</div>
	<div class="col">
		<button class="btn btn-sorting" type="button" (click)="toggle()">
			<div class="arrow" [ngClass]="{'active': isArrowUp()}">
				<i class="material-icons">arrow_drop_up</i>
			</div>
			<div class="arrow" [ngClass]="{'active': isArrowDown()}">
				<i class="material-icons">arrow_drop_down</i>
			</div>
		</button>
	</div>
</div>
