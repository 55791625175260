import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { LoggerService, ILogger } from './logger.service';

@Injectable()
export class MessageBrokerService {

  private topics: { [key: string]: Subject<any> };
  private logger: ILogger;

  constructor(
    logger: LoggerService
  ) { 
    this.logger = logger.getLogger('MessageBrokerService');
    this.topics = {};
  }

  public to(topic: string, message?: any): void {
    this.logger.debug('Publishing into topic', topic);

    if (!this.topics[topic]) {
      this.logger.debug('Topic {0} does not exists, creating', topic);
      this.topics[topic] = new Subject();
    }

    this.topics[topic].next(message);
  }

  public from(topic: string): Observable<any> {
    this.logger.debug('Listening to topic', topic);

    if (!this.topics[topic]) {
      this.logger.debug('Topic {0} does not exists, creating', topic);
      this.topics[topic] = new Subject();
    }

    return this.topics[topic];
  }
}
