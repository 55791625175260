
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { LoggerService, ILogger } from '../../core/shared/logger.service';
import { QueryResult } from '../../core/query-filter/model/QueryResult';
import { QueryFilter } from '../../core/query-filter/model/QueryFilter';
import { environment } from '../../../environments/environment';
import { AssessedMachine } from '../model/assessed-machine';
import { AssessedMachineStatistics } from '../model/assessed-machine-statistics';

@Injectable()
export class AssessedMachinesService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/assessedmachines';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('AssessedMachinesService');
  }

  public getAssessedMachines(filter?: QueryFilter): Observable<QueryResult<AssessedMachine>> {
    this.logger.debug('Getting assessed machines');

    let url = this.apiUrl;

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<AssessedMachine>>(url)
      .pipe(
        tap(result => {
          if (!result || !result.data || result.data.length === 0) {
            this.logger.debug('No assessed machines found');
            return result;
          }

          this.logger.debug('{0} assessed machines found', result.data.length);

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting assessed machines: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public getAssessedMachine(id: string): Observable<AssessedMachine> {
    this.logger.debug('Getting assessed machine with id {0}', id);

    let url = [this.apiUrl, id].join('/');

    return this.http.get<AssessedMachine>(url)
      .pipe(
        tap(result => {
          if (!result) {
            this.logger.debug('No assessed machine found');
            return result;
          }

          this.logger.debug('Assessed machine found');

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting assessed machine: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public getStatistics(filter?: QueryFilter): Observable<AssessedMachineStatistics> {
    this.logger.debug('Getting assessed machines statistics');

    let url = [
      this.apiUrl,
      'statistics'
    ].join('/');

    return this.http.put<AssessedMachineStatistics>(url, filter && filter.toJson())
      .pipe(
        tap(result => {
          if (!result) {
            this.logger.debug('No assessed machine statistics found');
            return;
          }

          this.logger.debug('Assessed machines statistics found');
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting assessed machine statistics: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

}
