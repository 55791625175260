export class Role {
  public static readonly administrator: string = 'Administrator';
  public static readonly engineer: string = 'Engineer';
  public static readonly customerAdministrator: string = 'CustomerAdministrator';
  public static readonly customerEngineer: string = 'CustomerEngineer';

  //deprecated role, not used anymore (now using CustomerImplementer)
  //TODO clarify if we are still using this role.
  public static readonly customerEngineerLight: string = 'CustomerEngineerLight';
  public static readonly customerImplementer: string = 'CustomerImplementer';
  public static readonly customerReader: string = 'CustomerReader';
  public static readonly customerManufacturer: string = 'CustomerManufacturer';
  public static readonly powerEngineer: string = 'PowerEngineer';
}
