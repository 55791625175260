import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { NotificationsService } from '../../../core/notifications/shared/notifications.service';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MessageBrokerService } from '../../../core/shared/message-broker.service';
import { MachineAssessedHazardsService } from '../../../implementations-modules/assessed-machines/shared/machine-assessed-hazards.service';
import { MachineHazard } from '../../machine-services/model/machine-hazard';
import { MachineHazardAction } from '../../machine-services/model/machine-hazard-action';
import { MultiHazardActionByMachineDto } from '../../machine-services/model/multi-hazard-action-by-machine-dto';
import { MultiHazardActionDto } from '../../machine-services/model/multi-hazard-action-dto';
import { MachineHazardsService } from '../shared/machine-hazards.service';
import { MachineAssessedHazardsMemoryService } from './../../../implementations-modules/assessed-machines/shared/machine-assessed-hazards-memory.service';

@Injectable()
export class InternalMachineHazardActionsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private readonly assessedMachinesApiUrl: string = environment.serverUrl + '/v1/assessedmachines';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;
  private hazardId: string;
  private hazardIds: string[];

  public filter: QueryFilter = new QueryFilter();

  refreshSubscription: any;
  machineHazards: MachineHazard[];

  constructor(
    private http: HttpClient,
    logger: LoggerService,
    private machineHazardsService: MachineHazardsService,
    private machineAssessedHazardsService: MachineAssessedHazardsService,
    private machineAssessedHazardsMemoryService: MachineAssessedHazardsMemoryService,
    private messageBroker: MessageBrokerService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {
    this.logger = logger.getLogger('InternalMachineHazardsService');
  }

  public setProject(projectId: string): InternalMachineHazardActionsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalMachineHazardActionsService {
    this.machineId = machineId;
    return this;
  }

  public setHazard(hazardId: string): InternalMachineHazardActionsService {
    this.hazardId = hazardId;
    return this;
  }

  public setHazardIds(hazardIds: string[]): InternalMachineHazardActionsService {
    this.hazardIds = hazardIds;
    return this;
  }

  public save(action: MachineHazardAction): Observable<MachineHazardAction> {
    this.logger.debug('Saving hazard action');

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Project Machine Hazard Id not set. Use setHazard() first');
    }

    const url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards', this.hazardId, 'actions'].join('/');

    return this.http.post<MachineHazardAction>(url, action).pipe(
      tap((_) => {
        this.logger.debug('Project machine hazard action saved');
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving machine hazard action: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public saveByHazardId(action: MachineHazardAction, hazardId: string): Observable<MachineHazardAction> {
    this.logger.debug('Saving hazard action by hazard id: ', hazardId);

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    const url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards', hazardId, 'actions'].join('/');

    return this.http.post<MachineHazardAction>(url, action).pipe(
      tap((_) => {
        this.logger.debug('Project machine hazard action saved');
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving machine hazard action: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  //TODO removed if https://jira.evolit.com/browse/TSX-16490 works without issues
  public saveMultiHazardActionDto(action: MultiHazardActionDto): Observable<any> {
    this.logger.debug('Saving hazard action', action);

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    const url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazardactionmassupdate'].join('/');

    return this.http.put<MultiHazardActionDto>(url, action).pipe(
      first(),
      tap((_) => {
        this.logger.debug('Project machine hazard action saved');
      }),
      shareReplay(1),
      catchError((err) => {
        this.logger.error('Error saving machine hazard action: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public saveMultiHazardActionByMachineDto(action: MultiHazardActionDto): Observable<any> {
    this.logger.debug('Saving MultiHazardActionByMachineDto', action);

    const selectedHazards = this.machineAssessedHazardsMemoryService.getSelectedHazards();

    const mhabpDto = MultiHazardActionByMachineDto.makeDtoFromAssessedHazards(selectedHazards, action);
    this.logger.debug('saveMultiHazardActionByMachineDto Selected Hazards ', selectedHazards);

    const url = [this.assessedMachinesApiUrl, 'hazardactionmassupdatebymachine'].join('/');

    return this.http.put<MultiHazardActionByMachineDto>(url, mhabpDto).pipe(
      first(),
      tap((_) => {
        this.logger.debug('Project machine hazard action saved');
      }),
      shareReplay(1),
      catchError((err) => {
        this.logger.error('Error saving machine hazard action: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
