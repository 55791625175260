import { Component, OnInit, HostListener, NgZone } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { AuthService } from '../shared/auth.service';
import { LoggerService, ILogger } from '../../core/shared/logger.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { ElectronService } from 'ngx-electron';


@Component({
  selector: 'auth-refresh',
  templateUrl: './auth-refresh.component.html',
  styleUrls: ['./auth-refresh.component.scss']
})
export class AuthRefreshComponent implements OnInit {

  public src: SafeResourceUrl;

  private logger: ILogger;
  private refreshObserver: Subject<string> = new Subject<string>();
  private timeout: any;
  private isCancelled: boolean = false;

  constructor(
    private electron: ElectronService,
    private zone: NgZone,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('AuthRefreshComponent');
  }

  public ngOnInit() {
    this.logger.debug('Initializing AuthRefreshComponent');
    this.authService.registerAuthRefreshComponent(this);
  }

  @HostListener('window:silentRefreshFinished', ['$event'])
  public silentRefreshFinished(event: any): void {
    if (this.isCancelled) {
      return;
    }

    this.timeout && clearTimeout(this.timeout);
    this.src = '';

    if (event.detail && event.detail.hash) {
      const hash = event.detail.hash;
      
      this.logger.debug('Silent refresh finished, forwarding hash');

      this.refreshObserver.next(hash);
    } else if (!event.detail || !event.detail.hash) {
      this.logger.debug('Silent refresh finished but no hash found, skipping.');
      this.refreshObserver.error('Silent refresh finished but no hash found');
    } else {
      this.logger.debug('Silent refresh finished but unexpected error occured.');
      this.refreshObserver.error('Silent refresh finished but unexpected error occured');
    }
  }

  public refreshToken(authUrl: string): Observable<string> {

    this.logger.debug('Refresh token called');

    return new Observable(subscriber => {
      this.logger.debug('Refreshing token');

      this.isCancelled = false;
      this.refreshObserver = new Subject<string>();
      
      if (this.isDesktop() && this.electron.ipcRenderer.listenerCount('hash-received') === 0) { 
        this.electron.ipcRenderer.once('hash-received', (event, hash) => this.zone.run(_ => {
          this.logger.debug('Hash received');

          this.silentRefreshFinished({ detail: { hash: hash } });
        }));
      }

      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(authUrl); 

      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(_ => {
        this.logger.debug('Page not loaded on time, cancelling');

        this.isCancelled = true;
        this.src = '';
        this.refreshObserver.error('Page not loaded in limit');
      }, 30000);

      subscriber.next();
    })
    .pipe(
      switchMap(_ => this.refreshObserver)
    );
  }

  private isDesktop(): boolean {
    return environment.uwp === true && this.electron.isElectronApp;
  }
}
