import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent {

  public hidden: boolean = true;

  @Input() size: string = '';
  @Output() onHide: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.hidden = false;
    }, 1);
  }

  public hide(): Observable<void> {
    this.hidden = true;
    
    return Observable.create(observer => {
      setTimeout(() => {
        observer.next();
      }, 200);
    });
  }

  public keyup(event): void {
    if (event.key === 'Escape') {
      this.hideInternal();
    }
  }

  public hideInternal(): void {
    this.onHide.emit(true);
  }
}
