import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { AppAnalytics } from '../logging/shared/analytics';

@Injectable()
export class AnalyticsErrorHandlerService extends ErrorHandler {

  constructor(
    
  ) {
    super();
  }

  handleError(error: any): void {
    AppAnalytics.error(error);

    super.handleError(error);
  }
}
