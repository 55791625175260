import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Laboratory } from '../../../settings-modules/laboratories/model/laboratory';
import { QueryFilter } from '../../query-filter/model/QueryFilter';
import { QueryResult } from '../../query-filter/model/QueryResult';
import { ILogger, LoggerService } from '../../shared/logger.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileLaboratoryService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/laboratory';
  private logger: ILogger;
  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('LaboratoriesService');
  }
  public getLaboratories(filter?: QueryFilter): Observable<QueryResult<Laboratory>> {
    this.logger.debug('Getting laboratories');

    let url = this.apiUrl;

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<Laboratory>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No laboratories found');
          return result;
        }

        this.logger.debug('{0} laboratories found', result.data.length);
        return result;
      }),
      catchError((err) => {
        this.logger.error('Error getting laboratories: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }
}
