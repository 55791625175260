import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSelectDirective } from './file-select/file-select.directive';
import { FileDropComponent } from './file-drop/file-drop.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [FileSelectDirective, FileDropComponent],
  exports: [FileSelectDirective, FileDropComponent]
})
export class FileUploadModule {}
