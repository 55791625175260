
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { Organization } from '../model/organization';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';

@Injectable()
export class OrganizationsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/organizations';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('OrganizationsService');
  }

  public getOrganizations(filter?: QueryFilter): Observable<QueryResult<Organization>> {
    this.logger.debug('Getting organizations');

    let url = this.apiUrl;

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<Organization>>(url)
      .pipe(
        tap(result => {
          if (!result || !result.data || result.data.length == 0) {
            this.logger.debug('No organizations found');
            return result;
          }

          this.logger.debug('{0} organizations found', result.data.length);

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting organizations: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public getOrganization(id: string): Observable<Organization> {
    this.logger.debug('Getting organization with id', id);

    if (!id) {
      this.logger.error('Organization id is null, returning null');
      return of(null);
    }

    return this.http.get<Organization>(this.apiUrl + '/' + id)
      .pipe(
        tap(result => {
          if (!result) {
            this.logger.debug('No organization found');
            return result;
          }

          this.logger.debug('Organization found');

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting organization: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
