import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, ExtraOptions, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AuthCallbackComponent } from './auth/auth-callback/auth-callback.component';
import { AuthOfflineComponent } from './auth/auth-offline/auth-offline.component';
import { NoAuthComponent } from './auth/no-auth/no-auth.component';
import { AuthNGuardService } from './auth/shared/auth-n-guard.service';
import { BreadcrumbLabelResolver } from './core/breadcrumbs/shared/breadcrumb-label-resolver';
import { LayoutComponent } from './core/layout/layout/layout.component';
import { PageNotFoundComponent } from './core/layout/page-not-found/page-not-found.component';
import { LoadingComponent } from './core/loading/loading.component';

const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full' },
  { path: 'start', component: LoadingComponent },
  { path: 'auth/callback', component: AuthCallbackComponent },
  { path: 'auth/offline', component: AuthOfflineComponent },
  { path: 'auth/login', component: NoAuthComponent },
  {
    path: 'notifications',
    outlet: 'notifications',
    loadChildren: () => import('app/user-notifications/user-notifications.module').then((m) => m.UserNotificationsModule)
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthNGuardService],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('app/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: {
          breadcrumb: null
        },
        resolve: {
          breadcrumbResolver: 'breadcrumbTranslateDashboard'
        }
      },
      {
        path: 'assessments',
        data: {
          breadcrumb: null
        },
        resolve: {
          breadcrumbResolver: 'breadcrumbTranslateAssessment'
        },
        loadChildren: () => import('app/projects/projects.module').then((m) => m.ProjectsModule)
      },
      {
        path: 'documents',
        data: {
          breadcrumb: null
        },
        resolve: {
          breadcrumbResolver: 'breadcrumbTranslateDocuments'
        },
        loadChildren: () => import('app/documents/documents.module').then((m) => m.DocumentsModule)
      },
      {
        path: 'implementations',
        loadChildren: () => import('app/implementations/implementations.module').then((m) => m.ImplementationsModule),
        data: {
          breadcrumb: null
        },
        resolve: {
          breadcrumbResolver: 'breadcrumbTranslateImplementations'
        }
      },
      {
        path: 'settings',
        data: {
          breadcrumb: true
        },
        loadChildren: () => import('app/settings/settings.module').then((m) => m.SettingsModule)
      }
    ]
  },
  {
    path: '**',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: PageNotFoundComponent
      }
    ]
  }
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  useHash: environment.uwp
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
  providers: [
    {
      provide: 'breadcrumbTranslateDashboard',
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => ({
        resolve: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): BreadcrumbLabelResolver => ({
          getLabel: () => translateService.get('breadcrumb.dashboard')
        })
      })
    },
    {
      provide: 'breadcrumbTranslateImplementations',
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => ({
        resolve: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): BreadcrumbLabelResolver => ({
          getLabel: () => translateService.get('breadcrumb.implementations')
        })
      })
    },
    {
      provide: 'breadcrumbTranslateDocuments',
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => ({
        resolve: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): BreadcrumbLabelResolver => ({
          getLabel: () => translateService.get('breadcrumb.documents')
        })
      })
    },
    {
      provide: 'breadcrumbTranslateAssessment',
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => ({
        resolve: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): BreadcrumbLabelResolver => ({
          getLabel: () => translateService.get('breadcrumb.assessments')
        })
      })
    }
  ]
})
export class AppRoutingModule {}
