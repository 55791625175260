import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PhotosURLResolver } from '../../machine-photos/shared/photos-url-resolver';
import { Photo } from '../../machine-services/model/photo';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectHazardPhotosURLResolverService {

  private apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('ProjectHazardPhotosURLResolverService');
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): PhotosURLResolver {
    const projectId = route.params['projectId'];
    const otherMachineId = route.params['otherMachineId'];
    const hazardId = route.params['hazardId'];

    this.logger.debug('Creating ProjectHazardPhotosURLResolverService for project {0} nad machine {1} and hazard {2}', projectId, otherMachineId, hazardId);

    return {
      getURL: (photo: Photo, size: string, extra?: string): Observable<string> => {
        const url =
        [
          this.apiUrl,
          projectId,
          'projectmachines',
          otherMachineId,
          'hazards',
          hazardId,
          'photos',
          photo.id
        ].join('/') + '?size=' + size + (extra ? '&' + extra : '');

        return this.http.get(url, { responseType: 'blob' }).pipe(map((blob) => window.URL.createObjectURL(blob)));
      }
    };
  }
}
