import { Injectable } from '@angular/core';
import { LoggerService, ILogger } from './logger.service';
import { Notification } from '../notifications/model/Notification';
import { NotificationsService } from '../notifications/shared/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ClipboardService {

  private logger: ILogger;
  private clipboardNotification: Notification;
  private content: any;

  constructor(
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    logger: LoggerService
  ) { 
    this.logger = logger.getLogger('ClipboardService');
  }

  public add(content: any, message: string): void {
    this.logger.debug('Adding content to clipboard');

    if (this.clipboardNotification) {
      this.clipboardNotification.dismiss();
    }

    this.content = content;

    this.clipboardNotification = 
      this.notificationsService.info(this.translateService.instant('clipboard.notification.content-added', { message: message }), false);

    this.clipboardNotification.dismissed = () => {
      this.content = null;
    };
  }

  public getContent(): any {
    return this.content;
  }

  public clear(): void {
    this.logger.debug('Clearing clipboard');
    this.clipboardNotification.dismiss();
  }
}
