import { Injectable } from '@angular/core';
import { UserNotificationHandler } from './user-notification-handler';
import { UserNotification } from '../model/user-notification';
import { Observable, from } from 'rxjs';
import { LoggerService, ILogger } from '../../core/shared/logger.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HazardImplementationHandlerService implements UserNotificationHandler {

  private logger: ILogger;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    logger: LoggerService
  ) { 
    this.logger = logger.getLogger('HazardImplementationHandlerService');
  }

  public getNotificationTypes(): Array<{ id: string, label: string }> {
    const result = [
      { id: 'hazard_in_implementation', label: '' },
      { id: 'hazard_implemented', label: '' },
      { id: 'hazard_ignored', label: '' },
      { id: 'hazard_accepted', label: '' },
      { id: 'hazard_rejected', label: '' }
    ];

    result.forEach(item => {
      const state = item.id
        .split('_')
        .slice(1)
        .join('_');

      item.label =
        `${this.translateService.instant('user-notifications.handler.hazard.title')} ${this.translateService.instant('user-notifications.handler.hazard.' + state)}`;
    });

    return result;
  }

  formatTitle(notification: UserNotification): string {
    this.logger.debug('Formating notification title with id', notification.id);

    if (!notification || !notification.type) {
      this.logger.debug('Notification is null, skipping');
      return null;
    }

    const state = notification.type
      .split('_')
      .slice(1)
      .join('_');

    return `${this.translateService.instant('user-notifications.handler.hazard.title')} ${this.translateService.instant('user-notifications.handler.hazard.' + state)}`;
  }

  public formatDescription(notification: UserNotification): string {
    this.logger.debug('Formating notification with id', notification.id);

    if (!notification || !notification.type || !notification.additionalInfo) {
      this.logger.debug('Notification is null, skipping');
      return null;
    }

    const project = notification.additionalInfo['project'] && notification.additionalInfo['project'].name || 'Unknown';
    const hazardNr = notification.additionalInfo['hazard'] && notification.additionalInfo['hazard'].name || 'Unknown';
    const machineSerialNr = notification.additionalInfo['projectMachine'] && notification.additionalInfo['projectMachine'].name || 'Unknown'; 
    const machineType = notification.additionalInfo['projectMachineType'] && notification.additionalInfo['projectMachineType'].name || 'Unknown'; 

    return `Project: ${project} | Hazard: ${hazardNr} | Machine: ${machineSerialNr} (${machineType})`;
  }
  
  public navigate(notification: UserNotification): Observable<void> {
    this.logger.debug('Navigating to user notification with id', notification.id);

    if (!notification || !notification.type || !notification.additionalInfo) {
      this.logger.debug('Notification is null, skipping');
      return null;
    }

    const companyId = notification.company && notification.company.id || null;
    const projectId = notification.additionalInfo['project'] && notification.additionalInfo['project'].id || null;
    const projectMachineId = notification.additionalInfo['projectMachine'] && notification.additionalInfo['projectMachine'].id || null;
    const hazardId = notification.additionalInfo['hazard'] && notification.additionalInfo['hazard'].id || null;
    const machineId = notification.additionalInfo['machine'] && notification.additionalInfo['machine'].id || null;
    
    const route = ['implementations', companyId, 'machines', machineId, 'hazards', projectId, projectMachineId, hazardId, 'implementation'];

    return from(this.router.navigate(route))
      .pipe(
        map(_ => null)
      );
  }

}
