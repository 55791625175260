import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { LoggerService, ILogger } from '../../core/shared/logger.service';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-no-auth',
  templateUrl: './no-auth.component.html',
  styleUrls: ['./no-auth.component.scss']
})
export class NoAuthComponent implements OnInit {

  private logger: ILogger;

  constructor(
    private authService: AuthService,
    logger: LoggerService
  ) { 
    this.logger = logger.getLogger('NoAuthComponent');
  }

  public ngOnInit(): void {
    this.logger.debug('Initializing component');

    if (environment.uwp === true) {
      this.logger.debug('Is desktop, calling callback');
      this.authService.callback().pipe(first()).subscribe();
    }
  }
}
