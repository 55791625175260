import { Injectable } from '@angular/core';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { AssessedHazard } from '../../../implementations-services/model/assessed-hazard';

@Injectable({
  providedIn: 'root'
})
export class MachineAssessedHazardsMemoryService {
  private logger: ILogger;

  private selectedHazards: any[];

  constructor(logger: LoggerService) {
    this.logger = logger.getLogger('MachineAssessedHazardsMemoryService');
  }

  public setSelectedHazards(hazards: any[]): MachineAssessedHazardsMemoryService {
    this.logger.debug('Setting selected hazards');
    this.selectedHazards = hazards;
    return this;
  }

  public getSelectedHazards(): any[] {
    this.logger.debug('Getting selected hazards');
    return this.selectedHazards;
  }
}
