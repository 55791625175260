import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { AuthService } from '../../auth/shared/auth.service';
import { ProjectDocument } from '../../projects-services/model/project-document';
import { DocumentsProjectsService } from './documents-projects.service';

@Injectable()
export class DocumentsProjectDocumentsService {

  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(
    private authService: AuthService,
    private projectsService: DocumentsProjectsService,
    loggerService: LoggerService
  ) {
    this.logger = loggerService.getLogger('DocumentsProjectDocumentsService');
  }

  public setProject(projectId: string): DocumentsProjectDocumentsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): DocumentsProjectDocumentsService {
    this.machineId = machineId;
    return this;
  }

  public getDocuments(): Observable<Array<ProjectDocument>> {

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting documents for project {0}', this.projectId);

    return this.projectsService.getDocumentsProject(this.machineId, this.projectId)
      .pipe(
        first(),
        map(project => {
          const providerId = this.authService.user.provider;
          const organizationId = this.authService.user.organization;
          let allowedCategories = [];

          if (organizationId === providerId) {
            allowedCategories = ['mandatory', 'customer', 'operator', '', null, undefined];
          } else {
            if (organizationId === project.company.id) {
              allowedCategories = ['mandatory', 'customer'];
            } else {
              allowedCategories = ['mandatory', 'operator'];
            }
          }

          const documents = project.documents && project.documents.filter(item => allowedCategories.includes(item.category)) || [];
          return JSON.parse(JSON.stringify(documents)); //create copy because machine instance is shared
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting documents project documents: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }

  public getDocument(documentId: string): Observable<ProjectDocument> {

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting document with id {0} from machine {1}', documentId, this.projectId);

    return this.projectsService.getDocumentsProject(this.machineId, this.projectId)
      .pipe(
        first(),
        map(project => {
          const providerId = this.authService.user.provider;
          const organizationId = this.authService.user.organization;
          let allowedCategories = [];

          if (organizationId === providerId) {
            allowedCategories = ['mandatory', 'customer', 'operator', '', null, undefined];
          } else {
            if (organizationId === project.company.id) {
              allowedCategories = ['mandatory', 'customer'];
            } else {
              allowedCategories = ['mandatory', 'operator'];
            }
          }

          const machineDocument = project.documents && project.documents.filter(item => allowedCategories.includes(item.category)).find(item => item.id === documentId);

          return machineDocument && JSON.parse(JSON.stringify(machineDocument)); //create copy because machine instance is shared
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting documents project document: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }

}
