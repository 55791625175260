import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MachineHazard } from '../../machine-services/model/machine-hazard';

@Injectable()
export class InternalMachineAssessmentHazardsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalMachineAssessmentHazardsService');
  }

  public setProject(projectId: string): InternalMachineAssessmentHazardsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalMachineAssessmentHazardsService {
    this.machineId = machineId;
    return this;
  }

  public create(hazards: Array<MachineHazard>): Observable<Array<string>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Creating {0} machine hazards for project {1} and machine {2}', hazards.length, this.projectId, this.machineId);

    const method = 'POST';
    const url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards'].join('/');

    if (!hazards || hazards.length === 0) {
      this.logger.debug('Nothing to create, skipping');
      return of([]);
    }

    return this.http
      .request(method, url, {
        body: hazards
      })
      .pipe(
        tap((_) => {
          this.logger.debug('Machine hazards saved');
        }),
        map((results) => results['ids']),
        catchError((err, caught) => {
          this.logger.error('Error saving machine hazards: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
