import { ServerFile } from '../model/server-file';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ServerFileDownloader {

	constructor() { }

	public download(file: ServerFile): Observable<void> {
		let url;
		let download;
		
		if (file.blob) {
			url = window.URL.createObjectURL(file.blob);
			download = file.name;
		} else {
			url = file.url;
			download = '';
		}

		const anchor = document.createElement('a');
		anchor.download = download;
		anchor.href = url;
		anchor.click();

		if (file.blob) {
			setTimeout(_ => {
				window.URL.revokeObjectURL(url);
			}, 1000);
		}

		return of(null);
	}

}