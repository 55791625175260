import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MachineType } from '../model/machine-type';

@Injectable()
export class MachineTypesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/machinetypes';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('MachineTypesService');
  }

  public getMachineTypes(filter?: QueryFilter): Observable<QueryResult<MachineType>> {
    this.logger.debug('Getting machine types');

    let url = this.apiUrl;

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<MachineType>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No machine types found');
          return result;
        }

        this.logger.debug('{0} machine types found', result.data.length);
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine types: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getMachineType(id: string): Observable<MachineType> {
    this.logger.debug('Getting machine type with id', id);

    if (!id) {
      this.logger.error('Machine Type id is null, returning null');
      return of(null);
    }

    return this.http.get<MachineType>(this.apiUrl + '/' + id).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No machine type found');
          return result;
        }

        this.logger.debug('Machine type found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine type: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
