export enum FileWhitelist {
  pdf = 'application/pdf',
  zip = 'application/zip',

  xZipCompressed = 'application/x-zip-compressed',

  xZip = 'application/x-zip',
  xCompressed = 'application/x-compressed',
  rar = 'application/vnd.rar',
  xrar = 'application/x-rar-compressed',
  rarCompressed = 'application/rar-compressed',
  rarApplication = 'application/rar-application',
  ppt = 'application/vnd.ms-powerpoint',
  pps = 'application/vnd.ms-powerpoint',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  msg = 'application/vnd.ms-outlook',
  imagePng = 'image/png',
  mov = 'video/quicktime',
  mp4 = 'video/mp4',
  rtf = 'application/rtf',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  plainText = 'text/plain',
  docm = 'application/vnd.ms-word.document.macroEnabled.12',
  imageGif = 'image/gif',
  avi = 'video/x-msvideo',
  imageJpeg = 'image/jpeg',

  vndMsExcel = 'application/vnd.ms-excel',
  msexcel = 'application/msexcel',
  xMsexcel = 'application/x-msexcel',
  xMsExcel = 'application/x-ms-excel',
  xExcel = 'application/x-excel',
  xDosMsExcel = 'application/x-dos_ms_excel',
  xls = 'application/xls',
  xXls = 'application/x-xls',
  vndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

  extDoc = '.doc',
  extXls = '.xls',
  extPdf = '.pdf',
  extIif = '.iif',
  extTxt = '.txt',
  extZip = '.zip',
  extRar = '.rar',
  extPpt = '.ppt',
  extPps = '.pps',
  extDocx = '.docx',
  extXlsx = '.xlsx',
  extRdb2 = '.rdb2',
  extMsg = '.msg',
  extJpg = '.jpg',
  extPng = '.png',
  extMov = '.mov',
  extMp4 = '.mp4',
  ext7z = '.7z',
  extRtf = '.rtf',
  extPptx = '.pptx',
  extDocm = '.docm',
  extGif = '.gif',
  extAvi = '.avi',
  extXlm = '.xlm',
  extJpeg = '.jpeg'
}
