
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';
import { MachineStandard } from '../model/machine-standard';


@Injectable()
export class InternalMachineStandardsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private readonly apiUrlProjectMachines: string = 'projectMachines';
  private readonly apiUrlStandards: string = 'Standards';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('InternalMachineStandardsService');
  }

  public setProject(projectId: string): InternalMachineStandardsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalMachineStandardsService {
    this.machineId = machineId;
    return this;
  }

  public create(standards: Array<MachineStandard>): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Creating project standards for machine {0} from project {1}', this.machineId, this.projectId);

    const method = 'POST';
    let url = [
      this.apiUrl, 
      this.projectId, 
      this.apiUrlProjectMachines, 
      this.machineId, 
      this.apiUrlStandards
    ].join('/');

    return this.http.request<void>(method, url, {
      body: standards
    }).pipe(
      tap(result => {
        this.logger.debug('Machine standards created');
      }),
      catchError((err, caught) => {
        this.logger.error('Error creating machine standards: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public update(standard: MachineStandard): Observable<MachineStandard> {

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Updating standard {0} for machine {1} from project {2}', standard.id, this.machineId, this.projectId);

    const method = 'PUT';
    let url = [
      this.apiUrl, 
      this.projectId, 
      this.apiUrlProjectMachines, 
      this.machineId, 
      this.apiUrlStandards, 
      standard.id
    ].join('/');

    return this.http.request<MachineStandard>(method, url, {
      body: standard
    }).pipe(
      tap(result => {
        this.logger.debug('Machine standard updated');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error updating machine standard: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public delete(standard: MachineStandard): Observable<void> {
    this.logger.debug('Deleting machine standard with id', standard.id);

    let url = [
      this.apiUrl, 
      this.projectId, 
      this.apiUrlProjectMachines, 
      this.machineId, 
      this.apiUrlStandards, 
      standard.id
    ].join('/');

    return this.http.delete<void>(url)
      .pipe(
        tap(() => {
          this.logger.debug('Machine standard deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting machine standard: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

}
