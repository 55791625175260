
<div class="relogin" [ngClass]="{'hidden': hidden}">

  <div class="relogin-overlay" [ngClass]="{'hidden': reloginHidden}"></div>
    
  <div class="relogin-container" [ngClass]="{'hidden': reloginHidden}">
    <iframe class="frame" [src]="src" (load)="loaded = true" *ngIf="src" sandbox="allow-same-origin allow-scripts" ></iframe>

    <div class="relogin-loading" [ngClass]="{'hidden': loaded}">
      Loading...
    </div>
  </div>

</div>