import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Pointer } from '../../core/model/pointer';
import { Division } from '../../settings-modules/companies/model/division';
import { Location } from '../../settings-modules/companies/model/location';
import { LoggerService, ILogger } from '../../core/shared/logger.service';
import { ModelService } from '../../core/shared/model.service';
import { SitesService } from '../../settings-modules/sites/shared/sites.service';
import { first, map } from 'rxjs/operators';
import { QueryFilter } from '../../core/query-filter/model/QueryFilter';
import { Company } from '../../settings-modules/companies/model/company';

@Injectable()
export class ImplementationsHelperService {
  private logger: ILogger;

  constructor(private modelService: ModelService, private sitesService: SitesService, logger: LoggerService) {
    this.logger = logger.getLogger('ImplementationsHelperService');
  }

  public getDefaultSites(companyId: string): Observable<Array<Pointer>> {
    this.logger.debug('Getting default sites of company for user');

    return this.sitesService.getSites(new QueryFilter().take(10000).equalTo('company.id', companyId)).pipe(
      first(),
      map((sites) => sites.data.map((item) => this.modelService.toSitePointer(item)))
    );
  }

  public getSites(
    company: Company,
    divisions: Array<Division>,
    locations: Array<Location>,
    defaultSites: Array<Pointer>
  ): Observable<Array<Pointer>> {
    this.logger.debug('Getting sites for divisions and locations');

    if ((!divisions || divisions.length === 0) && (!locations || locations.length === 0)) {
      this.logger.debug('No division or locations defined, skipping');
      return (defaultSites && of(defaultSites.map((item) => this.modelService.toSitePointer(item)))) || of([]);
    }

    if (!company) {
      this.logger.debug('No company defined, skipping');
      return (defaultSites && of(defaultSites.map((item) => this.modelService.toSitePointer(item)))) || of([]);
    }

    const filter = new QueryFilter().take(10000).keys(['id', 'name']).equalTo('company.id', company.id);

    if (divisions && divisions.length > 0) {
      filter.containedIn(
        'division.id',
        divisions.map((item) => item.id)
      );
    }

    if (locations && locations.length > 0) {
      filter.containedIn(
        'locations.id',
        locations.map((item) => item.id)
      );
    }

    return this.sitesService.getSites(filter).pipe(
      map((sites) => {
        if (sites && sites.count > 0) {
          return sites.data.map((item) => this.modelService.toSitePointer(item));
        }

        return [];
      })
    );
  }
}
