import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { MachineDeclaration } from '../../machine-services/model/machine-declaration';

@Injectable()
export class InternalMachineDeclarationsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalMachineDeclarationsService');
  }

  public setProject(projectId: string): InternalMachineDeclarationsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalMachineDeclarationsService {
    this.machineId = machineId;
    return this;
  }

  public save(declaration: MachineDeclaration): Observable<MachineDeclaration> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug(
      'Saving machine declaration with id {0} in machine {1} from project {2}',
      declaration.id,
      this.machineId,
      this.projectId
    );

    const method = declaration.id != null ? 'PUT' : 'POST';

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'declarations',
      declaration.id != null ? declaration.id : ''
    ].join('/');

    return this.http
      .request<MachineDeclaration>(method, url, {
        body: declaration
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Machine declaration saved');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error saving machine declaration: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public delete(declaration: MachineDeclaration): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug(
      'Deleting machine declaration with id {0} in machine {1} from project {2}',
      declaration.id,
      this.machineId,
      this.projectId
    );

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'declarations', declaration.id].join('/');

    return this.http.delete<void>(url).pipe(
      tap((_) => {
        this.logger.debug('Machine declaration deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting machine declaration: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
