import { Component, OnInit, ViewEncapsulation, ViewChild, forwardRef } from '@angular/core';
import { NgbDateStruct, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatedPipe } from '../../pipe/dateFormated.pipe';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;


@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateRangePickerComponent),
    multi: true
  }]
})
export class DateRangePickerComponent implements OnInit, ControlValueAccessor {

  @ViewChild('datePickerPopover', { static: true })
  public datePickerPopover: NgbPopover;

  public formattedDate: string;

  private hoveredStruct: NgbDateStruct;
  private fromStruct: NgbDateStruct;
  private toStruct: NgbDateStruct;
  private fromDate: Date;
  private toDate: Date;


  private propagateChange = (_: any) => { };

  constructor(
    private datePipe: DateFormatedPipe
  ) { }

  ngOnInit() { }

  public onDateSelection(struct: NgbDateStruct): void {
    if (!this.fromDate && !this.toDate) {
      this.fromStruct = struct;
    } else if (this.fromStruct && !this.toStruct && after(struct, this.fromStruct)) {
      this.toStruct = struct;
      this.datePickerPopover.close();
    } else {
      this.toStruct = null;
      this.fromStruct = struct;
    }

    if (this.fromStruct) {
      this.fromDate = new Date(this.fromStruct.year, this.fromStruct.month - 1, this.fromStruct.day);
    } else {
      this.fromDate = null;
    }

    if (this.toStruct) {
      this.toDate = new Date(this.toStruct.year, this.toStruct.month - 1, this.toStruct.day);
    } else {
      this.toDate = null;
    }

    this.formatDate();
    this.propagateChange && this.propagateChange([this.fromDate, this.toDate]);
  }

  public isToday(date: NgbDateStruct): boolean {
    const now = new Date();
    return equals(date, { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() });
  }

  public isHovered(date: NgbDateStruct): boolean {
    return this.fromDate && !this.toDate && this.hoveredStruct && after(date, this.fromStruct) && before(date, this.hoveredStruct);
  }

  public isInside(date: NgbDateStruct): boolean {
    return after(date, this.fromStruct) && before(date, this.toStruct);
  }

  public isFrom(date: NgbDateStruct): boolean {
    return equals(date, this.fromStruct);
  }

  public isTo(date: NgbDateStruct): boolean {
    return equals(date, this.toStruct);
  }

  public writeValue(obj: any): void {
    if (!obj) {
      this.fromDate = null;
      this.fromStruct = null
      this.toDate = null;
      this.toStruct = null;
      this.formattedDate = null;
    }

    //TODO: implement setting value based on obj
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    //nothing
  }

  public setDisabledState?(isDisabled: boolean): void {
    //nothing
  }

  private formatDate(): void {
    if (this.fromDate) {
      this.formattedDate = this.datePipe.transform(this.fromDate);
    }

    this.formattedDate += ' - ';

    if (this.toDate) {
      this.formattedDate += this.datePipe.transform(this.toDate);
    }
  }
}
