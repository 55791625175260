import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { ProjectMachine } from '../../machine-services/model/project-machine';

@Injectable()
export class InternalProjectMachinesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalProjectsService');
  }

  public setProject(projectId: string): InternalProjectMachinesService {
    this.projectId = projectId;
    return this;
  }

  public update(machine: ProjectMachine): Observable<ProjectMachine> {
    this.logger.debug('Updating project machine with id {0} for project {1}', machine.id, this.projectId);

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    const method = machine.id != null ? 'PUT' : 'POST';
    const url = [this.apiUrl, this.projectId, 'projectmachines'].join('/') + (machine.id != null ? '/' + machine.id : '');

    return this.http
      .request<ProjectMachine>(method, url, {
        body: machine
      })
      .pipe(
        tap((_) => {
          this.logger.debug('Project machine saved');
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating project machine: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public updateMoveMachine(machine: ProjectMachine, originProjectId: string, originProjectMachineId: string): Observable<ProjectMachine> {
    this.logger.debug('Updating project machine with id {0} for project {1}', machine.id, this.projectId);

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    const method = 'PUT';
    const url =
      [this.apiUrl, this.projectId, 'projectmachines'].join('/') +
      (machine.id != null ? '/' + machine.id : '') +
      '/moved?originProjectId=' +
      originProjectId +
      '&originProjectMachineId=' +
      originProjectMachineId;

    return this.http
      .request<ProjectMachine>(method, url, {
        body: machine
      })
      .pipe(
        tap((_) => {
          this.logger.debug('Project machine saved');
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating project machine: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public create(machines: Array<ProjectMachine>): Observable<any> {
    this.logger.debug('Saving {0} project machines list for project {1}', machines.length, this.projectId);

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    const method = 'POST';
    const url = [this.apiUrl, this.projectId, 'projectmachines'].join('/');
    return this.http
      .request<any>(method, url, {
        body: machines
      })
      .pipe(
        tap((_) => {
          this.logger.debug('Project machines saved');
        }),
        catchError((err, caught) => {
          this.logger.error('Error saving project machines: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public delete(machine: ProjectMachine | string): Observable<void> {
    const machineId = typeof machine === 'object' ? machine.id : machine;
    this.logger.debug('Deleting project machine with id', machineId);

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    const url = [this.apiUrl, this.projectId, 'projectmachines', machineId].join('/');

    return this.http.delete<void>(url).pipe(
      tap((_) => {
        this.logger.debug('Project machine deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting project machine: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
