import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MachineDocument } from '../model/machine-document';
import { MachinesService } from '../../machines/shared/machines.service';

@Injectable()
export class MachineDocumentsService {
  private logger: ILogger;
  private machineId: string;

  constructor(private machinesService: MachinesService, loggerService: LoggerService) {
    this.logger = loggerService.getLogger('MachineDocumentService');
  }

  public setMachine(machineId: string): MachineDocumentsService {
    this.machineId = machineId;
    return this;
  }

  public getMachineDocuments(): Observable<Array<MachineDocument>> {
    if (!this.machineId) {
      throw Error('Machine Id not set. Use setMachine() first');
    }

    this.logger.debug('Getting documents for machine with id {0}', this.machineId);

    return this.machinesService.getMachine(this.machineId).pipe(
      first(),
      map((machine) => {
        return (machine.documents && JSON.parse(JSON.stringify(machine.documents))) || []; //create copy because Machine instance is shared
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine documents: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public getMachineDocument(documentId: string): Observable<MachineDocument> {
    if (!this.machineId) {
      throw Error('Machine Id not set. Use setMachine() first');
    }

    this.logger.debug('Getting document with id {0} from machine with id {1}', documentId, this.machineId);

    return this.machinesService.getMachine(this.machineId).pipe(
      first(),
      map((machine) => {
        return machine.documents && JSON.parse(JSON.stringify(machine.documents.find((item) => item.id === documentId))); //create copy because Machine instance is shared
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine document: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
