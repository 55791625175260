import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { Company } from '../model/company';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class InternalCompaniesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/companies';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalCompaniesService');
  }

  public save(company: Company): Observable<Company> {
    this.logger.debug('Saving company with id', company.id);

    const method = company.id != null ? 'PUT' : 'POST';
    const url = this.apiUrl + (company.id != null ? '/' + company.id : '');

    return this.http
      .request<Company>(method, url, {
        body: company
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Company saved');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating company: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public delete(company: Company): Observable<void> {
    this.logger.debug('Deleting company with id', company.id);

    return this.http.delete<void>(this.apiUrl + '/' + company.id).pipe(
      tap((result) => {
        this.logger.debug('Company deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting company: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
