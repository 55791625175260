import { Directive, TemplateRef, ViewContainerRef, OnInit, Input } from '@angular/core';
import { AuthService } from '../shared/auth.service';

@Directive({
  selector: '[isNotAdministrator]'
})
export class IsNotAdministratorDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {

  }

  public ngOnInit(): void {

    if (this.authService.isAdministrator() !== true) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}