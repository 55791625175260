import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edit-modal-content',
  templateUrl: './edit-modal-content.component.html',
  styleUrls: ['./edit-modal-content.component.scss']
})
export class EditModalContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
