<form [formGroup]="selectForm" (ngSubmit)="onSubmit()" novalidate>
  <li class="nav-item">
    <label for="laboratoryId">{{ 'laboratories.generic.laboratory' | translate }}</label>

    <div class="input-padding">
      <ng-select *ngIf="queryResultLaboratory" (change)="onLabChange($event)" [items]="queryResultLaboratory.data" bindLabel="name" bindValue="id"
        formControlName="laboratoryId" hideSelected="true" [searchable]="false" [loading]="savingLab">
        <ng-template ng-loadingspinner-tmp>
          <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <span *ngIf="item.name">
            {{ item.name | truncate : 30 }}
          </span>
        </ng-template>
      </ng-select>
    </div>
  </li>

  <li  class="nav-item input-padding">
    <label>{{ 'menu.user-profile.phone-number' | translate }}</label>
    <div style="position: relative">
      <input
        type="text"
        class="form-control"
        disabled="true"
        id="phoneNumber"
        formControlName="phoneNumber"
        (change)="onPhoneNumberChange($event)"
      />

      <div *ngIf="savingPhone" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%)">
        <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
      </div>
    </div>
  </li>

  <ng-template #loadingSpinner>
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </ng-template>

  <li class="nav-item input-padding">
    <div class="d-flex justify-content-between">
      <label class="d-flex">{{ 'menu.user-profile.signature.upload' | translate }}</label>
      <div class="d-flex">
        <ng-template #tooltipContentLanguage>
          <div class="tooltip-content">
            <label>{{ 'menu.user-profile.signature.upload' | translate }}</label>
            <p>
            {{ 'menu.user-profile.signature.tooltip' | translate }}
            </p>
          </div>
        </ng-template>
        <button
          type="button"
          class="btn btn-link btn-tooltip"
          [ngbTooltip]="tooltipContentLanguage"
          placement="top-right"
          triggers="click"
          tabindex="-1"
        >
          <i class="material-icons blue">help</i>
        </button>
      </div>
    </div>

    <button-spinner name="upload">
      <label class="btn btn-primary btn-action btn-upload">
        <input type="file" multiple="true" ngFileSelect [allowedMimeTypes]="allowedMimeTypes" [accept]="allowedMimeTypes.join(',')" (filesSelected)="filesSelected($event)" />
        {{ 'project-machine-photos.list.button.upload' | translate }}
      </label>
    </button-spinner>

    <div *ngIf="displayStringFileName" class="d-flex justify-content-between mt-2">
      <div  class="d-flex">
        <div>
          <span class="blue"> <span class="material-icons larger">draw</span>{{ selectForm.get('fileName').value }}</span>
        </div>
      </div>
      <div class="d-flex">
        <div>
          <button type="button" (click)="download()" class="btn btn-link btn-tooltip">
            <span class="material-icons larger">download</span>
          </button>
        </div>

        <div>
          <button type="button" (click)="delete()" class="btn btn-link btn-tooltip">
            <span class="material-icons larger">delete_outline</span>
          </button>
        </div>
      </div>
    </div>
  </li>
</form>
