import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { LoggerService, ILogger } from './logger.service';

@Injectable()
export class StartupService {

  private logger: ILogger;
  private startupSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private finishedServices: { [key: string]: boolean } = {
    auth: false,
    translation: false
  };

  constructor(
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('StartupService');
  }

  public isAppStarted(): Observable<boolean> {
    return this.startupSubject;
  }

  public finished(service: string): void {
    if (!this.startupSubject) {
      this.logger.debug('Startup observer is null, skipping.');
      return;
    }

    const finishedServices = this.getFinishedServices();

    if (finishedServices[service] != null) {
      this.logger.debug('Finished {0} service', service);

      finishedServices[service] = true;
      
      const allFinished = Object.keys(finishedServices)
        .every(key => finishedServices[key] === true);

      this.startupSubject.next(allFinished);

      if (allFinished) {
        this.logger.debug('Startup complete');
        this.startupSubject.complete();
      }
    } else {
      this.logger.debug('Service {0} not found', service);
    }
  }

  protected getFinishedServices(): { [key: string]: boolean } {
    return this.finishedServices;
  }
}
