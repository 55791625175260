import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { Machine } from '../model/machine';

@Injectable()
export class InternalMachinesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/machines';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalMachinesService');
  }

  public save(machine: Machine): Observable<Machine> {
    this.logger.debug('Saving machine with id', machine.id);

    const method = machine.id != null ? 'PUT' : 'POST';
    const url = this.apiUrl + (machine.id != null ? '/' + machine.id : '');

    return this.http
      .request<Machine>(method, url, {
        body: machine
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Machine saved');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating machine: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public move(machine: Machine): Observable<boolean> {
    this.logger.debug('Can Move machine with id', machine.id);

    const url = `${this.apiUrl}/${machine.id}/move`;

    return this.http
      .request<boolean>('GET', url, {
        body: machine
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Can move Machine', result);
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error can move machine: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public delete(machine: Machine): Observable<void> {
    this.logger.debug('Deleting machine with id', machine.id);

    return this.http.delete<void>(this.apiUrl + '/' + machine.id).pipe(
      tap((result) => {
        this.logger.debug('Machine deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting machine: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public import(companyId: string, siteId: string, file: File): Observable<Array<Machine>> {
    this.logger.debug('Importing machines for company {0} and site {1}', companyId, siteId);

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('companyId', companyId);
    formData.append('siteId', siteId);

    const method = 'POST';
    const url = [this.apiUrl, 'import'].join('/');

    return this.http
      .request<Array<Machine>>(method, url, {
        body: formData
      })
      .pipe(
        tap((_) => {
          this.logger.debug('Machines imported');
        }),
        catchError((err, caught) => {
          this.logger.error('Error importing machines: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
