<edit-modal size="2x" (onHide)="cancel()">
  <edit-modal-header>
    <div class="row align-items-center table-header">
      <div class="col-auto">
        <modal-close-confirmation name="cancel-examination">
          <button type="button" class="close btn-close" (click)="cancel()">
            <span class="material-icons icon-2x">close</span>
          </button>
        </modal-close-confirmation>
      </div>
      <div class="col text-right">
        <div class="list-inline">
          <div class="list-inline-item">
            <button type="button" class="btn btn-primary btn-action" (click)="save()">
              {{ 'assessment-types.edit.examination.button.save' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </edit-modal-header>

  <edit-modal-content>
    <div class="card content-card">
      <div class="card-header">
        <div class="content-title">{{ 'project-machine-assessment.view.measurements.min-max-tests' | translate }}</div>
      </div>
      <div class="card-body">
        <form [formGroup]="form" novalidate *ngIf="true">
          <div
            class="form-group with-error"
            [formGroup]="measurementTest"
            *ngFor="let measurementTest of measurementTestControls; let i = index"
          >
            <label>{{ measurementTest.value.label }}</label>
            <div class="row">
              <div class="col">
                <label for="minValue-{{ i }}">{{ 'assessment-types.edit.examination.min-value' | translate }}</label>
                <input
                  [readonly]="
                    (measurementTest.value.minValue || measurementTest.value.maxValue) &&
                    !measurementTest.value.definedByUser &&
                    !measurementTest.dirty
                  "
                  type="text"
                  class="form-control form-control-readonly"
                  id="minValue-{{ i }}"
                  formControlName="minValue"
                />
                <div class="alert alert-error">
                  <span *ngIf="!isMinMaxValid(i, 'minValue')">{{
                    'assessment-types.edit.examination.validation.min-max-value.invalid' | translate
                  }}</span>
                </div>
              </div>
              <div class="col">
                <label for="maxValue-{{ i }}">{{ 'assessment-types.edit.examination.max-value' | translate }}</label>
                <input
                  [readonly]="
                    (measurementTest.value.minValue || measurementTest.value.maxValue) &&
                    !measurementTest.value.definedByUser &&
                    !measurementTest.dirty
                  "
                  type="text"
                  class="form-control form-control-readonly"
                  id="maxValue-{{ i }}"
                  formControlName="maxValue"
                />
                <div class="alert alert-error">
                  <span *ngIf="!isMinMaxValid(i, 'maxValue')">{{
                    'assessment-types.edit.examination.validation.min-max-value.invalid' | translate
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </edit-modal-content>
</edit-modal>
