import { Notification } from '../model/Notification';
import { NotificationType } from '../model/NotificationType';
import { Subject } from 'rxjs';
import { AuthService } from '../../../auth/shared/auth.service';
import moment from 'moment';
import { environment } from '../../../../environments/environment';

export class NotificationsChannel {

	private readonly autoDismissTime: number = 5000;

	private notifications: Array<Notification> = [];

	constructor(
		private displayedNotifications: Subject<Notification>,
		private dismissedNotifications: Subject<Notification>,
		private authService: AuthService
	) { }

	public success(message: string): Notification;
	public success(message: string, autoDismiss: boolean): Notification;
	public success(message: string, autoDismiss?: boolean, context?: string): Notification {
		const notification = {
			type: NotificationType.SUCCESS,
			text: message,
			autoDismissTime: autoDismiss ? this.autoDismissTime : 0,
			dismissable: true,
			context: context,
			dismissed: null,
			dismiss: () => this.remove(notification)
		};

		return this.create(notification);
	}

	public info(message: string): Notification;
	public info(message: string, autoDismiss: boolean): Notification;
	public info(message: string, autoDismiss?: boolean, context?: string): Notification {
		const notification = {
			type: NotificationType.INFO,
			text: message,
			autoDismissTime: autoDismiss ? this.autoDismissTime : 0,
			dismissable: true,
			context: context,
			dismissed: null,
			dismiss: () => this.remove(notification)
		};

		return this.create(notification);
	}

	public warning(message: string): Notification;
	public warning(message: string, autoDismiss: boolean): Notification;
	public warning(message: string, autoDismiss?: boolean, context?: string): Notification {
		const notification = {
			type: NotificationType.WARNING,
			text: message,
			autoDismissTime: autoDismiss ? this.autoDismissTime : 0,
			dismissable: true,
			context: context,
			dismissed: null,
			dismiss: () => this.remove(notification)
		};

		return this.create(notification);
	}

	public danger(message: string): Notification;
	public danger(message: string, details: string): Notification;
	public danger(message: string, details: string, autoDismiss: boolean): Notification;
	public danger(message: string, details: string, autoDismiss: boolean, context: string): Notification;
	public danger(message: string, details?: string, autoDismiss?: boolean, context?: string): Notification {
		const notification = {
			type: NotificationType.DANGER,
			text: message,
			autoDismissTime: autoDismiss ? this.autoDismissTime : 0,
			dismissable: true,
			context: context,
			dismissed: null,
			details: this.enrichDetails(message, details),
			dismiss: () => this.remove(notification)
		};

		return this.create(notification);
	}

	public dismiss(): void;
	public dismiss(type: NotificationType): void;
	public dismiss(type?: NotificationType): void {
		this.notifications.filter(item => type && item.type === type || true).forEach(item => {
			item.dismiss();
		});
	}

	public dismissSuccess(): void {
		this.dismiss(NotificationType.SUCCESS);
	}

	public dismissInfo(): void {
		this.dismiss(NotificationType.INFO);
	}

	public dismissWarning(): void {
		this.dismiss(NotificationType.WARNING);
	}

	public dismissDanger(): void {
		this.dismiss(NotificationType.DANGER);
	}

	private remove(notification: Notification): void {
		const index = this.notifications.indexOf(notification);
		index && this.notifications.splice(index, 1);

		this.dismissedNotifications.next(notification);
	}

	private create(notification: Notification): Notification {
		this.notifications.push(notification);
		this.displayedNotifications.next(notification);
		return notification;
	}

	private enrichDetails(message: string, details: string): string {
		const timestamp = moment().format();
		const location = window.location.href;
		const env = window.navigator.userAgent;
		const user = this.authService.user.id;
		const version = environment.version;

		return `Timestamp: ${timestamp}\nMessage: ${message}\n\nDetails: ${JSON.stringify(details)}\n\nVersion: ${version}\nUser: ${user}\nEnvironment: ${env}\nLocation: ${location}`;
	}
}