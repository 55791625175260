import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromDate'
})
export class FromDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const date = moment(value);

    if (!date.isValid()) {
      return value; 
    }
    
    const now = moment();

    if (date.diff(now, 'days') < 1) {
      return date.fromNow();
    } else {
      return date.calendar();
    }
  }
}
