import { Injectable } from '@angular/core';

import { Pointer } from '../model/pointer';
import { IdName } from '../model/id-name';

@Injectable()
export class ModelService {

  constructor() { }

  public toCompanyPointer(obj: any): Pointer {
    return this.toPointer(obj, 'Company');
  }

  public toSitePointer(obj: any): Pointer {
    return this.toPointer(obj, 'Site');
  }

  public toMachinePointer(obj: any): Pointer {
    return this.toPointer(obj, 'ProjectMachine');
  }

  public toMachineTypePointer(obj: any): Pointer {
    return this.toPointer(obj, 'MachineType');
  }

  public toOrganizationPointer(obj: any): Pointer {
    return this.toPointer(obj, 'Organization');
  }

  public toProjectPointer(obj: any): Pointer {
    return this.toPointer(obj, 'Project');
  }

  public toStandardTypePointer(obj: any): Pointer {
    return this.toPointer(obj, 'StandardType');
  }

  public toHazardGroupPointer(obj: any): Pointer {
    return this.toPointer(obj, 'HazardGroup');
  }

  public toStandardPointer(obj: any): Pointer {
    return this.toPointer(obj, 'Standard');
  }

  public toHazardPointer(obj: any): Pointer {
    if (obj.description) {
      obj.name = obj.description;
    } else {
      throw Error('ModelService.toPointer(): Description not defined');
    }

    return this.toPointer(obj, 'Hazard');
  }

  public toPointer(obj: any, reference: string): Pointer {
    if (!obj) {
      return null;
    }

    const result = new Pointer();
    result.reference = reference;

    if (obj.id) {
      result.id = obj.id;
    } else {
      throw Error('ModelService.toPointer(): Id not defined');
    }

    if (obj.name) {
      result.name = obj.name;
    } else {
      throw Error('ModelService.toPointer(): Name not defined');
    }

    return result;
  }

  public toExaminationIdName(obj: any): IdName {
    if (obj.examinationNumber) {
      obj.name = obj.examinationNumber;
    } else {
      throw Error('ModelService.toIdName(): Examination number not defined');
    }

    return this.toIdName(obj);
  }

  public toIdName(obj: any): IdName {
    if (!obj) {
      return null;
    }

    const result = new IdName();

    if (obj.id) {
      result.id = obj.id;
    } else {
      throw Error('ModelService.toIdName(): Id not defined');
    }

    if (obj.name) {
      result.name = obj.name;
    } else {
      throw Error('ModelService.toIdName(): Name not defined');
    }

    return result;
  }
}
