import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { Notification } from '../model/Notification';
import { NotificationType } from '../model/NotificationType';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() public notification: Notification;
  @Output() public dismissed = new EventEmitter();

  public isShowDetails: boolean = false;

  constructor(private sanitizer: DomSanitizer) {}

  public ngOnInit(): void {
    this.initTimerIfNeeded();
  }

  public isSuccess(): boolean {
    return this.notification.type === NotificationType.SUCCESS;
  }

  public isInfo(): boolean {
    return this.notification.type === NotificationType.INFO;
  }

  public isWarning(): boolean {
    return this.notification.type === NotificationType.WARNING;
  }

  public isDanger(): boolean {
    return this.notification.type === NotificationType.DANGER;
  }

  public hasDetails(): boolean {
    return this.notification.details != null;
  }

  public dismiss(): void {
    this.dismissed.emit();
  }

  public dismissPositive(): void {
    if (this.isSuccess() || this.isInfo()) {
      this.dismiss();
    }
  }

  public isDismissEnabled(): boolean {
    return this.notification.autoDismissTime === 0 || this.notification.dismissable;
  }

  getSafeHTML(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  private isAutoDismissing(): boolean {
    return this.notification.autoDismissTime > 0;
  }

  private initTimerIfNeeded() {
    if (this.isAutoDismissing()) {
      setTimeout(() => this.dismiss(), this.notification.autoDismissTime);
    }
  }
}
