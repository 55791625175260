import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { ControlIntegrityRating } from '../../machine-services/model/control-integrity-rating';


@Injectable()
export class InternalMachineControlIntegritiesService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('InternalMachineControlIntegritiesService');
  }

  public setProject(projectId: string): InternalMachineControlIntegritiesService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalMachineControlIntegritiesService {
    this.machineId = machineId;
    return this;
  }

  public save(controlIntegrity: ControlIntegrityRating): Observable<ControlIntegrityRating> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Saving control integrity with id {0} in machine {1} from project {2}', controlIntegrity.id, this.machineId, this.projectId);

    const method = controlIntegrity.id != null ? 'PUT' : 'POST';

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'controlintegrities',
      (controlIntegrity.id != null ? controlIntegrity.id : '')
    ].join('/');

    return this.http.request<ControlIntegrityRating>(method, url, {
      body: controlIntegrity
    }).pipe(
      tap(result => {
        this.logger.debug('Control integrity saved');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving control integrity: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public delete(controlIntegrity: ControlIntegrityRating): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }
    
    this.logger.debug('Deleting control integrity with id {0} in machine {1} from project {2}', controlIntegrity.id, this.machineId, this.projectId);

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'controlintegrities',
      controlIntegrity.id
    ].join('/');

    return this.http.delete<void>(url)
      .pipe(
        tap(result => {
          this.logger.debug('Control integrity deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting control integrity: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
