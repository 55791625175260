import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { ProjectMachine } from '../model/project-machine';
import { MachineAssessmentSection } from '../model/machine-assessment-section';
import { MachineAssessmentChecklist } from '../model/machine-assessment-checklist';
import { InternalManageMachinesService } from '../internal/internal-manage-machines.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ManageMachinesService {
  private logger: ILogger;
  private projectId: string;

  constructor(
    private internalManageMachinesService: InternalManageMachinesService,
    private translateService: TranslateService,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('ManageMachinesService');
  }

  public setProject(projectId: string): ManageMachinesService {
    this.projectId = projectId;
    this.internalManageMachinesService.setProject(projectId);
    return this;
  }

  public complyAll(machine: ProjectMachine, checklist: MachineAssessmentChecklist): Observable<ProjectMachine>;
  public complyAll(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section: MachineAssessmentSection
  ): Observable<ProjectMachine>;
  public complyAll(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section?: MachineAssessmentSection
  ): Observable<ProjectMachine> {
    this.logger.debug(
      'Setting comply to all examinations in checklist {0}, section {1} of machine {2} in project {3}',
      checklist.id,
      section && section.id,
      machine.id,
      this.projectId
    );

    return this.internalManageMachinesService.update(machine, checklist, section, (internalChecklist, internalSection) => {
      let examinations = [];

      if (internalSection) {
        examinations = internalSection.examinations;
      } else {
        internalChecklist.sections &&
          internalChecklist.sections.forEach((item) => item.examinations && examinations.push(...item.examinations));
      }

      examinations.forEach((item) => {
        item.answer = item.defaultAnswer;
        item.state = 'comply';
      });

      return examinations.map((item) => item.id);
    });
  }

  public notApplicableAll(machine: ProjectMachine, checklist: MachineAssessmentChecklist): Observable<ProjectMachine>;
  public notApplicableAll(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section: MachineAssessmentSection
  ): Observable<ProjectMachine>;
  public notApplicableAll(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section?: MachineAssessmentSection
  ): Observable<ProjectMachine> {
    this.logger.debug(
      'Setting not applicable to all examinations in checklist {0}, section {1} of machine {2} in project {3}',
      checklist.id,
      section && section.id,
      machine.id,
      this.projectId
    );

    return this.internalManageMachinesService.update(machine, checklist, section, (internalChecklist, internalSection) => {
      let examinations = [];

      if (internalSection) {
        examinations = internalSection.examinations;
      } else {
        internalChecklist.sections &&
          internalChecklist.sections.forEach((item) => item.examinations && examinations.push(...item.examinations));
      }

      examinations.forEach((item) => {
        item.answer = this.translateService.instant('project-machine-assessment.list.menu.answers.not-applicable');
        item.state = 'not_applicable';
      });

      return examinations.map((item) => item.id);
    });
  }

  public notRelevantAll(machine: ProjectMachine, checklist: MachineAssessmentChecklist): Observable<ProjectMachine>;
  public notRelevantAll(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section: MachineAssessmentSection
  ): Observable<ProjectMachine>;
  public notRelevantAll(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section?: MachineAssessmentSection
  ): Observable<ProjectMachine> {
    this.logger.debug(
      'Setting not relevant to all examinations in checklist {0}, section {1} of machine {2} in project {3}',
      checklist.id,
      section && section.id,
      machine.id,
      this.projectId
    );

    return this.internalManageMachinesService.update(machine, checklist, section, (internalChecklist, internalSection) => {
      let examinations = [];

      if (internalSection) {
        examinations = internalSection.examinations;
      } else {
        internalChecklist.sections &&
          internalChecklist.sections.forEach((item) => item.examinations && examinations.push(...item.examinations));
      }

      examinations.forEach((item) => {
        item.answer = this.translateService.instant('project-machine-assessment.list.menu.answers.not-relevant');
        item.state = 'not_relevant';
      });

      return examinations.map((item) => item.id);
    });
  }

  public complyUnanswered(machine: ProjectMachine, checklist: MachineAssessmentChecklist): Observable<ProjectMachine>;
  public complyUnanswered(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section: MachineAssessmentSection
  ): Observable<ProjectMachine>;
  public complyUnanswered(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section?: MachineAssessmentSection
  ): Observable<ProjectMachine> {
    this.logger.debug(
      'Setting comply to unanswered examinations in checklist {0}, section {1} of machine {2} in project {3}',
      checklist.id,
      section && section.id,
      machine.id,
      this.projectId
    );

    return this.internalManageMachinesService.update(machine, checklist, section, (internalChecklist, internalSection) => {
      let examinations = [];

      if (internalSection) {
        examinations = internalSection.examinations;
      } else {
        internalChecklist.sections &&
          internalChecklist.sections.forEach((item) => item.examinations && examinations.push(...item.examinations));
      }

      const updatedIds = examinations.filter((item) => !item.state).map((item) => item.id);

      examinations
        .filter((item) => !item.state)
        .forEach((item) => {
          item.answer = item.defaultAnswer;
          item.state = 'comply';
        });

      return updatedIds;
    });
  }

  public notApplicableUnanswered(machine: ProjectMachine, checklist: MachineAssessmentChecklist): Observable<ProjectMachine>;
  public notApplicableUnanswered(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section: MachineAssessmentSection
  ): Observable<ProjectMachine>;
  public notApplicableUnanswered(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section?: MachineAssessmentSection
  ): Observable<ProjectMachine> {
    this.logger.debug(
      'Setting not applicable to unanswered examinations in checklist {0}, section {1} of machine {2} in project {3}',
      checklist.id,
      section && section.id,
      machine.id,
      this.projectId
    );

    return this.internalManageMachinesService.update(machine, checklist, section, (internalChecklist, internalSection) => {
      let examinations = [];

      if (internalSection) {
        examinations = internalSection.examinations;
      } else {
        internalChecklist.sections &&
          internalChecklist.sections.forEach((item) => item.examinations && examinations.push(...item.examinations));
      }

      const updatedIds = examinations.filter((item) => !item.state).map((item) => item.id);

      examinations
        .filter((item) => !item.state)
        .forEach((item) => {
          item.answer = this.translateService.instant('project-machine-assessment.list.menu.answers.not-applicable');
          item.state = 'not_applicable';
        });

      return updatedIds;
    });
  }

  public notRelevantUnanswered(machine: ProjectMachine, checklist: MachineAssessmentChecklist): Observable<ProjectMachine>;
  public notRelevantUnanswered(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section: MachineAssessmentSection
  ): Observable<ProjectMachine>;
  public notRelevantUnanswered(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section?: MachineAssessmentSection
  ): Observable<ProjectMachine> {
    this.logger.debug(
      'Setting not relevant to unanswered examinations in checklist {0}, section {1} of machine {2} in project {3}',
      checklist.id,
      section && section.id,
      machine.id,
      this.projectId
    );

    return this.internalManageMachinesService.update(machine, checklist, section, (internalChecklist, internalSection) => {
      let examinations = [];

      if (internalSection) {
        examinations = internalSection.examinations;
      } else {
        internalChecklist.sections &&
          internalChecklist.sections.forEach((item) => item.examinations && examinations.push(...item.examinations));
      }

      const updatedIds = examinations.filter((item) => !item.state).map((item) => item.id);

      examinations
        .filter((item) => !item.state)
        .forEach((item) => {
          item.answer = this.translateService.instant('project-machine-assessment.list.menu.answers.not-relevant');
          item.state = 'not_relevant';
        });

      return updatedIds;
    });
  }

  public resetAll(machine: ProjectMachine, checklist: MachineAssessmentChecklist): Observable<ProjectMachine>;
  public resetAll(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section: MachineAssessmentSection
  ): Observable<ProjectMachine>;
  public resetAll(
    machine: ProjectMachine,
    checklist: MachineAssessmentChecklist,
    section?: MachineAssessmentSection
  ): Observable<ProjectMachine> {
    this.logger.debug(
      'Reseting all examinations in checklist {0}, section {1} of machine {2} in project {3}',
      checklist.id,
      section && section.id,
      machine.id,
      this.projectId
    );

    return this.internalManageMachinesService.update(
      machine,
      checklist,
      section,
      (internalChecklist, internalSection) => {
        let examinations = [];

        if (internalSection) {
          examinations = internalSection.examinations;
        } else {
          if (internalChecklist.sections) {
            internalChecklist.sections.forEach((item) => item.examinations && examinations.push(...item.examinations));
          }
        }

        examinations.forEach((item) => {
          item.answer = null;
          item.guidance = item.defaultGuidance;
          item.state = undefined;
        });

        return examinations.map((item) => item.id);
      },
      true
    );
  }
}
