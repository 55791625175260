
import {throwError as observableThrowError,  Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';
import { Standard } from '../model/standard';
import { tap, catchError} from 'rxjs/operators';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';


@Injectable()
export class StandardsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/standards';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('StandardsService');
  }

  public getStandards(filter?: QueryFilter): Observable<QueryResult<Standard>> {
    this.logger.debug('Getting standards');

    let url = this.apiUrl;
    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<Standard>>(url)
      .pipe(
        tap(result => {
          if (!result || !result.data || result.data.length === 0) {
            this.logger.debug('No standards found');
            return result;
          }

          this.logger.debug('{0} standards found', result.data.length);

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting standards: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public getStandard(id: string): Observable<Standard> {
    this.logger.debug('Getting standard with id', id);

    if (!id) {
      this.logger.error('Standard id is null, returning null');
      return of(null);
    }

    return this.http.get<Standard>(this.apiUrl + '/' + id)
      .pipe(
        tap(result => {
          if (!result) {
            this.logger.debug('No standard found');
            return result;
          }

          this.logger.debug('Standard found');

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting standard: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public getStandardsPrefixes(): Observable<Array<string>> {
    this.logger.debug('Getting prefixes of standards');

    return this.http.get<Array<string>>(this.apiUrl + '/prefixes')
      .pipe(
        tap(results => {
          if (!results || results.length === 0) {
            this.logger.debug('No standard prefixes found');
            return results;
          }

          this.logger.debug('{0} standard prefixes found', results.length);

          return results;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting standard prefixes: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
