import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { GalleryPhotoDto } from '../model/gallery-photo-dto';

@Injectable()
export class ManageMachinePhotosService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('ManageMachinePhotosService');
  }

  public setProject(projectId: string): ManageMachinePhotosService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(projectMachineId: string): ManageMachinePhotosService {
    this.machineId = projectMachineId;
    return this;
  }

  public copyTo(otherProjectId: string, otherProjectMachineId: string): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug(
      'Copying photo from machine {0} from project {1} to project {2} and machine {3}',
      this.machineId,
      this.projectId,
      otherProjectId,
      otherProjectMachineId
    );

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'photos',
      'copyto',
      otherProjectId,
      otherProjectMachineId
    ].join('/');

    return this.http.post<void>(url, 'empty body').pipe(
      tap((_) => {
        this.logger.debug('Machine photos copied');
      }),
      catchError((err, caught) => {
        this.logger.error('Error copying machine photos: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public delete(photos: Array<string>): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Deleting {0} machine photos', photos.length);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'photos'].join('/');

    return this.http
      .request<void>('DELETE', url, {
        body: photos
      })
      .pipe(
        tap(() => {
          this.logger.debug('Machine photos deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting machine photos: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }

  public updateGallery(photos: Array<GalleryPhotoDto>): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Updating {0} machine photos', photos.length);

    //end point: /api/v{version}/projects/{projectId}/ProjectMachines/{id}/Gallery
    let url = [this.apiUrl, this.projectId, 'ProjectMachines', this.machineId, 'Gallery'].join('/');

    return this.http
      .request<void>('PUT', url, {
        body: photos
      })
      .pipe(
        tap(() => {
          this.logger.debug('Gallery photos updated');
        }),
        catchError((err, caught) => {
          console.log(caught);
          this.logger.error('Error updating Gallery photos: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }
}
