
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { HttpClient } from '@angular/common/http';
import { StandardType } from '../model/standard-type';
import { tap, catchError} from 'rxjs/operators';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';

@Injectable()
export class StandardTypesService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/standardtypes';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService) {
      this.logger = logger.getLogger('StandardTypesService');
    }
  public getStandardTypes(): Observable<QueryResult<StandardType>> {
    this.logger.debug('Getting standardTypes');

    const filter = new QueryFilter().ascending('name').take(1000);
    const url = this.apiUrl + '?' + filter.toQueryString();

    return this.http.get<QueryResult<StandardType>>(url)
      .pipe(
        tap(result => {
          if (!result || !result.data || result.data.length === 0) {
            this.logger.debug('No standardTypes found');
            return result;
          }

          this.logger.debug('{0} standardTypes found', result.data.length);
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting standardTypes: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

}
