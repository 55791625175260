import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { User } from '../model/User';

@Injectable()
export class UsersService {
  private readonly apiUrl: string = environment.umServerUrl + '/v1/users';

  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('UsersService');
  }

  public getUsers(filter?: QueryFilter): Observable<QueryResult<User>> {
    this.logger.debug('Getting users');

    let url = this.apiUrl;

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<User>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No users found');
          return result;
        }

        this.logger.debug('{0} users found', result.data.length);

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting users: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getUser(id: string): Observable<User> {
    this.logger.debug('Getting user with id', id);

    if (!id) {
      this.logger.error('User id is null, returning null');
      return of(null);
    }

    return this.http.get<User>(this.apiUrl + '/' + id).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No user found');
          return result;
        }

        this.logger.debug('User found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting user: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
