import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../../shared/logger.service';
import { MessageBrokerService } from '../../../shared/message-broker.service';
import { Equipment } from './../../../../projects-modules/machine-services/model/equipment';
import { Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MeasurementType } from '../../../../projects-modules/machine-services/model/measurement-type';
import { Site } from '../../../../settings-modules/sites/model/site';
import { ConfirmationService } from '../../../confirmation/shared/confirmation.service';
import { EditModalComponent } from '../../../edit-modal/edit-modal/edit-modal.component';
import { Pointer } from '../../../model/pointer';
import { NotificationsService } from '../../../notifications/shared/notifications.service';
import { Form, FormService } from '../../../shared/form.service';
import { SpinnerService } from '../../../spinner/shared/spinner.service';
import { EquipmentListService } from '../equipment-list.service';
import { MeasurementTypeService } from '../measurement-type.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-equipment-edit',
  templateUrl: './equipment-edit.component.html',
  styleUrls: ['./equipment-edit.component.scss']
})
export class EquipmentEditComponent implements OnInit, OnDestroy {
  @Input() public equipment: Equipment;

  @Input() public editMode: boolean;
  @ViewChild(EditModalComponent, { static: true }) editModal: EditModalComponent;
  @Output() modalHid: EventEmitter<Equipment> = new EventEmitter<Equipment>();
  public form: Form;
  public sites: Array<Site>;
  public todaysDate: Date = new Date();
  public minDateStruct: NgbDateStruct = {
    year: this.todaysDate.getFullYear(),
    month: this.todaysDate.getMonth() + 1,
    day: this.todaysDate.getDate()
  };
  measurementTypes: MeasurementType[];
  pointerMeasurementTypes: Pointer[];
  confirmationDeleteSafeHtml: SafeHtml;
  private logger: ILogger;
  private subscriptions: Subscription[] = [];

  constructor(
    private messageBroker: MessageBrokerService,
    private formService: FormService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService,
    private equipmentListService: EquipmentListService,
    private measurementTypeService: MeasurementTypeService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('EquipmentEditComponent');
  }

  public ngOnInit(): void {
    this.logger.debug('Initializing component');

    this.form = this.formService.form(
      {
        reference: [null, Validators.required],
        testDuration: [null],
        calibration: [null],
        calibrationDue: [null],
        description: [null],
        assetNumber: [null, Validators.required],
        manufacturer: [null],
        model: [null, Validators.required],
        assignedMeasurementTypes: [null, Validators.required],
        isActive: [null]
      },
      {
        reference: {
          required: this.translateService.instant('menu.equipment.validation.required')
        },
        calibrationDue: {
          required: this.translateService.instant('menu.equipment.validation.required')
        },
        assetNumber: {
          required: this.translateService.instant('menu.equipment.validation.required')
        },
        model: {
          required: this.translateService.instant('menu.equipment.validation.required')
        },
        assignedMeasurementTypes: {
          required: this.translateService.instant('menu.equipment.validation.assignedMeasurementTypes.required')
        }
      }
    );

    this.logger.debug('assignedMeasurementTypes', this.equipment.assignedMeasurementTypes);

    this.form.patchValue(this.equipment);

    this.spinnerService.hide('equipment-detail');

    this.measurementTypeService.getMeasurementTypes().subscribe((types) => {
      this.measurementTypes = types.data;
      // convert measurement types to pointers
      this.pointerMeasurementTypes = this.measurementTypes.map((type) => ({
        id: type.id,
        reference: type.name,
        name: type.name
      }));
    });
  }
  public ngOnDestroy(): void {
    this.logger.debug('Destroying component');
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.notificationsService.getChannel('equipment-edit').dismissDanger();
  }

  public cancel(): void {
    this.logger.debug('Cancelling edit');
    if (this.form.dirty) {
      this.logger.debug('Something changed, waiting for cancel confirmation.');

      this.confirmationService
        .show('cancel-equipment')
        .pipe(first())
        .subscribe((result) => {
          this.logger.debug('cancel result', result);
          if (result) {
            this.editModal.hide();
          }
        });
    } else {
      this.logger.debug('cancel, form not dirty');
      this.editModal.hide();
    }
    this.modalHid.emit(this.equipment);
  }

  public save(): void {
    if (this.form.canSubmit()) {
      this.logger.debug('Submiting form');

      this.notificationsService.getChannel('equipment-edit').dismissDanger();

      this.spinnerService.show('save-equipment');

      const equipment = Object.assign({}, this.equipment, this.form.value);

      this.logger.debug('save equipment', equipment);

      const saveEquipment = (): Observable<Equipment> => this.equipmentListService.save(equipment).pipe(first());

      saveEquipment().subscribe(
        (_) => {
          this.editModal
            .hide()
            .pipe(first())
            .subscribe((modalData) => {
              this.logger.debug('Saving equipment', modalData);
              this.spinnerService.hide('save-equipment');

              this.notificationsService
                .getChannel('equipment-edit')
                .success(this.translateService.instant('menu.equipment.notification.save'), true);
              this.messageBroker.to('equipment.save');

              this.modalHid.emit(this.equipment);
            });
        },
        (error) => {
          this.spinnerService.hide('save-equipment');
          this.notificationsService
            .getChannel('equipment-edit')
            .danger(this.translateService.instant('menu.equipment.notification.error-save'), error);
          this.editModal.hide();
        }
      );
    } else {
      this.logger.debug('Cannot submit form');
    }

    this.confirmationDeleteSafeHtml = this.getSafeHTML(
      this.translate.instant('project-overview.project.confirmation.delete', {
        project: 'fake'
      })
    );
  }

  getSafeHTML(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  public delete(): void {
    this.logger.debug('Deleting equipment with id', this.equipment.id, this.equipment.reference);

    this.confirmationService
      .show('delete-equipment')
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.spinnerService.show('delete-equipment');

          this.equipmentListService
            .delete(this.equipment.id)
            .pipe(first())
            .subscribe(
              (_) => {
                this.spinnerService.hide('delete-equipment');
                this.editModal.hide();
                this.modalHid.emit(this.equipment);
                this.notificationsService
                  .getChannel('equipment-edit')
                  .success(this.translateService.instant('menu.equipment.notification.delete'), true);
                this.messageBroker.to('equipment.delete');
              },
              (error) => {
                this.spinnerService.hide('delete-equipment');
                this.notificationsService
                  .getChannel('equipment-edit')
                  .danger(this.translateService.instant('menu.equipment.notification.error-delete'), error);
              }
            );
        }
      });
  }

  isActive() {
    this.logger.debug('is Active');
  }
}
