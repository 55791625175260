import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, switchMap, catchError, finalize, map, filter, first } from 'rxjs/operators';

import { LoggerService, ILogger } from './logger.service';
import { StartupService } from './startup.service';

const defaultLanguage = 'en';

@Injectable()
export class TranslationService {

	public currentLanguage: string;

	private logger: ILogger;

	constructor(
		private startupService: StartupService,
		private ngxTranslationService: TranslateService,
		logger: LoggerService
	) {
		this.logger = logger.getLogger('TranslationService');
	}

	public init(): Observable<boolean> {
		this.logger.debug('Initiliazing TranslationService');

		// this language will be used as a fallback when a translation isn't found in the current language
		this.ngxTranslationService.setDefaultLang(defaultLanguage);
		// the lang to use, if the lang isn't available, it will use the current loader to get them
		const userLanguage = this.getStoredLocale() || this.ngxTranslationService.getBrowserLang();

		// there is a bug with use method
		return this.ngxTranslationService.getTranslation(userLanguage)
			.pipe(
				switchMap(() => {
					this.logger.debug('Using user langugage', userLanguage);
					return this.ngxTranslationService.use(userLanguage);
				}),
				catchError(() => this.ngxTranslationService.getTranslation(defaultLanguage)
					.pipe(switchMap(() => {
						this.logger.debug('Using default language', defaultLanguage);
						return this.ngxTranslationService.use(defaultLanguage);
					}))
				),
				map(_ => true),
				finalize(() => {
					this.startupService.finished('translation');
					this.currentLanguage = this.ngxTranslationService.currentLang;
				})
			);
	}

	public changeLanguage(language: string): Observable<void> {
		this.logger.debug('Changing langugage to', language);

		return this.ngxTranslationService.use(language)
			.pipe(
				first(),
				tap(_ => {
					this.logger.debug('Langugage changed', language);

					this.currentLanguage = this.ngxTranslationService.currentLang;
					this.setStoredLocale(language);
				})
			);
	}

	public clear(): void {
		this.logger.debug('Clearing stored data');
		this.clearStoredLocale();
	}

	private getStoredLocale(): string {
		return localStorage.getItem('mcomplus/locale');
	}

	private setStoredLocale(language: string) {
		localStorage.setItem('mcomplus/locale', language);
	}

	private clearStoredLocale() {
		localStorage.removeItem('mcomplus/locale');
	}
}
