import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthOfflineComponent } from './auth-offline/auth-offline.component';
import { AuthRefreshComponent } from './auth-refresh/auth-refresh.component';
import { AuthReloginComponent } from './auth-relogin/auth-relogin.component';
import { IsAdministratorOrPowerEngineerDirective } from './directives/is-administrator-or-power-engineer.directive';
import { IsAdministratorDirective } from './directives/is-administrator.directive';
import { IsCustomerEngineerDirective } from './directives/is-customer-engineer.directive';
import { IsEngineerDirective } from './directives/is-engineer.directive';
import { IsNotAdministratorDirective } from './directives/is-not-administrator.directive';
import { IsNotRestrictedDirective } from './directives/is-not-restricted.directive';
import { IsPowerEngineerDirective } from './directives/is-power-engineer.directive';
import { IsProviderAdministratorDirective } from './directives/is-provider-administrator.directive';
import { IsProviderEngineerDirective } from './directives/is-provider-engineer.directive';
import { IsProviderDirective } from './directives/is-provider.directive';
import { IsRestrictedDirective } from './directives/is-restricted.directive';
import { NoAuthComponent } from './no-auth/no-auth.component';
import { IsEngineerOrPowerEngineerDirective } from './directives/is-engineer-or-power-engineer.directive';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [
    AuthCallbackComponent,
    NoAuthComponent,
    AuthRefreshComponent,
    IsEngineerDirective,
    IsPowerEngineerDirective,
    IsAdministratorDirective,
    IsAdministratorOrPowerEngineerDirective,
    IsProviderAdministratorDirective,
    IsNotAdministratorDirective,
    AuthReloginComponent,
    IsProviderEngineerDirective,
    IsCustomerEngineerDirective,
    AuthOfflineComponent,
    IsNotRestrictedDirective,
    IsRestrictedDirective,
    IsProviderDirective,
    IsEngineerOrPowerEngineerDirective
  ],
  exports: [
    AuthCallbackComponent,
    AuthRefreshComponent,
    IsEngineerDirective,
    IsPowerEngineerDirective,
    IsAdministratorDirective,
    IsAdministratorOrPowerEngineerDirective,
    IsProviderAdministratorDirective,
    IsNotAdministratorDirective,
    AuthReloginComponent,
    IsProviderEngineerDirective,
    IsCustomerEngineerDirective,
    AuthOfflineComponent,
    IsNotRestrictedDirective,
    IsRestrictedDirective,
    IsProviderDirective,
    IsEngineerOrPowerEngineerDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule {}
