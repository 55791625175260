import { Component, OnInit, HostListener, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoggerService, ILogger } from '../../core/shared/logger.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';
import { environment } from '../../../environments/environment';
import { ElectronService } from 'ngx-electron';

@Component({
  selector: 'auth-relogin',
  templateUrl: './auth-relogin.component.html',
  styleUrls: ['./auth-relogin.component.scss']
})
export class AuthReloginComponent implements OnInit {

  public hidden: boolean = true;
  public src: SafeResourceUrl;
  public loaded: boolean = false;
  public reloginHidden: boolean = true;

  private logger: ILogger;
  private reloginObserver: Subject<string>;

  constructor(
    private electron: ElectronService,
    private zone: NgZone,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    logger: LoggerService
  ) { 
    this.logger = logger.getLogger('AuthReloginComponent');
  }

  public ngOnInit(): void {
    this.logger.debug('Initializing component');
    this.authService.registerAuthReloginComponent(this);
  }

  @HostListener('window:reloginFinished', ['$event'])
  public reloginFinished(event: any): void {
    
    this.reloginHidden = true;
    this.src = '';

    if (event.detail && event.detail.hash) {
      const hash = event.detail.hash;

      this.logger.debug('Relogin finished, forwarding hash');

      this.reloginObserver.next(hash);
    } else {
      if (!event.detail || !event.detail.hash) {
        this.logger.debug('Relogin finished but no hash found, skipping.');
        this.reloginObserver.error('Relogin finished but no hash found');
      } else {
        this.logger.debug('Relogin finished but unexpected error occured, skipping.');
        this.reloginObserver.error('Relogin finished but unexpected error occured');
      }
    }

    //wait for animation
    setTimeout(_ => {
      this.hidden = true;
    }, 300);
  }

  public relogin(authUrl: string): Observable<string> {
    this.logger.debug('Reloging');

    this.hidden = false;
    this.loaded = false;
    this.reloginObserver = new Subject<string>();

    if (this.isDesktop()) {
      this.electron.ipcRenderer.once('hash-received', (event, hash) => this.zone.run(_ => {
        this.logger.debug('Hash received');

        this.reloginFinished({ detail: { hash: hash }});
      }));
    }

    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(authUrl);

    setTimeout(_ => {
      this.reloginHidden = false;
    }, 1);

    return this.reloginObserver;
  }

  private isDesktop(): boolean {
    return environment.uwp === true && this.electron.isElectronApp;
  }
}
