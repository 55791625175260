import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

import { Company } from '../model/company';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';
import { IdName } from '../../../core/model/id-name';

@Injectable()
export class InternalCompanyLogosService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/companies';
  private logger: ILogger;
  private companyId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalCompanyLogosService');
  }

  public setCompany(companyId: string): InternalCompanyLogosService {
    this.companyId = companyId;
    return this;
  }

  public save(file: File): Observable<string> {
    this.logger.debug('Saving logo of company with id', this.companyId);

    if (!this.companyId) {
      throw Error('Company Id not set. Use setCompany() first');
    }

    const method = 'POST';
    const url = [this.apiUrl, this.companyId, 'logo'].join('/');

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .request<IdName>(method, url, {
        body: formData
      })
      .pipe(
        map((result) => {
          this.logger.debug('Logo saved');

          return result.id;
        }),
        catchError((err, caught) => {
          this.logger.error('Error saving logo: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public delete(id: string): Observable<void> {
    this.logger.debug('Deleting logo {0} of company {1}', id);

    if (!this.companyId) {
      throw Error('Company Id not set. Use setCompany() first');
    }

    return this.http.delete<void>([this.apiUrl, this.companyId, 'logo', id].join('/')).pipe(
      tap((result) => {
        this.logger.debug('Company logo deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting company logo: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
