import { Input, AfterViewInit, ElementRef, HostListener, Directive, SimpleChanges, OnChanges, forwardRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: 'textarea[autosize]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutosizeDirective),
    multi: true
  }]
})
export class AutosizeDirective implements AfterViewInit, ControlValueAccessor {
  
  private element: HTMLElement;
  private minimumHeight: string;
  private maximumHeight: string;
  private clientWidth: number;
  private onChange: (_: any) => void;
  private value: string;

  @Input('minHeight')
  get minHeight(): string {
    return this.minimumHeight;
  }
  set minHeight(val: string) {
    this.minimumHeight = val;
    this.updateMinHeight();
  }

  @Input('maxHeight')
  get maxHeight(): string {
    return this.maximumHeight;
  }
  set maxHeight(val: string) {
    this.maximumHeight = val;
    this.updateMaxHeight();
  }

  @HostListener('window:resize', ['$event.target'])
  onResize(): void {
    // Only apply adjustment if element width had changed.
    if (this.element.clientWidth === this.clientWidth) {
      return
    };
    this.clientWidth = this.elementRef.nativeElement.clientWidth;
    this.adjust();
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.onChange(value);
    this.adjust();
  }

  @HostListener('blur', []) 
  onTouched = () => { };

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.element = elementRef.nativeElement;
    this.clientWidth = this.element.clientWidth;
  }

  ngAfterViewInit(): void {
    // set element resize allowed manually by user
    const style = window.getComputedStyle(this.element, null);
    if (style.resize === 'both') {
      this.element.style.resize = 'horizontal';
    } else if (style.resize === 'vertical') {
      this.element.style.resize = 'none';
    }

    // run first adjust
    this.adjust();
  }

  adjust(): void {
    // perform height adjustments after input changes, if height is different
    if (this.element.style.height == this.elementRef.nativeElement.scrollHeight + 'px') {
      return;
    }

    // this.element.style.overflow = 'hidden';
    this.element.style.height = 'auto';
    this.element.style.height = 2 + this.element.scrollHeight + 'px'; //add 2 * 1px for border width
  }

  updateMinHeight(): void {
    // Set textarea min height if input defined
    this.element.style.minHeight = this.minimumHeight;
  }

  updateMaxHeight(): void {
    // Set textarea max height if input defined
    this.element.style.maxHeight = this.maximumHeight;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    } else {
      this.value = '';
    }

    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      this.value
    );

    setTimeout(_ => {
      this.adjust();
    }, 1);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}