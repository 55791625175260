import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, finalize, first, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { AuthService } from '../shared/auth.service';
import { TokenService } from './token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private logger: ILogger;
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private online$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private tokenService: TokenService, private authService: AuthService, logger: LoggerService) {
    this.logger = logger.getLogger('AuthInterceptor');
    fromEvent(window, 'offline').subscribe(() => this.broadcastConnectivity(false));
    fromEvent(window, 'online').subscribe(() => this.broadcastConnectivity(true));
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.logger.debug('Processing request');

    if (!request.url || (!request.url.startsWith(environment.serverUrl) && !request.url.startsWith(environment.umServerUrl))) {
      this.logger.debug('Request is not for own server, skipping processing');

      return next.handle(request);
    }

    if (!this.online$.value) {
      return observableThrowError('Client is offline');
    }

    const token: string = this.tokenService.authInfo.accessToken;

    return next.handle(this.addToken(request, token)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handleUnauthorized(request, next);
        } else if (error instanceof HttpErrorResponse && error.status === 400) {
          const message = error.error;
          this.logger.error('Application error received.', message);
          return observableThrowError(message);
        } else {
          this.logger.error('Error received.', error);
          return observableThrowError(error);
        }
      })
    );
  }

  /**
   *   this logic can be moved to a dedicated "onlineService"
   *   in case online/offline status should be injected from outside
   */
  private broadcastConnectivity(b: boolean): void {
    this.online$.next(b);
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const headers = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: 'Bearer ' + token
    };

    //add timezone info for server
    if (Intl) {
      headers['X-TimeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (this.authService?.user?.organization) {
      headers['OrganizationId'] = this.authService.user.organization;
    }

    return request.clone({ setHeaders: headers });
  }

  private handleUnauthorized(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.logger.debug('Handling 401 Unathorized');

    if (!this.isRefreshingToken) {
      this.logger.debug('Refreshing token not in progress');

      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(this.addToken(request, newToken));
          }

          // If we don't get a new token, we are in trouble so bad news.
          this.logger.error('Token not received');
          return observableThrowError('No access token obtained');
        }),
        catchError((error) => {
          // If there is an exception calling 'refreshToken', bad news.
          this.logger.error('Error refreshing token', error);
          return observableThrowError('');
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      this.logger.debug('Refreshing token in progress. Waiting for token.');

      return this.tokenSubject.pipe(
        filter((token) => token != null),
        first(),
        switchMap((token) => next.handle(this.addToken(request, token)))
      );
    }
  }
}
