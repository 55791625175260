import { Injectable } from '@angular/core';
import { LoggerService, ILogger } from './logger.service';
import { Router, ResolveEnd } from '@angular/router';
import { ComponentState } from '../model/component-state';
import { filter } from 'rxjs/operators';

@Injectable()
export class ComponentStateService {

  private logger: ILogger;
  private states: {
    [key: string]: {
      update?: () => void,
      state?: ComponentState
    } 
  } = {};

  constructor(
    private router: Router,
    logger: LoggerService
  ) { 
    this.logger = logger.getLogger('ComponentStateService');

    this.router.events
      .pipe(
        filter(event => event instanceof ResolveEnd)
      )
      .subscribe((event: ResolveEnd) => {
        const previousUrl = this.router.url;
        this.states[previousUrl] && this.states[previousUrl].update && this.states[previousUrl].update() 

        const nextUrl = event.urlAfterRedirects;
        Object.keys(this.states).filter(item => item.startsWith(nextUrl) && item.length > nextUrl.length).forEach(item => {
          this.logger.debug('Deleting component state for', item);
          delete this.states[item];
        });
      });
  }

  public getState(): ComponentState {
    const url = this.router.url;

    this.logger.debug('Getting component state for', url);

    return this.states[url] && this.states[url].state;
  }

  public setState(getState: () => ComponentState): void {
    const url = this.router.url;

    !this.states[url] && (this.states[url] = {});

    this.states[url].update = () => {
      this.logger.debug('Setting component state for', url);
      this.states[url].state = getState();
    };
  }
}
