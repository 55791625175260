import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { MachineDocumentAction } from '../../settings-modules/machine-documents/model/machine-document-action';

@Injectable()
export class ManageProjectDocumentActionsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private documentId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('ManageProjectDocumentActionsService');
  }

  public setProject(projectId: string): ManageProjectDocumentActionsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectDocument(documentId: string): ManageProjectDocumentActionsService {
    this.documentId = documentId;
    return this;
  }

  public save(action: MachineDocumentAction): Observable<MachineDocumentAction> {
    this.logger.debug('Saving machine document action');

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.documentId) {
      throw Error('Project document id not set. Use setProjectDocument() first');
    }

    const url = [this.apiUrl, this.projectId, 'documents', this.documentId, 'actions'].join('/');

    return this.http.post<MachineDocumentAction>(url, action).pipe(
      tap((_) => {
        this.logger.debug('Project document action saved');
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving project document action: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
