import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MachineDocument } from '../../../settings-modules/machine-documents/model/machine-document';
import { ProjectMachinesService } from '../../machine-services/shared/project-machines.service';
import { ProjectsService } from '../../../projects-services/shared/projects.service';
import { AuthService } from '../../../auth/shared/auth.service';

@Injectable()
export class ProjectMachineDocumentsService {

  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(
    private authService: AuthService,
    private projectService: ProjectsService,
    private machinesService: ProjectMachinesService,
    loggerService: LoggerService
  ) {
    this.logger = loggerService.getLogger('ProjectMachineDocumentsService');
  }

  public setProject(projectId: string): ProjectMachineDocumentsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): ProjectMachineDocumentsService {
    this.machineId = machineId;
    return this;
  }

  public getProjectMachineDocuments(): Observable<Array<MachineDocument>> {
    
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting documents for machine {0} in project {1}', this.machineId, this.projectId);

    return this.projectService.getProject(this.projectId)
      .pipe(
        first(),
        switchMap(project => {
          const providerId = this.authService.user.provider;
          const organizationId = this.authService.user.organization;
          let allowedCategories = [];

          if (organizationId === providerId) {
             allowedCategories = ['mandatory', 'customer', 'operator', '', null, undefined];
          } else {
            if (organizationId === project.company.id) {
              allowedCategories = ['mandatory', 'customer'];
            } else if (project.operator && organizationId === project.operator.id) {
              allowedCategories = ['mandatory', 'operator'];
            }
          }

          return this.machinesService.getProjectMachine(this.machineId)
            .pipe(
              first(),
              map(machine => {
                const documents = machine.documents && machine.documents.filter(item => allowedCategories.includes(item.category)) || [];
                return JSON.parse(JSON.stringify(documents)); //create copy because machine instance is shared
              }),
              catchError((err, caught) => {
                this.logger.error('Error getting project machine documents: {0} - {1}', err['status'], err['message']);
                return throwError(err);
              })
            );
        })
      );
  }

  public getProjectMachineDocument(documentId: string): Observable<MachineDocument> {

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting document with id {0} from machine {1} in project {2}', documentId, this.machineId, this.projectId);

    return this.projectService.getProject(this.projectId)
      .pipe(
        first(),
        switchMap(project => {
          const providerId = this.authService.user.provider;
          const organizationId = this.authService.user.organization;
          let allowedCategories = [];

          if (organizationId === providerId) {
            allowedCategories = ['mandatory', 'customer', 'operator', '', null, undefined];
          } else {
            if (organizationId === project.company.id) {
              allowedCategories = ['mandatory', 'customer'];
            } else if (project.operator && organizationId === project.operator.id) {
              allowedCategories = ['mandatory', 'operator'];
            }
          }

          return this.machinesService.getProjectMachine(this.machineId)
            .pipe(
              first(),
              map(machine => {
                const machineDocument = machine.documents && machine.documents.filter(item => allowedCategories.includes(item.category)).find(item => item.id === documentId);

                return machineDocument && JSON.parse(JSON.stringify(machineDocument)); //create copy because machine instance is shared
              }),
              catchError((err, caught) => {
                this.logger.error('Error getting project machine document: {0} - {1}', err['status'], err['message']);
                return throwError(err);
              })
            );
        })
      );
  }

}
