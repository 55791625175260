
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { Hazard } from '../model/hazard';


@Injectable()
export class InternalHazardsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/hazards';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('InternalHazardsService');
  }

  public save(hazard: Hazard): Observable<Hazard> {
    this.logger.debug('Saving hazard with id', hazard.id);

    const method = hazard.id != null ? 'PUT' : 'POST';
    const url = this.apiUrl + (hazard.id != null ? '/' + hazard.id : '');

    return this.http.request<Hazard>(method, url, {
      body: hazard
    }).pipe(
      tap(result => {
        this.logger.debug('Hazard saved');
      }),
      catchError((err, caught) => {
        this.logger.error('Error updating hazard: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  } 

  public delete(hazard: Hazard): Observable<void> {
    this.logger.debug('Deleting hazard with id', hazard.id);

    return this.http.delete<void>(this.apiUrl + '/' + hazard.id)
      .pipe(
        tap(result => {
          this.logger.debug('Hazard deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting Hazard: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public import(file: File): Observable<Array<Hazard>> {
    this.logger.debug('Importing hazards');

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    
    const method = 'POST';
    const url = [this.apiUrl, 'import'].join('/')

    return this.http.request<Array<Hazard>>(method, url, {
      body: formData
    }).pipe(
      tap(_ => {
        this.logger.debug('Hazards imported');
      }),
      catchError((err, caught) => {
        this.logger.error('Error importing hazards: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    )
  }
}
