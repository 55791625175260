<edit-modal name="equipment-edit-modal" (onHide)="cancel()" size="md">
  <edit-modal-header>
    <div class="row align-items-center table-header">
      <div class="col-auto">
        <modal-close-confirmation name="cancel-equipment">
          <button type="button" class="btn-close close" (click)="cancel()">
            <span class="material-icons icon-2x">close</span>
          </button>
        </modal-close-confirmation>
      </div>
      <div class="col text-right">
        <div class="list-inline" *ngIf="equipment">
          <div class="list-inline-item" *ngIf="editMode">
            <button-spinner name="delete-equipment">
              <button type="button" class="btn btn-outline-primary btn-action" (click)="delete()">
                {{ 'generic.button.delete' | translate }}
              </button>
            </button-spinner>
          </div>
          <div class="list-inline-item">
            <button-spinner name="save-equipment">
              <button type="button" class="btn btn-primary btn-action" (click)="save()">
                {{ 'projects.edit.button.save' | translate }}
              </button>
            </button-spinner>
          </div>
        </div>
      </div>
    </div>
  </edit-modal-header>

  <edit-modal-content>
    <spinner name="equipment-detail" show="true" class="edit-spinner">
      <div>{{ 'modal.loading' | translate }}</div>
    </spinner>

    <form [formGroup]="form" *ngIf="equipment" appRequiredLabel>
      <div class="card content-card">
        <div class="card-header">
          <div class="content-title">
            {{ 'menu.equipment-list.edit.title' | translate }}
          </div>
        </div>

        <div class="card-body">
          <div class="form-group with-error">
            <label for="reference">{{ 'model.equipment.reference' | translate }}</label>
            <input type="text" id="reference" formControlName="reference" class="form-control" />
            <div class="alert alert-error">
              <span *ngFor="let alert of form.alerts.reference">{{ alert }}</span>
            </div>
          </div>

          <div class="form-group">
            <label for="testDuration">{{ 'model.equipment.test-duration' | translate }}</label>
            <input type="text" id="testDuration" formControlName="testDuration" class="form-control" />
          </div>

          <div class="form-group">
            <label for="calibration">{{ 'model.equipment.calibration' | translate }}</label>
            <date-picker id="calibration" formControlName="calibration"></date-picker>
          </div>

          <div class="form-group with-error">
            <label for="calibrationDue">{{ 'model.equipment.calibration-due' | translate }}</label>
            <date-picker id="calibrationDue" formControlName="calibrationDue"></date-picker>
            <div class="alert alert-error">
              <span *ngFor="let alert of form.alerts.calibrationDue">{{ alert }}</span>
            </div>
          </div>

          <div class="form-group">
            <label for="description">{{ 'model.equipment.description' | translate }}</label>
            <input type="text" id="description" formControlName="description" class="form-control" />
          </div>

          <div class="form-group with-error">
            <label for="assetNumber">{{ 'model.equipment.asset-number' | translate }}</label>
            <input type="text" id="assetNumber" formControlName="assetNumber" class="form-control" />
            <div class="alert alert-error">
              <span *ngFor="let alert of form.alerts.assetNumber">{{ alert }}</span>
            </div>
          </div>

          <div class="form-group">
            <label for="manufacturer">{{ 'model.equipment.manufacturer' | translate }}</label>
            <input type="text" id="manufacturer" formControlName="manufacturer" class="form-control" />
          </div>

          <div class="form-group with-error">
            <label for="model">{{ 'model.equipment.model' | translate }}</label>
            <input type="text" id="model" formControlName="model" class="form-control" />
            <div class="alert alert-error">
              <span *ngFor="let alert of form.alerts.model">{{ alert }}</span>
            </div>
          </div>

          <div class="form-group with-error">
            <label for="assignedMeasurementTypes">{{ 'model.equipment.assigned-measurement-types' | translate }}</label>
            <ng-select
              [items]="pointerMeasurementTypes"
              bindLabel="name"
              formControlName="assignedMeasurementTypes"
              [multiple]="true"
            ></ng-select>
            <div class="alert alert-error">
              <span *ngFor="let alert of form.alerts.assignedMeasurementTypes">{{ alert }}</span>
            </div>
          </div>

          <div class="form-group d-flex justify-content-between">
            <label class="flex-shrink" for="isActive">{{ 'model.equipment.is-active' | translate }}</label>
            <span class="switch">
              <input type="checkbox" formControlName="isActive" id="isActive" />
              <label for="isActive"></label>
            </span>
          </div>
        </div>
      </div>
    </form>
  </edit-modal-content>
</edit-modal>

<modal-confirmation name="delete-equipment">
  <div [innerHTML]="confirmationDeleteSafeHtml"></div>
</modal-confirmation>
