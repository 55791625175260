import { throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { MachineStandard } from '../model/machine-standard';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';

@Injectable()
export class MachineStandardsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private readonly apiUrlProjectMachines: string = 'projectmachines';
  private readonly apiUrlStandards: string = 'standards';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('MachineStandardsService');
  }

  public setProject(projectId: string): MachineStandardsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): MachineStandardsService {
    this.machineId = machineId;
    return this;
  }

  public getStandards(filter?: QueryFilter): Observable<QueryResult<MachineStandard>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting standards for machine {0} from project {1}', this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, this.apiUrlProjectMachines, this.machineId, this.apiUrlStandards].join('/');

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<MachineStandard>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No machine standards found');
          return;
        }

        this.logger.debug('Machine standards found');
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting standards: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getStandard(id: string): Observable<MachineStandard> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting standard {0} for machine {1} from project {2}', id, this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, this.apiUrlProjectMachines, this.machineId, this.apiUrlStandards, id].join('/');

    return this.http.get<MachineStandard>(url).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No machine standard found');
          return;
        }

        this.logger.debug('Machine standard found');
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting project machine standards: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getStandardsPrefixes(): Observable<Array<string>> {
    this.logger.debug('Getting prefixes of machine standards');

    let url = [this.apiUrl, this.projectId, this.apiUrlProjectMachines, this.machineId, this.apiUrlStandards].join('/');

    url += new QueryFilter().take(1000).toQueryString();

    return this.http.get<QueryResult<MachineStandard>>(url).pipe(
      map((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No machine standard prefixes found');
          return [];
        }

        const standardPrefixes = result.data.map((item) => item.prefix);
        const prefixes = [...new Set(standardPrefixes)];

        if (prefixes.length === 0) {
          this.logger.debug('No machine standard prefixes found');
        }

        this.logger.debug('{0} machine standard prefixes found', prefixes.length);

        return prefixes;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine standard prefixes: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
