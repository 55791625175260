import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EditModalComponent } from '../../edit-modal/edit-modal/edit-modal.component';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Form, FormService } from '../../shared/form.service';
import { ILogger, LoggerService } from '../../shared/logger.service';
import { MinMaxColumns } from '../sub-grid-update';
import _ from 'lodash';

@Component({
  selector: 'app-min-max-edit',
  templateUrl: './min-max-edit.component.html',
  styleUrls: ['./min-max-edit.component.scss']
})
export class MinMaxEditComponent implements OnInit {
  @ViewChild(EditModalComponent, { static: true }) editModal: EditModalComponent;

  @Input()
  columns: MinMaxColumns;

  @Output()
  saveMinMax = new EventEmitter<MinMaxColumns>();

  public form: Form;

  attemptedSubmit = false;

  private logger: ILogger;
  private readonly measurementTestsFormArray = 'measurementTests';

  constructor(private formService: FormService, logger: LoggerService) {
    this.logger = logger.getLogger('MinMaxEditComponent');
  }
  get measurementTestControls() {
    return (this.form.get(this.measurementTestsFormArray) as FormArray).controls;
  }

  public ngOnInit(): void {
    this.logger.debug('Initializing component');

    this.form = this.formService.form({
      measurementTests: new FormArray([])
    });

    this.createTestFormControls();
  }

  public cancel(): void {
    this.editModal.hide();
    this.saveMinMax.emit(null);
  }

  public save(): void {
    if (this.form.canSubmit()) {
      this.logger.debug('Submiting form');

      const columns: MinMaxColumns = this.form.value.measurementTests.reduce((acc, cur) => {
        const controlIsDirty = this.measurementTestControls.find((control) => control.value.id === cur.id).dirty;
        return {
          ...acc,
          [cur.id]: {
            label: cur.label,
            min: cur.minValue ? Number(cur.minValue) : undefined,
            max: cur.maxValue ? Number(cur.maxValue) : undefined,
            definedByUser: controlIsDirty ? controlIsDirty : cur.definedByUser
          }
        };
      }, {});
      this.saveMinMax.emit(columns);
    } else {
      this.attemptedSubmit = true;
      this.logger.debug('Cannot submit form');
    }
  }

  public numberOnInput(event: InputEvent, fromControlArrayItem: FormControl, formControlName: string) {
    const formControl = fromControlArrayItem.get(formControlName);
    if (_.includes(formControl.value, ',')) {
      formControl.setValue(_.replace(formControl.value, ',', '.'));
    }
  }

  private createTestFormControls() {
    const formField = this.form.get(this.measurementTestsFormArray) as FormArray;
    const updatedMeasurementTestControls = [];
    formField.clear();

    const entries = Object.entries(this.columns);

    entries?.forEach(([key, value]) => {
      updatedMeasurementTestControls.push(this.createTestFormGroup(key, value));
    });

    updatedMeasurementTestControls.forEach((test, i) => formField.setControl(i, test));
  }

  private createTestFormGroup(key: string, value: { min: number; max: number; definedByUser: boolean; label: string }): FormGroup {
    return new FormGroup({
      id: new FormControl(key),
      minValue: new FormControl(value.min, this.numberValidator()),
      maxValue: new FormControl(value.max, this.numberValidator()),
      definedByUser: new FormControl(value.definedByUser),
      label: new FormControl(value.label)
    });
  }

  private numberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      isNaN(control.value) ? { forbiddenValue: { value: control.value } } : null;
  }

  isMinMaxValid(index: number, formControlName: string): boolean {
    return this.measurementTestControls[index].get(formControlName)?.valid;
  }
}
