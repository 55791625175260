<div class="file-drop" [ngClass]="layout"
		 [class.drag-over]="isDraggingOver" 
		 (drop)="drop($event)" 
		 (dragover)="over($event)" 
		 (dragleave)="leave($event)">
		 
	<ng-content></ng-content>

	<div class="drop-zone" *ngIf="isDraggingOver">
		<div class="drop-zone-box">
			<div class="drop-zone-content">
				<i class="material-icons icon-5x">cloud_upload</i>
				<p>
					{{'file-drop.drop-files-to-upload'|translate}}
				</p>
			</div>
		</div>
	</div>
</div>