import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { ILogger, LoggerService } from '../shared/logger.service';
import { CanComponentDeactivate } from './can-component-deactivate';


@Injectable()
export class CanDeactivateGuardService implements CanDeactivate<CanComponentDeactivate> {  
  
  private logger: ILogger;

  constructor(
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('CanDeactivateGuardService');
  }

  canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> |  Promise<boolean> {
    if(component.canDeactivate) {
      this.logger.debug('Executing canDeactivate()');
      return component.canDeactivate();
    } else {
      this.logger.debug('canDeactivate() does not exist');
      return true;
    }
  }

}
