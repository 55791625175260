export const environment = {
  name: 'DEV Environment',
  version: '3.0.1885d',
  production: true,

  uwp: false,
  installationUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com/desktop/MComPlus.UWP.appinstaller',
  helpUrl: 'https://help-mcom.tuvsud.com/moum',
  serverUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com/api',
  umServerUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com/api',

  authOptions: {
    clientId: 'mcomplus-dev',
    scope: 'openid mcomplus.api mcomplus.security_api',
    response: 'id_token token',
    authUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com/connect/authorize',
    deauthUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com/connect/endsession',
    selfcareUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com/connect/profileedit',
    redirectUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com/auth/callback',
    redirectSilentUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com/silent/callback/',
    redirectReloginUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com/relogin/callback/',
    redirectLogoutUrl: 'https://dev-mcomplus-gw.westeurope.cloudapp.azure.com'
  },

  appInsightsConfig: {
    instrumentationKey: '7f629cc4-0447-4e38-a06a-4137b436f831',
    enableCorsCorrelation: true
  },

  featureFlags: {
    identicalMachines: true,
    measurements: true,
    spe: true
  },
  logLevel: 0 //ALL = 0, TRACE = 1, DEBUG = 2, INFO = 3, WARN = 4, ERROR = 5, FATAL = 6, OFF = 7
};
