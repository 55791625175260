import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';

import { ConfirmationService } from '../shared/confirmation.service';
import { Confirmation } from '../model/Confirmation';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';

@Component({
  selector: 'modal-close-confirmation',
  templateUrl: './modal-close-confirmation.component.html',
  styleUrls: ['./modal-close-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalCloseConfirmationComponent implements OnInit, OnDestroy, Confirmation {

  @Input() name: string;
  @Input() group: string;
  @Input() message: string = this.translateService.instant('confirmation.cancel');

  @Input()
  get show(): boolean {
    return this.isShowing;
  }
  set show(val: boolean) {
    this.isShowing = val;
    this.showChange.emit(this.isShowing);
  }

  @Output() showChange = new EventEmitter();

  private isShowing = false;

  constructor(private confirmationService: ConfirmationService,
  private translateService:TranslateService) { }

  ngOnInit(): void {
    if (!this.name) throw new Error("Confirmation must have a 'name' attribute.");

    this.confirmationService.register(this);
  }

  ngOnDestroy(): void {
    this.confirmationService.unregister(this);
  }

  public cancel(): void {
    this.show = false;
    this.confirmationService.cancel(this);
  }

  public confirm() {
    this.show = false;
    this.confirmationService.confirm(this);
  }
  
  public scrollToConfirmation(): void {
    //not needed here
  }
}

