import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, filter, finalize, first, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { QueryFilter } from '../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../core/query-filter/model/QueryResult';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { Project } from '../model/project';
import { UpdateUserInProjects } from '../model/update-user-in-projects';

@Injectable()
export class ProjectsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private isLoadingProject: { [key: string]: boolean } = {};
  private projectsResolver: { [key: string]: BehaviorSubject<Project> } = {};
  private projectsTimeout: { [key: string]: any } = {};

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('ProjectsService');
  }

  public getProjects(filter?: QueryFilter): Observable<QueryResult<Project>> {
    this.logger.debug('Getting projects');

    const url = this.apiUrl;

    return this.http.put<QueryResult<Project>>(url, filter && filter.toJson()).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No projects found');
          return result;
        }

        this.logger.debug('{0} projects found', result.data.length);

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting projects: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getProject(id: string): Observable<Project> {
    this.logger.debug('Getting project with id', id);

    if (!id) {
      this.logger.debug('Project id is null, returning null');
      return of(null);
    }

    let resolver = this.projectsResolver[id];

    if (!resolver) {
      resolver = this.projectsResolver[id] = new BehaviorSubject<Project>(undefined);
    }

    if (resolver.getValue() === undefined && !this.isLoadingProject[id]) {
      this.logger.debug('Project {0} is not loaded, processing', id);

      this.isLoadingProject[id] = true;
      this.projectsTimeout[id] && clearTimeout(this.projectsTimeout[id]);

      this.http
        .get<Project>(this.apiUrl + '/' + id)
        .pipe(
          first(),
          finalize(() => (this.isLoadingProject[id] = false))
        )
        .subscribe(
          (result) => {
            if (!result) {
              this.logger.debug('No project found');
            } else {
              this.logger.debug('Project found');
            }

            this.projectsTimeout[id] = setTimeout((_) => {
              //cache for 2 seconds and then clear
              resolver.next(undefined);
            }, 2000);

            resolver.next(result);
          },
          (error) => {
            this.logger.error('Error getting project: {0} - {1}', error['status'] || error['code'], error['message']);
            resolver.error(error);
          }
        );
    } else {
      this.logger.debug('Project {0} is cached, returning', id);
    }

    return resolver.pipe(filter((result) => result !== undefined));
  }

  public updateUserInProjects(request: UpdateUserInProjects, queryFilter?: QueryFilter): Observable<void> {
    const method = 'PUT';
    const url = this.apiUrl + '/AddUser';

    return this.http
      .request<void>(method, url + queryFilter.toQueryString(), {
        body: request
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Users in projects updated');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating project: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
