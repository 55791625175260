import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NotificationsService } from '../../notifications/shared/notifications.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent {
  @Input()
  public allowedMimeTypes: Array<string>;
  @Input()
  public layout: string;
  @Input()
  public disabled = false;

  @Output()
  public filesSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

  public isDraggingOver = false;

  constructor(private notificationsService: NotificationsService, private translateService: TranslateService) {}

  public over(event: Event): void {
    if (!this.disabled) {
      this.isDraggingOver = true;
    }
    this.preventAndStop(event);
  }

  public leave(event: Event): void {
    this.isDraggingOver = false;
    this.preventAndStop(event);
  }

  public drop(event: any) {
    if (this.disabled) {
      return;
    }

    const files: File[] = event.dataTransfer.files;
    let allowedFiles: File[] = [];
    const preventedFiles: File[] = [];

    this.isDraggingOver = false;

    event.dataTransfer.dropEffect = 'copy';

    this.preventAndStop(event);

    if (this.allowedMimeTypes && this.allowedMimeTypes.length > 0) {
      for (const file of files) {
        const fileExtension = ('.' + file.name.split('.').pop()).toLowerCase();

        if (this.allowedMimeTypes.indexOf(fileExtension) > -1) {
          allowedFiles.push(file);
        } else {
          preventedFiles.push(file);
        }
      }
    } else {
      allowedFiles = files;
    }

    if (allowedFiles.length > 0) {
      if (this.filesSelected) {
        this.filesSelected.emit(allowedFiles);
      }

      allowedFiles = [];
    } else {

      const details = preventedFiles.map((file) => file.name).join(', ');

      this.notificationsService.danger(this.translateService.instant('file-upload.notification.error-type-not-allowed'), details);
    }
  }

  private preventAndStop(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }
}
