import { APP_INITIALIZER, NgModule } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NonceService } from './auth/internal/nonce.service';
import { TokenService } from './auth/internal/token.service';
import { AuthService } from './auth/shared/auth.service';
import { ServerFileDownloader } from './core-services/shared/server-file-downloader';
import { StartupService } from './core/shared/startup.service';
import { SynchronizationService } from './core/shared/synchronization.service';
import { TranslationService } from './core/shared/translation.service';
import { DocumentsProjectDocumentsService } from './documents/shared/documents-project-documents.service';
import { DocumentsProjectMachineDocumentsService } from './documents/shared/documents-project-machine-documents.service';
import { DocumentsProjectMachinesService } from './documents/shared/documents-project-machines.service';
import { DocumentsProjectsService } from './documents/shared/documents-projects.service';
import { MachineAssessedHazardsService } from './implementations-modules/assessed-machines/shared/machine-assessed-hazards.service';
import { AssessedMachinesService } from './implementations-services/shared/assessed-machines.service';
import { InternalCompanyImplementationsReportsService } from './implementations/internal/internal-company-implementations-reports.service';
import { InternalSiteImplementationsReportsService } from './implementations/internal/internal-site-implementations-reports.service';
import { ImplementationsHelperService } from './implementations/shared/implementations-helper.service';
import { InternalMachineAssessmentHazardsService } from './projects-modules/machine-assessment-hazards-services/internal/internal-machine-assessment-hazards.service';
import { ExaminationHazardsService } from './projects-modules/machine-assessment-hazards-services/shared/examination-hazards.service';
import { MachineAssessmentHazardsService } from './projects-modules/machine-assessment-hazards-services/shared/machine-assessment-hazards.service';
import { ProjectHazardPhotosURLResolverService } from './projects-modules/machine-assessment-hazards-services/shared/project-hazard-photos-urlresolver.service';
import { InternalExaminationHazardsService } from './projects-modules/machine-assessments/internal/internal-examination-hazards.service';
import { InternalExaminationsService } from './projects-modules/machine-assessments/internal/internal-examinations.service';
import { ExaminationsService } from './projects-modules/machine-assessments/shared/examinations.service';
import { InternalMachineComponentsService } from './projects-modules/machine-components/internal/internal-machine-components.service';
import { MachineComponentsService } from './projects-modules/machine-components/shared/machine-components.service';
import { InternalMachineControlIntegritiesService } from './projects-modules/machine-control-integrities/internal/internal-machine-control-integrities.service';
import { MachineControlIntegritiesService } from './projects-modules/machine-control-integrities/shared/machine-control-integrities.service';
import { InternalMachineDeclarationsService } from './projects-modules/machine-declarations/internal/internal-machine-declarations.service';
import { MachineDeclarationsService } from './projects-modules/machine-declarations/shared/machine-declarations.service';
import { ProjectMachineDocumentsService } from './projects-modules/machine-documents/shared/project-machine-documents.service';
import { InternalMachineHazardActionDocumentsService } from './projects-modules/machine-hazards-services/internal/internal-machine-hazard-action-documents.service';
import { InternalMachineHazardActionsService } from './projects-modules/machine-hazards-services/internal/internal-machine-hazard-actions.service';
import { InternalMachineHazardsService } from './projects-modules/machine-hazards-services/internal/internal-machine-hazards.service';
import { MachineHazardActionDocumentsService } from './projects-modules/machine-hazards-services/shared/machine-hazard-action-documents.service';
import { MachineHazardPhotosURLResolverService } from './projects-modules/machine-hazards-services/shared/machine-hazard-photos-urlresolver.service';
import { MachineHazardsService } from './projects-modules/machine-hazards-services/shared/machine-hazards.service';
import { MachineMeasurementsService } from './projects-modules/machine-measurements/shared/machine-measurements.service';
import { InternalMachinePhotosService } from './projects-modules/machine-photos/internal/internal-machine-photos.service';
import { MachinePhotosURLResolverService } from './projects-modules/machine-photos/shared/machine-photos-urlresolver.service';
import { InternalManageMachinesService } from './projects-modules/machine-services/internal/internal-manage-machines.service';
import { ManageHazardPhotosService } from './projects-modules/machine-services/shared/manage-hazard-photos.service';
import { ManageMachineHazardActionDocumentsService } from './projects-modules/machine-services/shared/manage-machine-hazard-action-documents.service';
import { ManageMachineHazardsService } from './projects-modules/machine-services/shared/manage-machine-hazards.service';
import { ManageMachinePhotosService } from './projects-modules/machine-services/shared/manage-machine-photos.service';
import { ManageMachinesService } from './projects-modules/machine-services/shared/manage-machines.service';
import { ManageProjectMachineDocumentActionsService } from './projects-modules/machine-services/shared/manage-project-machine-document-actions.service';
import { ManageProjectMachineDocumentsService } from './projects-modules/machine-services/shared/manage-project-machine-documents.service';
import { ProjectMachinesService } from './projects-modules/machine-services/shared/project-machines.service';
import { InternalMachineStandardsService } from './projects-modules/machine-standards/internal/internal-machine-standards.service';
import { MachineStandardsService } from './projects-modules/machine-standards/shared/machine-standards.service';
import { InternalProjectMachinesService } from './projects-modules/machines/internal/internal-project-machines.service';
import { ManageProjectDocumentActionsService } from './projects-services/shared/manage-project-document-actions.service';
import { ManageProjectDocumentsService } from './projects-services/shared/manage-project-documents.service';
import { ProjectDocumentsService } from './projects-services/shared/project-documents.service';
import { ProjectsService } from './projects-services/shared/projects.service';
import { InternalProjectsService } from './projects/internal/internal-projects.service';
import { AssessmentTypesService } from './settings-modules/assessment-types/shared/assessment-types.service';
import { InternalCompaniesService } from './settings-modules/companies/internal/internal-companies.service';
import { InternalCompanyLogosService } from './settings-modules/companies/internal/internal-company-logos.service';
import { CompaniesService } from './settings-modules/companies/shared/companies.service';
import { CompanyLogosURLResolverService } from './settings-modules/companies/shared/company-logos-urlresolver.service';
import { OrganizationsService } from './settings-modules/companies/shared/organizations.service';
import { InternalHazardsService } from './settings-modules/hazards/internal/internal-hazards.service';
import { HazardGroupsService } from './settings-modules/hazards/shared/hazard-groups.service';
import { HazardsService } from './settings-modules/hazards/shared/hazards.service';
import { InternalMachineDocumentsService } from './settings-modules/machine-documents/internal/internal-machine-documents.service';
import { MachineDocumentsService } from './settings-modules/machine-documents/shared/machine-documents.service';
import { InternalMachineTypesService } from './settings-modules/machine-types/internal/internal-machine-types.service';
import { MachineTypesService } from './settings-modules/machine-types/shared/machine-types.service';
import { InternalMachinesService } from './settings-modules/machines/internal/internal-machines.service';
import { MachinesService } from './settings-modules/machines/shared/machines.service';
import { InternalSitesService } from './settings-modules/sites/internal/internal-sites.service';
import { SitesService } from './settings-modules/sites/shared/sites.service';
import { InternalStandardsService } from './settings-modules/standards/internal/internal-standards.service';
import { StandardTypesService } from './settings-modules/standards/shared/standard-types.service';
import { StandardsService } from './settings-modules/standards/shared/standards.service';
import { InternalUsersReportsService } from './settings-modules/user-management/shared/internal-users-reports.service';
import { UsersService } from './settings-modules/user-management/shared/users.service';
import { HazardImplementationHandlerService } from './user-notifications/shared/hazard-implementation-handler.service';
import { ProjectMachineDocumentHandlerService } from './user-notifications/shared/project-machine-document-handler.service';
import { UserNotificationsService } from './user-notifications/shared/user-notifications.service';
import { MachineAssessedHazardsMemoryService } from './implementations-modules/assessed-machines/shared/machine-assessed-hazards-memory.service';

export function initServices(
  authService: AuthService,
  translationService: TranslationService,
  userNotificationsService: UserNotificationsService
) {
  return (): Promise<any> =>
    authService
      .init()
      .pipe(switchMap((result) => (result && userNotificationsService.init()) || of(false)))
      .toPromise();
}

@NgModule({
  providers: [
    AuthService,
    { provide: APP_INITIALIZER, useFactory: initServices, deps: [AuthService, TranslationService, UserNotificationsService], multi: true },
    ManageMachinesService, //shared service with UWP
    SynchronizationService,
    StartupService,
    TokenService,
    NonceService,
    ServerFileDownloader,
    ProjectsService,
    InternalProjectsService,
    UsersService,
    StandardsService,
    StandardTypesService,
    InternalStandardsService,
    SitesService,
    InternalSitesService,
    MachinesService,
    InternalMachinesService,
    MachineTypesService,
    InternalMachineTypesService,
    HazardsService,
    HazardGroupsService,
    InternalHazardsService,
    CompaniesService,
    OrganizationsService,
    InternalCompaniesService,
    AssessmentTypesService,
    InternalManageMachinesService,
    ProjectMachinesService,
    ManageMachineHazardsService,
    ManageHazardPhotosService,
    InternalMachinePhotosService,
    InternalProjectMachinesService,
    MachineStandardsService,
    InternalMachineStandardsService,
    MachineHazardsService,
    InternalMachineHazardsService,
    InternalMachineHazardActionsService,
    MachineDeclarationsService,
    InternalMachineDeclarationsService,
    MachineControlIntegritiesService,
    InternalMachineControlIntegritiesService,
    ExaminationsService,
    InternalExaminationsService,
    InternalExaminationHazardsService,
    ExaminationHazardsService,
    MachineAssessmentHazardsService,
    InternalMachineAssessmentHazardsService,
    MachinePhotosURLResolverService,
    MachineHazardPhotosURLResolverService,
    ProjectHazardPhotosURLResolverService,
    CompanyLogosURLResolverService,
    InternalCompanyLogosService,
    ManageMachinePhotosService,
    UserNotificationsService,
    HazardImplementationHandlerService,
    ProjectMachineDocumentHandlerService,
    MachineAssessedHazardsService,
    MachineAssessedHazardsMemoryService,
    AssessedMachinesService,
    InternalMachineDocumentsService,
    MachineDocumentsService,
    MachineHazardActionDocumentsService,
    InternalMachineHazardActionDocumentsService,
    ManageMachineHazardActionDocumentsService,
    MachineMeasurementsService,
    MachineComponentsService,
    InternalMachineComponentsService,
    InternalCompanyImplementationsReportsService,
    InternalSiteImplementationsReportsService,
    InternalUsersReportsService,
    ImplementationsHelperService,
    ProjectMachineDocumentsService,
    ManageProjectMachineDocumentsService,
    ManageProjectMachineDocumentActionsService,
    ProjectDocumentsService,
    ManageProjectDocumentsService,
    ManageProjectDocumentActionsService,
    DocumentsProjectMachinesService,
    DocumentsProjectMachineDocumentsService,
    DocumentsProjectsService,
    DocumentsProjectDocumentsService
  ]
})
export class AppProvidersModule {}
