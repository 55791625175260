
import { throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { environment } from '../../../../environments/environment';
import { AssessedHazard } from '../../../implementations-services/model/assessed-hazard';

@Injectable()
export class MachineAssessedHazardsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/assessedmachines';
  private logger: ILogger;

  private machineId: string;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('AssessedHazardsService');
  }

  public setAssessedMachine(id: string): MachineAssessedHazardsService {
    this.machineId = id;
    return this;
  }

  public getAssessedMachine(): string {
    return this.machineId;
  }

  public getAssessedHazards(filter?: QueryFilter): Observable<QueryResult<AssessedHazard>> {
    this.logger.debug('Getting hazard implementations');

    if (!this.machineId) {
      throw Error('Assessed Machine Id not set. Use setAssessedMachine() first');
    }

    let url = [
      this.apiUrl, 
      this.machineId, 
      'assessedhazards'
    ].join('/');

    this.logger.debug('Getting hazard implementations', url, filter);

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<AssessedHazard>>(url)
      .pipe(
        tap(result => {
          if (!result || !result.data || result.data.length === 0) {
            this.logger.debug('No assessed hazards found');
            return result;
          }

          this.logger.debug('{0} assessed hazards found', result.data.length);

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting assessed hazards: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public getAssessedHazard(id: string): Observable<AssessedHazard> {

    //TODO: add caching (due to photo url resolver)

    if (!this.machineId) {
      throw Error('Assessed Machine Id not set. Use setAssessedMachine() first');
    }

    this.logger.debug('Getting assessed hazard with id', id);

    const url = [
      this.apiUrl,
      this.machineId,
      'assessedhazards',
      id
    ].join('/');

    return this.http.get<AssessedHazard>(url)
      .pipe(
        tap(result => {
          if (!result) {
            this.logger.debug('No assessed hazard found');
            return result;
          }

          this.logger.debug('Assessed hazard found');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting assessed hazard: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }
}
