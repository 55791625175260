import { ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export class AppAnalytics {

  private static appInsights: ApplicationInsights;

  public static init(): boolean {
    if (this.appInsights) {
      return true;
    }

    if (environment.appInsightsConfig && environment.appInsightsConfig.instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: environment.appInsightsConfig
      });

      this.appInsights.loadAppInsights();

      console.log('analytics initialized');

      return true;
    }

    return false;
  }

  public static setUserId(userId: string): void {
    AppAnalytics.getAppInsights() && AppAnalytics.getAppInsights().setAuthenticatedUserContext(userId);
  }

  public static pageView(
    name: string,
    url?: string,
    properties?: { [key: string]: string },
    duration?: string) {

    AppAnalytics.getAppInsights() && AppAnalytics.getAppInsights().trackPageViewPerformance({
      name: name, 
      uri: url, 
      properties: this.getProperties(properties), 
      duration: duration
    });
  }

  public static event(name: string, properties?: { [key: string]: string }) {
    AppAnalytics.getAppInsights() && AppAnalytics.getAppInsights().trackEvent({
      name: name, 
      properties: this.getProperties(properties)
    });
  }

  public static error(error: Error, properties?: { [key: string]: string }) {
    AppAnalytics.getAppInsights() && AppAnalytics.getAppInsights().trackException({
      exception: error, 
      severityLevel: 3,
      properties: this.getProperties(properties)
    });
  }

  private static getProperties(properties?: { [key: string]: string }): { [key: string]: string } {
    if (!properties) {
      properties = {};
    }

    properties['version'] = environment.version;
    properties['uwp'] = '' + environment.uwp;

    return properties;
  }

  public static navigation(root: ActivatedRouteSnapshot, urlAfterRedirects: string): void {
    const activatedComponent = this.getActivatedComponent(root);
    if (activatedComponent) {
      this.pageView(`${activatedComponent.name} ${this.getRouteTemplate(root)}`, urlAfterRedirects);
    }
  }

  private static getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private static getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';

    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  private static getAppInsights(): ApplicationInsights {
    if (this.appInsights == null) {
      AppAnalytics.init();
    }

    return this.appInsights;
  }
}
