
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { MachineType } from '../model/machine-type';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';

@Injectable()
export class InternalMachineTypesService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/machinetypes';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('InternalMachineTypesService');

  }

  public save(machineType: MachineType): Observable<MachineType> {
    this.logger.debug('Saving machine type with id', machineType.id);

    const method = machineType.id != null ? 'PUT' : 'POST';
    const url = this.apiUrl + (machineType.id != null ? '/' + machineType.id : '');

    return this.http.request<MachineType>(method, url, {
      body: machineType
    }).pipe(
      tap(result => {
        this.logger.debug('machineType saved');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error updating machine type: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public delete(machineType: MachineType): Observable<void> {
    this.logger.debug("Deleting machine type", machineType.id);

    return this.http.delete<void>(this.apiUrl + '/' + machineType.id)
      .pipe(
        tap(result => {
          this.logger.debug("Machine Type deleted");
        }),
        catchError((err, caught) => {
          this.logger.error("Error deleting machine type {0} - {1}", err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

}
