import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { Project } from '../../projects-services/model/project';

@Injectable()
export class InternalProjectsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalProjectsService');
  }

  public save(project: Project): Observable<Project> {
    this.logger.debug('Saving project with id', project.id);

    const method = project.id != null ? 'PUT' : 'POST';
    const url = this.apiUrl + (project.id != null ? '/' + project.id : '');

    return this.http
      .request<Project>(method, url, {
        body: project
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Project saved');
          return result;
        }),
        catchError((err) => {
          this.logger.error('Error updating project: {0} - {1}', err['status'] || err['code'], err['message']);
          // this.logger.error('Error updating project: caught', caught);
          return observableThrowError(err);
        })
      );
  }

  public delete(project: Project): Observable<void> {
    this.logger.debug('Deleting project with id', project.id);

    const method = 'DELETE';
    const url = [this.apiUrl, project.id].join('/');

    return this.http.request<void>(method, url).pipe(
      tap((_) => {
        this.logger.debug('Project deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting project: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public finish(project: Project): Observable<void> {
    this.logger.debug('Finishing project with id', project.id);

    const method = 'PUT';
    const url = [this.apiUrl, project.id, 'finish'].join('/');

    return this.http.request<void>(method, url).pipe(
      tap((_) => {
        this.logger.debug('Project finished');
      }),
      catchError((err, caught) => {
        this.logger.error('Error finishing project: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public reopen(project: Project): Observable<void> {
    this.logger.debug('Reopening project with id', project.id);

    const method = 'PUT';
    const url = [this.apiUrl, project.id, 'reopen'].join('/');

    return this.http.request<void>(method, url).pipe(
      tap((_) => {
        this.logger.debug('Project reopened');
      }),
      catchError((err, caught) => {
        this.logger.error('Error reopening project: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public import(companyId: string, siteId: string, file: any): Observable<Array<string>> {
    this.logger.debug('Importing projects to company {0} and site {1}', companyId, siteId);

    const method = 'POST';
    const url = [this.apiUrl, 'import'].join('/');

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('companyId', companyId);
    formData.append('siteId', siteId);

    return this.http
      .request<Array<string>>(method, url, {
        body: formData
      })
      .pipe(
        map((result) => {
          this.logger.debug('Projects imported');

          if (!result || !result['ids']) {
            this.logger.debug('No projects imported');
            return [];
          }

          this.logger.debug('{0} projects imported', result['ids'].length);

          return result['ids'];
        }),
        catchError((err, caught) => {
          this.logger.error('Error importing projects: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public saveHandover(project: Project): Observable<Project> {
    this.logger.debug('saveHandover Saving project with id', project.id);

    const method = 'POST';
    const url = this.apiUrl + '/' + project.id + '/Clone';

    return this.http
      .request<Project>(method, url, {
        body: project
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Project saved');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating project: {0} - {1} - {2}', err['status'] || err['code'], err['message'], caught);
          return observableThrowError(err);
        })
      );
  }

  public copyProject(project: Project): Observable<string> {
    this.logger.debug('copyProject Copying project with id', project.id);

    const method = 'POST';
    const url = this.apiUrl + '/' + project.id + '/Copy';

    return this.http
      .request<string>(method, url, {
        body: project
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Project copied');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating project: {0} - {1} - {2}', err['status'] || err['code'], err['message'], caught);
          return observableThrowError(err);
        })
      );
  }
}
