import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';

import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { Standard } from '../model/standard';

@Injectable()
export class InternalStandardsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/standards';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalStandardsService');
  }

  public save(standard: Standard): Observable<Standard> {
    this.logger.debug('Saving standard with id', standard.id);

    const method = standard.id != null ? 'PUT' : 'POST';
    const url = this.apiUrl + (standard.id != null ? '/' + standard.id : '');

    return this.http
      .request<Standard>(method, url, {
        body: standard
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Standard saved');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating standard: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public delete(standard: Standard): Observable<void> {
    this.logger.debug('Deleting standard with id', standard.id);

    return this.http.delete<void>(this.apiUrl + '/' + standard.id).pipe(
      tap((result) => {
        this.logger.debug('Standard deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting standard: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
