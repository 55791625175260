export class QueryFilter {
  public offset = 0;
  public limit = 15;
  public count = 0;

  private where?: { [key: string]: { [key: string]: any } };
  private properties?: Array<string>;
  private orders?: Array<string>;

  public adjustFrom(count: number): boolean {
    if (this.offset > 0 && this.offset >= count) {
      this.offset = (Math.floor(count / this.limit) - 1) * this.limit;
      return true;
    }

    return false;
  }

  public from(offset: number): QueryFilter {
    this.offset = offset;
    return this;
  }

  public take(limit: number): QueryFilter {
    this.limit = limit;
    return this;
  }

  public equalTo(property: string, value: any): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $eq: value });
    return this;
  }

  public notEqualTo(property: string, value: any): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $neq: value });
    return this;
  }

  public contains(property: string, value: any): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $contains: value });
    return this;
  }

  public notContains(property: string, value: any): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $ncontains: value });
    return this;
  }

  public containedIn(property: string, value: Array<any>): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $in: value });
    return this;
  }

  public notContainedIn(property: string, value: Array<any>): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $nin: value });
    return this;
  }

  public exists(property: string): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $exists: true });
    return this;
  }

  public greaterThan(property: string, value: any): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $gt: value });
    return this;
  }

  public greaterThanOrEqualTo(property: string, value: any): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $gte: value });
    return this;
  }

  public lessThan(property: string, value: any): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $lt: value });
    return this;
  }

  public lessThanOrEqualTo(property: string, value: any): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    this.where[property] = Object.assign({}, this.where[property], { $lte: value });
    return this;
  }

  public or(...filters: Array<QueryFilter>): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    if (!filters) {
      return this;
    }

    this.where['$or'] = [];

    filters.forEach((filter) => {
      this.where['$or'] = this.where['$or'].concat(filter.whereArray());
    });

    return this;
  }

  public and(...filters: Array<QueryFilter>): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    if (!filters) {
      return this;
    }

    this.where['$and'] = [];

    filters.forEach((filter) => {
      this.where['$and'] = this.where['$and'].concat(filter.whereArray());
    });

    return this;
  }

  /*
    public and(...filters: Array<QueryFilter>): QueryFilter {
    if (!this.where) {
      this.where = {};
    }
    if (!filters) {
      return this;
    }

    // Initialize $and array if it doesn't exist yet
    if (!this.where['$and']) {
      this.where['$and'] = [];
    }

    filters.forEach((filter) => {
      this.where['$and'] = this.where['$and'].concat(filter.whereArray());
    });

    return this;
  }
  */

  public clearOr(): QueryFilter {
    if (!this.where) {
      return this;
    }
    delete this.where['$or'];
    return this;
  }

  public clearWhere(property?: string | Array<string>): QueryFilter {
    if (!this.where) {
      return this;
    }

    if (property && Array.isArray(property)) {
      property.forEach((name) => delete this.where[name]);
    } else if (property) {
      delete this.where['' + property];
    } else {
      delete this.where;
    }

    return this;
  }

  public ascending(property: string): QueryFilter {
    if (!this.orders) {
      this.orders = [];
    }
    this.orders.push(property);
    return this;
  }

  public descending(property: string): QueryFilter {
    if (!this.orders) {
      this.orders = [];
    }
    this.orders.push('-' + property);
    return this;
  }

  public clearOrder(): QueryFilter {
    delete this.orders;
    return this;
  }

  public keys(properties: Array<string>): QueryFilter {
    this.properties = properties;
    return this;
  }

  public clearKeys(): QueryFilter {
    delete this.properties;
    return this;
  }

  public whereArray(): { [key: string]: { [key: string]: any } } {
    return Object.assign({}, this.where);
  }

  public ordersArray(): Array<string> {
    return Object.assign([], this.orders);
  }

  public toQueryString(): string {
    const params = new URLSearchParams();

    this.where && Object.keys(this.where).length > 0 && params.set('where', JSON.stringify(this.where));
    this.orders && this.orders.length > 0 && params.set('order', this.orders.join(','));
    this.properties && this.properties.length > 0 && params.set('keys', this.properties.join(','));

    params.set('offset', '' + this.offset);
    params.set('limit', '' + this.limit);

    return '?' + params;
  }

  public toJson(): string {
    const result: any = {
      offset: this.offset,
      limit: this.limit
    };

    this.where && Object.keys(this.where).length > 0 && (result['where'] = JSON.stringify(this.where));
    this.orders && this.orders.length > 0 && (result['order'] = this.orders.join(','));
    this.properties && this.properties.length > 0 && (result['keys'] = this.properties.join(','));

    return result;
  }

  public getOrders(): Array<string> | undefined {
    return this.orders;
  }
}
