import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';

import { NotificationsService } from '../shared/notifications.service';
import { LoggerService, ILogger } from '../../shared/logger.service'; 
import { Notification } from '../model/Notification';

export type ANIMATION_TYPE = 'none' | 'decent';

@Component({
  selector: 'notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
  animations: [
    trigger('animation', [
      state('none', style({})),
      state('decent', style([{ opacity: 1 }])),
      transition('void => decent', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out')
      ]),
      transition('decent => void', [
        animate('300ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class NotificationCenterComponent implements OnInit, OnDestroy {

  @Input() public animation: ANIMATION_TYPE = 'decent';
  @Input() public htmlTextEnabled = false;
  @Input() public context;

  public notifications: Notification[] = [];
  public subscriptions: Subscription[] = [];

  private logger: ILogger;

  constructor(
    private notificationsService: NotificationsService,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('NotificationsComponent');
  }

  public ngOnInit(): void {
    this.logger.debug('Initializing component');

    this.subscriptions.push(
      this.notificationsService.getNotifications().subscribe(notification => {
        if (notification.context === this.context) {
          this.notifications.unshift(notification);
        }
      })
    );

    this.subscriptions.push(
      this.notificationsService.getDismissedNotifications().subscribe(notification => {
        this.remove(notification);
      })
    );
  }

  public ngOnDestroy(): void {
    this.logger.debug('Destroying component');
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public remove(notification: Notification) {
    const index = this.notifications.indexOf(notification);
    if (index > -1) {
      this.notifications.splice(index, 1);

      if (notification.dismissed) {
        notification.dismissed();
      }
    }
  }

  public getAnimation(): string {
    return this.animation;
  }
}
