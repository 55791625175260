import { Injectable } from '@angular/core';
import { MachineHazard } from '../../machine-services/model/machine-hazard';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { Observable, throwError } from 'rxjs';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class MachineAssessmentHazardsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;

  private projectId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('MachineAssessmentHazardsService');
  }

  public setProject(id: string): MachineAssessmentHazardsService {
    this.projectId = id;
    return this;
  }

  public getProjectHazards(filter?: QueryFilter): Observable<QueryResult<MachineHazard>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Getting project hazards for project {0}', this.projectId);

    let url = [this.apiUrl, this.projectId, 'hazards'].join('/');

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<MachineHazard>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No project hazards found');
          return result;
        }

        this.logger.debug('Project hazards found');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting project hazards: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public getProjectHazard(id: string): Observable<MachineHazard> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    this.logger.debug('Getting project hazard with id', id);

    let url = [this.apiUrl, this.projectId, 'hazards', id].join('/');

    return this.http.get<MachineHazard>(url).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No project hazard found');
          return result;
        }

        this.logger.debug('Project hazard found');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting project hazard: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }
}
