import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[Autofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  private foccus = true;
  constructor(
    private el: ElementRef
  ) { }

  ngAfterViewInit() {
    this.el.nativeElement.focus();
  }
}
