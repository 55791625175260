import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RequestFileInterceptor implements HttpInterceptor {
  private readonly maxLength = 120 * 1000; // kb * 1000
  private readonly headerName = 'request-file-interceptor';

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipMethods = ['GET', 'OPTIONS', 'DELETE'];

    if (skipMethods.includes(req.method)) {
      return next.handle(req);
    }

    if (req.body instanceof FormData) {
      return next.handle(req);
    }

    const jsonBody = JSON.stringify(req.body);

    if (jsonBody.length < this.maxLength) {
      return next.handle(req);
    }

    req = req.clone({
      headers: req.headers.set(this.headerName, 'true').delete('Content-Type'),
      body: this.convertPayloadToBinary(jsonBody)
    });

    return next.handle(req);
  }

  private convertPayloadToBinary(jsonBody: string) {
    const blob = new Blob([jsonBody], { type: 'application/json' });
    const file = new File([blob], 'file.json');

    const formData = new FormData();
    formData.append('file', file);

    return formData;
  }
}
