import { Injectable } from '@angular/core';
import { Observable, of, empty } from 'rxjs';
import { SyncState } from '../model/sync-state';

@Injectable()
export class SynchronizationService {

  constructor() { }

  //dummy implementation

  public isOnline(): Observable<boolean> {
    return of(true);
  }

  public lastSynchronization(): Observable<Date> {
    return empty();
  }

  public synchronization(): Observable<SyncState> {
    return of(SyncState.Done);
  }

  public synchronize(): Observable<void>;
  public synchronize(force: boolean): Observable<void>;
  public synchronize(force?: boolean): Observable<void> {
    return empty();
  }

  public init(): Observable<boolean> {
    return of(true);
  }
}
