import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { Hazard } from '../../../settings-modules/hazards/model/hazard';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class ExaminationHazardsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;

  private projectId: string;
  private machineId: string;
  private examinationId: string;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('ExaminationHazardsService');
  }

  public setProject(id: string): ExaminationHazardsService {
    this.projectId = id;
    return this;
  }

  public setProjectMachine(id: string): ExaminationHazardsService {
    this.machineId = id;
    return this;
  }

  public setExamination(id: string): ExaminationHazardsService {
    this.examinationId = id;
    return this;
  }

  public getTop10Hazards(): Observable<QueryResult<Hazard>> {

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.examinationId) {
      throw Error('Examination Id not set. Use setExamination() first');
    }

    this.logger.debug('Getting Top10 hazards for examination {0} in machine {1} in project {2}', this.examinationId, this.machineId, this.projectId);

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'examinations',
      this.examinationId
    ].join('/') + '/Top10';

    return this.http.get<QueryResult<Hazard>>(url)
      .pipe(
        tap(result => {
          if (!result || !result.data || result.data.length === 0) {
            this.logger.debug('No hazards found');
            return result;
          }

          this.logger.debug('{0} hazards found', result.data.length);

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting hazards: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
