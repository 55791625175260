import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { CustomHeaderTemplate } from './custom-header.enum';
import { IGridColumn } from './grid-column.interface';
import { IGridConfig } from './grid-config.interface';
import { MinMaxColumns, SubGridUpdate } from './sub-grid-update';
import { SubGridComponent } from './sub-grid/sub-grid.component';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  @Input()
  public subGridColumns: IGridColumn[];

  @ViewChild('grid', { static: true })
  grid: DxDataGridComponent;

  @Input()
  config: IGridConfig;

  @Input()
  columns: IGridColumn[] = [];

  @Output()
  addRow = new EventEmitter<any>();

  @Output()
  updateRow = new EventEmitter<any>();

  @Output()
  deleteRow = new EventEmitter<any>();

  @Output()
  initNewRow = new EventEmitter<SubGridUpdate>();

  @Output()
  clickRow = new EventEmitter<any>();

  @Output()
  updateMinMaxColumns = new EventEmitter<SubGridUpdate>();

  @ViewChild('subgrid')
  private _subGrid: SubGridComponent;

  readonly customHeaderTemplate = CustomHeaderTemplate;
  readonly currentSort = { dataField: '', desc: false };
  nonEmptyColumns: Set<string> = new Set();

  minMaxHeaderValues: MinMaxColumns;

  private _dataSource: any[] = [];

  ngOnInit(): void {
    if (this.subGridColumns) {
      this.createGlobalMinMaxHeaderValues();
    }
  }

  onInitialized(): void {
    if (this.config.batchActions) {
      this.grid.instance.expandRow('0');
    }
  }

  get subGrid(): DxDataGridComponent {
    return this._subGrid.grid;
  }

  get dataSource(): any[] {
    return this._dataSource;
  }

  @Input()
  set dataSource(value: any[]) {
    this._dataSource = value;
    if (this.config.hideEmptyColumns) {
      this.nonEmptyColumns = value?.reduce((acc, curr) => new Set<string>(...acc, ...Object.keys(curr)), new Set<string>());
    }
  }

  handlePropertyChange(e: any) {
    this.grid.instance.endCustomLoading();
  }

  onAddRow(e: SubGridUpdate) {
    if (!this.config.batchActions) {
      this.grid.instance.beginCustomLoading('Loading...');
    }
    this.addRow.emit(e);
  }

  onInitNewRow(e: SubGridUpdate) {
    this.initNewRow.emit(e);
  }

  onUpdateRow(e: SubGridUpdate) {
    if (!this.config.batchActions) {
      this.grid.instance.beginCustomLoading('Loading...');
    }
    this.updateRow.emit(e);
  }

  onDeleteRow(e: SubGridUpdate) {
    if (!this.config.batchActions) {
      this.grid.instance.beginCustomLoading('Loading...');
    }
    this.deleteRow.emit(e);
  }

  onRowClick(e: any) {
    if (e.columnIndex !== 0) {
      this.clickRow.emit(e.data);
    }
  }

  toggleSort(e: any) {
    this.grid.instance.clearSorting();
    const desc = this.resolveSortOrder(e.column.dataField);
    // "-1" is required whenever the first column handles the expanding/collapsing of subgrids
    const offset = this.config.subGridEnabled ? -1 : 0;
    e.component.columnOption(e.columnIndex + offset, 'sortOrder', desc ? 'desc' : 'asc');
  }

  onUpdateMinMaxColumns(e: SubGridUpdate) {
    this.minMaxHeaderValues = e.minMaxColumns;
    if (!this.config.batchActions) {
      this.grid.instance.beginCustomLoading('Loading...');
    }
    this.updateMinMaxColumns.emit(e);
  }

  createMinMaxHeaderValues(subGridColumns: IGridColumn[]): MinMaxColumns {
    const minMaxHeaderValues = {};
    subGridColumns
      .filter((c) => c.customHeaderTemplate === CustomHeaderTemplate.minMax)
      .forEach((c) => {
        minMaxHeaderValues[c.dataField] = { min: c.min, max: c.max, definedByUser: c.definedByUser, label: c.captionKey };
      });
    return minMaxHeaderValues;
  }

  private resolveSortOrder(dataField: string): boolean {
    if (dataField !== this.currentSort.dataField) {
      this.currentSort.dataField = dataField;
      this.currentSort.desc = true;
    } else {
      this.currentSort.desc = !this.currentSort.desc;
    }
    return this.currentSort.desc;
  }

  private createGlobalMinMaxHeaderValues() {
    this.minMaxHeaderValues = {};
    this.subGridColumns
      .filter((c) => c.customHeaderTemplate === CustomHeaderTemplate.minMax)
      .forEach((c) => {
        this.minMaxHeaderValues[c.dataField] = { min: c.min, max: c.max, definedByUser: c.definedByUser, label: c.captionKey };
      });
    this.updateMinMaxColumns.emit({ minMaxColumns: this.minMaxHeaderValues });
  }
}
