
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { DocumentsProject } from '../model/documents-project';

@Injectable()
export class DocumentsProjectsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/documents';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('DocumentsProjectsService');
  }

  public getDocumentsProject(machineId: string, id: string): Observable<DocumentsProject> {
    this.logger.debug('Getting document project with id', id);

    let url = [
      this.apiUrl,
      machineId,
      'projects',
      id
    ].join('/');

    return this.http.get<DocumentsProject>(url)
      .pipe(
        tap(result => {
          if (!result) {
            this.logger.debug('No project found');
          }

          this.logger.debug('Documents project found');
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting documents project: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }
}
