import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { ControlIntegrityRating } from '../../machine-services/model/control-integrity-rating';

@Injectable()
export class MachineControlIntegritiesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('MachineControlIntegritiesService');
  }

  public setProject(projectId: string): MachineControlIntegritiesService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): MachineControlIntegritiesService {
    this.machineId = machineId;
    return this;
  }

  public getControlIntegrities(filter?: QueryFilter): Observable<QueryResult<ControlIntegrityRating>> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting control integrities for machine {0} from project {1}', this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'controlintegrities'].join('/');

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<ControlIntegrityRating>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No control integrities found');
          return result;
        }

        this.logger.debug('Control integrities found');
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting control integrities: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getControlIntegrity(id: string): Observable<ControlIntegrityRating> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting control integrity {0} for machine {1} from project {2}', id, this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'controlintegrities', id].join('/');

    return this.http.get<ControlIntegrityRating>(url).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No control integrity found');
          return result;
        }

        this.logger.debug('Control integrity found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting control integrities: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
