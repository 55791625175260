import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, type Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import type { QueryFilter } from '../../query-filter/model/QueryFilter';
import type { QueryResult } from '../../query-filter/model/QueryResult';
import { catchError, tap } from 'rxjs/operators';
import { MeasurementType } from '../../../projects-modules/machine-services/model/measurement-type';
import { ILogger, LoggerService } from '../../shared/logger.service';

@Injectable({
  providedIn: 'root'
})
export class MeasurementTypeService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/MeasurementType';

  private logger: ILogger;
  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('EquipmentListService');
  }

  public getMeasurementTypes(filter?: QueryFilter): Observable<QueryResult<MeasurementType>> {
    let url = [this.apiUrl].join('/');

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<MeasurementType>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No MeasurementType found');
          return result;
        }

        return result;
      }),
      catchError((err) => {
        console.error('Error getting MeasurementType: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }
}
