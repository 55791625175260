import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SortingModule } from './../sorting/sorting.module';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthModule } from '../../auth/auth.module';
import { ConfirmationModule } from '../confirmation/confirmation.module';
import { CoreModule } from '../core.module';
import { EditModalModule } from '../edit-modal/edit-modal.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { EquipmentEditComponent } from './equipment-list/equipment-edit/equipment-edit.component';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { FontSizeComponent } from './font-size/font-size.component';
import { LayoutComponent } from './layout/layout.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SyncComponent } from './sync/sync.component';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { UserProfileAutoSavePanelComponent } from './user-profile-auto-save-panel/user-profile-auto-save-panel.component';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { PaginationModule } from '../pagination/pagination.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule,
    AuthModule,
    NgbTooltipModule,
    SpinnerModule,
    NotificationsModule,
    ConfirmationModule,
    EditModalModule,
    SortingModule,
    HttpClientModule,
    FormsModule,
    DatePickerModule,
    FileUploadModule,
    PaginationModule
  ],
  declarations: [
    LayoutComponent,
    FontSizeComponent,
    PageNotFoundComponent,
    SyncComponent,
    NotificationsComponent,
    EquipmentListComponent,
    EquipmentEditComponent,
    UserProfileAutoSavePanelComponent
  ]
})
export class LayoutModule {}
