import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';

import { MachineDocument } from '../../../settings-modules/machine-documents/model/machine-document';
import { UploadSaveEvent } from '../../machine-services/model/upload-save-event';
import { ServerFile } from '../../../core-services/model/server-file';
import { ActionDocument } from '../../machine-services/model/action-document';
import { AuthService } from '../../../auth/shared/auth.service';

@Injectable()
export class InternalMachineHazardActionDocumentsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';

  private logger: ILogger;
  private machineId: string;
  private projectId: string;
  private hazardId: string;
  private actionId: string;

  constructor(private http: HttpClient, private authService: AuthService, logger: LoggerService) {
    this.http = http;
    this.logger = logger.getLogger('InternalMachineHazardActionDocumentsService');
  }

  public setProject(projectId: string): InternalMachineHazardActionDocumentsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalMachineHazardActionDocumentsService {
    this.machineId = machineId;
    return this;
  }

  public setHazard(hazardId: string): InternalMachineHazardActionDocumentsService {
    this.hazardId = hazardId;
    return this;
  }

  public setAction(actionId: string): InternalMachineHazardActionDocumentsService {
    this.actionId = actionId;
    return this;
  }

  public create(file: File): Observable<any> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Hazard Id not set. Use setHazard() first');
    }

    if (!this.actionId) {
      throw Error('Action Id not set. Use setAction() first');
    }

    this.logger.debug(
      'Creating document for hazard action {0} in project {1}, machine {2} and hazard {3}',
      this.actionId,
      this.projectId,
      this.machineId,
      this.hazardId
    );

    const method = 'POST';
    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'hazards',
      this.hazardId,
      'actions',
      this.actionId,
      'documents'
    ].join('/');

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .request<ActionDocument>(
        new HttpRequest(method, url, formData, {
          reportProgress: true
        })
      )
      .pipe(
        map((event) => {
          const saveEvent = new UploadSaveEvent();

          if (event.type === HttpEventType.UploadProgress) {
            saveEvent.progress = Math.round((100 * event.loaded) / event.total);
          } else if (event.type === HttpEventType.Response) {
            saveEvent.completed = true;

            if (event.body) {
              saveEvent.id = event.body['id'];
            }
          }
          return saveEvent;
        }),
        catchError((err, caught) => {
          this.logger.error('Error saving hazard action document: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }

  public update(actionDocument: ActionDocument): Observable<ActionDocument> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Hazard Id not set. Use setHazard() first');
    }

    if (!this.actionId) {
      throw Error('Action Id not set. Use setAction() first');
    }

    this.logger.debug(
      'Updating document {0} for hazard action {1} in project {2}, machine {3} and hazard {4}',
      actionDocument.id,
      this.actionId,
      this.projectId,
      this.machineId,
      this.hazardId
    );

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'hazards',
      this.hazardId,
      'actions',
      this.actionId,
      'documents',
      actionDocument.id
    ].join('/');

    return this.http
      .request<MachineDocument>('PUT', url, {
        body: actionDocument
      })
      .pipe(
        catchError((err, caught) => {
          this.logger.error('Error updating hazard action document: {0} - {1}', err['status'] || err['code'], err['message']);
          return throwError(err);
        })
      );
  }

  public download(): Observable<ServerFile>;
  public download(documents: Array<string>): Observable<ServerFile>;
  public download(documents?: Array<string>): Observable<ServerFile> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Hazard Id not set. Use setHazard() first');
    }

    if (!this.actionId) {
      throw Error('Action Id not set. Use setAction() first');
    }

    this.logger.debug(
      'Downloading documents for hazard action {0} in project {1}, machine {2} and hazard {3}',
      this.actionId,
      this.projectId,
      this.machineId,
      this.hazardId
    );

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'hazards',
      this.hazardId,
      'actions',
      this.actionId,
      'documents'
    ].join('/');

    if (documents) {
      url = `${url}?ids=${documents.join('&ids=')}`;
    }

    const httpOptions = {};
    httpOptions['responseType'] = 'blob';
    httpOptions['observe'] = 'response';

    return this.http.get<HttpResponse<Blob>>(url, httpOptions).pipe(
      tap((_) => {
        this.logger.debug('Documents downloaded');
      }),
      map((response) => {
        const disposition = response.headers.get('Content-Disposition');
        let filename = '';

        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        return {
          blob: response.body,
          name: filename
        };
      }),
      catchError((err, caught) => {
        this.logger.error('Error downloading hazard action documents: {0} - {1}', err['status'] || err['code'], err['message']);
        return throwError(err);
      })
    );
  }

  public getActionDocumentURL(doc: ActionDocument, size: string): Observable<string>;
  public getActionDocumentURL(doc: ActionDocument, size: string, extra: string): Observable<string>;
  public getActionDocumentURL(doc: ActionDocument, size: string, extra?: string): Observable<string> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Hazard Id not set. Use setHazard() first');
    }

    if (!this.actionId) {
      throw Error('Action Id not set. Use setAction() first');
    }

    this.logger.debug(
      'Getting documents url for hazard action {0} in project {1}, machine {2} and hazard {3}',
      this.actionId,
      this.projectId,
      this.machineId,
      this.hazardId
    );

    const url =
      [
        this.apiUrl,
        this.projectId,
        'projectmachines',
        this.machineId,
        'hazards',
        this.hazardId,
        'actions',
        this.actionId,
        'documents',
        doc.id
      ].join('/') +
      '?size=' +
      size +
      (extra ? '&' + extra : '');

    return this.http.get(url, { responseType: 'blob' }).pipe(map((blob) => window.URL.createObjectURL(blob)));
  }

  public getActionDocumentPreviewURL(doc: ActionDocument, size: string): Observable<string>;
  public getActionDocumentPreviewURL(doc: ActionDocument, size: string, extra: string): Observable<string>;
  public getActionDocumentPreviewURL(doc: ActionDocument, size: string, extra?: string): Observable<string> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Hazard Id not set. Use setHazard() first');
    }

    if (!this.actionId) {
      throw Error('Action Id not set. Use setAction() first');
    }

    this.logger.debug(
      'Getting documents preview url for hazard action {0} in project {1}, machine {2} and hazard {3}',
      this.actionId,
      this.projectId,
      this.machineId,
      this.hazardId
    );

    const url =
      [
        this.apiUrl,
        this.projectId,
        'projectmachines',
        this.machineId,
        'hazards',
        this.hazardId,
        'actions',
        this.actionId,
        'documents',
        doc.id,
        'preview'
      ].join('/') +
      '?size=' +
      size +
      (extra ? '&' + extra : '');

    return this.http.get(url, { responseType: 'blob' }).pipe(map((blob) => window.URL.createObjectURL(blob)));
  }
}
