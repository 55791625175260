import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { Hazard } from '../model/hazard';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { AssessmentType } from '../../assessment-types/model/AssessmentType';
import _ from 'lodash';

@Injectable()
export class HazardsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/hazards';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('HazardsService');
  }

  public getHazards(filter?: QueryFilter): Observable<QueryResult<Hazard>> {
    this.logger.debug('Getting hazards');

    let url = this.apiUrl;

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<Hazard>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No hazards found');
          return result;
        }

        this.logger.debug('{0} hazards found', result.data.length);

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting hazards: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getHazard(id: string): Observable<Hazard> {
    this.logger.debug('Getting hazard with id', id);

    if (!id) {
      this.logger.error('Hazard id is null, returning null');
      return of(null);
    }

    return this.http.get<Hazard>(this.apiUrl + '/' + id).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No hazard found');
          return result;
        }

        this.logger.debug('Hazard found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting hazard: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  /** Checks, if all measurements of assesmet type have the same default hazard type as specified */
  public hazardTypeMatch(assesmentType: AssessmentType, hazardType: string): Observable<boolean> {
    const defaultHazardIds = _.uniq(
      (assesmentType.checklists ?? [])
        .flatMap((c) => c.sections)
        .flatMap((s) => s.examinations)
        .map((e) => e.measurement?.defaultHazard?.id)
    ).filter((id) => id);
    if (defaultHazardIds.length > 0) {
      return this.getHazards(new QueryFilter().containedIn('id', defaultHazardIds)).pipe(
        map((hazards) => {
          if (hazards.data.some((hazard) => hazard.type !== hazardType)) {
            return false;
          } else {
            return true;
          }
        })
      );
    }
    return of(true);
  }
}
