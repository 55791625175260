import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edit-modal-header',
  templateUrl: './edit-modal-header.component.html',
  styleUrls: ['./edit-modal-header.component.scss']
})
export class EditModalHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
