import { IGridConfig } from './grid-config.interface';

export class GridConfig implements IGridConfig {
  readonly keyExpr: string;
  readonly sortingEnabled: boolean = false;
  readonly gridActionsEnabled: boolean = false;
  readonly hideEmptyColumns?: boolean = false;
  readonly subGridEnabled: boolean = false;
  readonly subGridDataSourceKey: string;
  readonly subGridConfigKey: string;
  readonly subGridColumnsKey: string;
  readonly batchActions: boolean;
  static create(params: IGridConfig): GridConfig {
    return { ...new GridConfig(), ...params };
  }
}
