import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsInternalEngineerRoleGuard implements CanActivate {
  private logger: ILogger;

  constructor(private router: Router, private authService: AuthService, logger: LoggerService) {
    this.logger = logger.getLogger('IsAdministratorGuardService');
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isEngineer() || this.authService.isPowerEngineer()) {
      this.logger.debug('Is Internal. Can activate.');
      return true;
    } else {
      this.logger.debug('Permission denied. Cannot activate. Redirecting to start.');
      this.router.navigate(['start']); //TODO check where this redirects to later
      return false;
    }
  }
}
