import { Component } from '@angular/core';
import Calendar, { Properties } from 'devextreme/ui/calendar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {
    Calendar.defaultOptions<Properties>({
      device: { deviceType: 'desktop' },
      options: {
        firstDayOfWeek: 1
      }
    });
  }
}
