
<ng-template #dateDay let-date="date" let-focused="focused" let-currentMonth="currentMonth">
  <span class="custom-day" 
    [class.faded]="isSelected(date)"
    [class.other-month]="currentMonth !== date.month"
    [class.today]="isToday(date)">
    {{ date.day }}
  </span>
</ng-template>

<div class="input-group date-picker">
  <input class="form-control form-control-readonly date" [class.ng-invalid-alt]="isInvalid()" [class.ng-dirty]="isDirty()" [ngModel]="formattedDate" readonly #datePickerPopover="ngbPopover" [ngbPopover]="datePickerPopoverTemplate" [autoClose]="'outside'" popoverTitle="Not used" [placement]="['bottom', 'top']" [disabled]="disabled">

  <ng-template #datePickerPopoverTemplate>
    <ngb-datepicker #datePicker [minDate]="minDate" [displayMonths]="1" [dayTemplate]="dateDay" [startDate]="selectedStruct" [footerTemplate]="footerTemplate" navigation="select" (dateSelect)="onDateSelection($event)">
    </ngb-datepicker>
  </ng-template>

  <ng-template #footerTemplate>
    <hr class="my-0">
    <button *ngIf="showTodayButton" class="btn btn-outline-primary btn-sm m-2 float-left" (click)="setTodayDate();">{{ 'datePicker.today' | translate }}</button>
    <button class="btn btn-outline-primary btn-sm m-2 float-right" (click)="clear();">{{ 'datePicker.clear' | translate }}</button>
  </ng-template>

  <div class="input-group-append" *ngIf="!disabled">
    <button type="button" class="btn btn-outline-secondary" (click)="datePickerPopover.toggle()">
      <i class="material-icons">calendar_today</i>
    </button>
  </div>
</div>