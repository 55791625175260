import { Injectable } from '@angular/core';
import ObjectID from 'bson-objectid';

@Injectable()
export class NonceService {

  private readonly storageKey: string = 'mcomplus/auth/nonce';

  constructor() { }

  public generate(): string;
  public generate(type: string): string;
  public generate(type?: string): string {
    const nonce = ObjectID.generate();
    
    if (type) {
      const key = [this.storageKey, type].join('/');
      this.store(key, nonce);
    }

    return nonce;
  }

  public getNonce(type: string): string {
    if (!type) {
      return;
    }

    const key = [this.storageKey, type].join('/');
    const nonce = this.read(key);

    this.delete(key);

    return nonce;
  }

  private store(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  private read(key: string): string {
    return sessionStorage.getItem(key);
  }

  private delete(key: string): void {
    sessionStorage.removeItem(key);
  }
}
