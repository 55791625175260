import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { environment } from '../../../../environments/environment';

import { MachineDocument } from '../model/machine-document';
import { UploadSaveEvent } from '../../../projects-modules/machine-services/model/upload-save-event';
import { ServerFile } from '../../../core-services/model/server-file';
import { AuthService } from '../../../auth/shared/auth.service';

@Injectable()
export class InternalMachineDocumentsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/machines';

  private logger: ILogger;
  private machineId: string;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.http = http;
    this.logger = logger.getLogger("InternalMachineDocumentsService");
  }

  public setMachine(machineId: string): InternalMachineDocumentsService {
    this.machineId = machineId;
    return this;
  }
  
  public create(file: File): Observable<any> {
    if (!this.machineId) {
      throw Error('Machine Id not set. Use setMachine() first');
    }

    this.logger.debug('Creating document for machine {0}', this.machineId);

    const method = 'POST';
    let url = [
      this.apiUrl,
      this.machineId,
      'documents'
    ].join('/');

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.request<MachineDocument>(new HttpRequest(method, url, formData, {
      reportProgress: true
    })).pipe(
      map(event => {
        const saveEvent = new UploadSaveEvent();

        if (event.type === HttpEventType.UploadProgress) {
          saveEvent.progress = Math.round(100 * event.loaded / event.total);
        } else if (event.type === HttpEventType.Response) {
          saveEvent.completed = true;

          if (event.body) {
            saveEvent.id = event.body['id'];
          }
        }
        return saveEvent;
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving machine document: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }

  public update(machineDocument: MachineDocument): Observable<MachineDocument> {
    if (!this.machineId) {
      throw Error('Machine Id not set. Use setMachine() first');
    }

    this.logger.debug('Creating document for machine {0}', this.machineId);

    let url = [
      this.apiUrl,
      this.machineId,
      'documents',
      machineDocument.id
    ].join('/');

    return this.http.request<MachineDocument>('PUT', url, {
      body: machineDocument
    }).pipe(
      catchError((err, caught) => {
        this.logger.error('Error updating machine document: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }

  public getMachineDocumentURL(doc: MachineDocument): Observable<string> {

    if (!this.machineId) {
      throw Error('Machine Id not set. Use setMachine() first');
    }

    this.logger.debug('Getting document {0} url for machine {1}', doc.id, this.machineId);

    const url = [
      this.apiUrl,
      this.machineId,
      'documents',
      doc.id
    ].join('/');

    return this.http.get(url, { responseType: 'blob' }).pipe(map((blob) => window.URL.createObjectURL(blob)));
  }

  public getMachineDocumentPreviewURL(doc: MachineDocument, size: string, extra?: string): Observable<string> {

    if (!this.machineId) {
      throw Error('Machine Id not set. Use setMachine() first');
    }

    this.logger.debug('Getting document {0} url for machine {1}', doc.id, this.machineId);

    const url = [
      this.apiUrl,
      this.machineId,
      'documents',
      doc.id,
      'preview'
    ].join('/') + '?size=' + size + (extra ? '&' + extra : '');

    return this.http.get(url, { responseType: 'blob' }).pipe(map((blob) => window.URL.createObjectURL(blob)));
  }

  public download(): Observable<ServerFile>;
  public download(documents: Array<string>): Observable<ServerFile>;
  public download(documents?: Array<string>): Observable<ServerFile> {

    if (!this.machineId) {
      throw Error('Machine Id not set. Use setMachine() first');
    }

    this.logger.debug('Downloading all documents for machine with id {0} ', this.machineId);

    let url: string = [
      this.apiUrl,
      this.machineId,
      'documents'
    ].join('/');

    if (documents) {
      url = `${url}?ids=${documents.join('&ids=')}`;
    }

    const httpOptions = {};
    httpOptions['responseType'] = 'blob';
    httpOptions['observe'] = 'response';

    return this.http.get<HttpResponse<Blob>>(url, httpOptions).pipe(
      tap(_ => {
        this.logger.debug('Documents downloaded');
      }),
      map(response => {
        const disposition = response.headers.get('Content-Disposition');
        let filename = '';

        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        return {
          blob: response.body,
          name: filename
        };
      }),
      catchError((err, caught) => {
        this.logger.error('Error downloading documents: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }

  public delete(documents: Array<string>): Observable<void> {

    if (!this.machineId) {
      throw Error('Machine Id not set. Use setMachine() first');
    }

    this.logger.debug('Deleting {0} machine documents in machine {1}', documents ? documents.length : 0, this.machineId);

    let url = [
      this.apiUrl,
      this.machineId,
      'documents'
    ].join('/');

    return this.http.request<void>('DELETE', url, {
      body: documents
    })
      .pipe(
        tap(() => {
          this.logger.debug('Machine documents deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting machine documents: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }

  public archive(documents: Array<string>): Observable<void> {

    if (!this.machineId) {
      throw Error('Machine Id not set. Use setMachine() first');
    }

    this.logger.debug('Archiving {0} machine documents in machine {1}', documents ? documents.length : 0, this.machineId);

    let url = [
      this.apiUrl,
      this.machineId,
      'documents',
      'archive'
    ].join('/');

    return this.http.request<void>('POST', url, {
      body: documents
    })
      .pipe(
        tap(() => {
          this.logger.debug('Machine documents archived');
        }),
        catchError((err, caught) => {
          this.logger.error('Error archiving machine documents: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }
}
