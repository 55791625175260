import BaseAppender from 'log4ts/build/appenders/BaseAppender';
import { IAppender } from 'log4ts/build/IAppender';
import { LogEntry } from 'log4ts/build/LogEntry';
import { AppAnalytics } from '../shared/analytics';
import { LogLevel } from 'log4ts/build/LogLevel';

export class AnalyticsAppender extends BaseAppender implements IAppender {
  append(entry: LogEntry) {
    switch (entry?.level) {
      case LogLevel.FATAL:
      case LogLevel.ERROR:
        AppAnalytics.error(new Error(entry.message), JSON.parse(JSON.stringify(this.tryStripHtml(entry))));
        break;
      default:
        AppAnalytics.event('log', JSON.parse(JSON.stringify(entry)));
        break;
    }
  }

  clear() {
    //do nothing
  }

  private tryStripHtml(entry: LogEntry): LogEntry {
    try {
      if ((entry.level === LogLevel.ERROR || entry.level === LogLevel.FATAL) && entry.message?.includes('<!DOCTYPE html>')) {
        // too long message becasue of base64 encoded background image (log text is throttled and valuable information lost if unmanaged)
        const start = entry.message.indexOf('<!DOCTYPE html>');
        const end = entry.message.indexOf('</html>', start);
        const html = entry.message.substring(start, end + 8);
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(html, 'text/html');
        const styleElements = htmlDoc.body.getElementsByTagName('style');
        if (styleElements.length) {
          htmlDoc.body.removeChild(styleElements[0]);
        }
        htmlDoc.body.removeAttribute('style');
        entry.message = entry.message.replace(html, htmlDoc.body.outerHTML);
      }
    } catch {}
    return entry;
  }
}
