import { Component, Injector, Input, OnInit, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { NgbDateStruct, NgbDatepicker, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatedPipe } from '../../pipe/dateFormated.pipe';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  @Input() minDate: NgbDateStruct; // format is like { year: 1900, month: 1, day: 1 };
  @Input() showTodayButton = true;

  @ViewChild('datePickerPopover', { static: true })
  public datePickerPopover: NgbPopover;

  @ViewChild('datePicker')
  public datePicker: NgbDatepicker;

  public formattedDate: string;
  public disabled = false;

  private control: NgControl;
  private selectedStruct: NgbDateStruct;
  private selectedDate: Date;

  constructor(private injector: Injector, private datePipe: DateFormatedPipe) {}

  ngOnInit() {}

  public onDateSelection(struct: NgbDateStruct): void {
    this.selectedStruct = struct;
    this.datePickerPopover.close();

    if (this.selectedStruct) {
      this.selectedDate = new Date(this.selectedStruct.year, this.selectedStruct.month - 1, this.selectedStruct.day);
    } else {
      this.selectedDate = null;
    }

    this.formatDate();
    this.propagateChange && this.propagateChange(this.selectedDate);
  }

  public setTodayDate(): void {
    this.selectedDate = new Date();
    this.selectedStruct = {
      year: this.selectedDate.getFullYear(),
      month: this.selectedDate.getMonth() + 1,
      day: this.selectedDate.getDate()
    };

    this.datePicker && this.datePicker.navigateTo(this.selectedStruct);

    this.formatDate();
    this.propagateChange && this.propagateChange(this.selectedDate);
  }

  public clear(): void {
    this.selectedDate = null;
    this.selectedStruct = null;
    this.formattedDate = null;

    this.propagateChange && this.propagateChange(this.selectedDate);
  }

  public writeValue(obj: any): void {
    this.control = this.injector.get(NgControl);

    if (obj) {
      this.selectedDate = new Date(obj);
      this.selectedStruct = {
        year: this.selectedDate.getFullYear(),
        month: this.selectedDate.getMonth() + 1,
        day: this.selectedDate.getDate()
      };

      this.formatDate();
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public isInvalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  public isDirty(): boolean {
    return this.control ? this.control.dirty : false;
  }

  public isToday(date: NgbDateStruct): boolean {
    const now = new Date();
    return equals(date, { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() });
  }

  public isSelected(date: NgbDateStruct): boolean {
    return equals(date, this.selectedStruct);
  }

  public registerOnTouched(fn: any): void {
    //nothing
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private formatDate(): void {
    if (this.selectedDate) {
      this.formattedDate = this.datePipe.transform(this.selectedDate);
    }
  }

  private propagateChange = (_: any) => {};
}
