import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QueryFilter } from '../../query-filter/model/QueryFilter';

@Component({
  selector: 'table-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss']
})
export class SortingComponent implements OnInit {

  @Input()
  public filter: QueryFilter;

  @Input()
  public property: string;

  @Input()
  public isNullable: boolean;

  @Output()
  public sort: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public ngOnInit(): void { }

  public toggle(): void {
    const isAscending = this.isArrowUp();

    this.filter.clearOrder();

    isAscending ? this.filter.descending(this.getProperty()) : this.filter.ascending(this.getProperty());
    
    this.sort.emit();
  }

  public isArrowActive(): boolean {
    return this.filter && this.filter.ordersArray()
      && !!this.filter.ordersArray().find(order => order === this.getProperty() || order === ('-' + this.getProperty()));
  }

  public isArrowUp(): boolean {
    return this.filter && this.filter.ordersArray()
      && !!this.filter.ordersArray().find(order => order === this.getProperty());
  }

  public isArrowDown(): boolean {
    return this.filter && this.filter.ordersArray()
      && !!this.filter.ordersArray().find(order => order === ('-' + this.getProperty()));
  }

  private getProperty(): string {
    return `${this.isNullable === true ? '?' : ''}${this.property}`;
  }
}
