<div *ngIf="show">
  <edit-modal size="full-screen" (onHide)="cancel()">
    <edit-modal-header>
      <div class="row align-items-center table-header">
        <div class="col text-left">
          <button type="button" class="close btn-close" (click)="cancel()">
            <span class="material-icons icon-2x">close</span>
          </button>
        </div>
      </div>
    </edit-modal-header>

    <edit-modal-content>
      <div class="mb-5">
        <ng-content></ng-content>
      </div>
    </edit-modal-content>
  </edit-modal>
</div>
