import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { GalleryPhotoDto } from '../model/gallery-photo-dto';
import { Photo } from '../model/photo';
import { UploadSaveEvent } from '../model/upload-save-event';

@Injectable()
export class ManageHazardPhotosService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;
  private hazardId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('ManageHazardPhotosService');
  }

  public setProject(projectId: string): ManageHazardPhotosService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): ManageHazardPhotosService {
    this.machineId = machineId;
    return this;
  }

  public setHazard(hazardId: string): ManageHazardPhotosService {
    this.hazardId = hazardId;
    return this;
  }

  public create(file: File): Observable<UploadSaveEvent> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Project Machine Hazard Id not set. Use setHazard() first');
    }

    this.logger.debug('Creating photo for hazard {0} of machine {1} from project {2}', this.hazardId, this.machineId, this.projectId);

    const method = 'POST';
    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards', this.hazardId, 'photos'].join('/');

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .request<Photo>(
        new HttpRequest(method, url, formData, {
          reportProgress: true
        })
      )
      .pipe(
        map((event) => {
          const saveEvent = new UploadSaveEvent();

          if (event.type === HttpEventType.UploadProgress) {
            saveEvent.progress = Math.round((100 * event.loaded) / event.total);
          } else if (event.type === HttpEventType.Response) {
            saveEvent.completed = true;

            if (event.body) {
              saveEvent.id = event.body['id'];
            }
          }

          return saveEvent;
        }),
        catchError((err, caught) => {
          this.logger.error('Error saving hazard photo: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public update(photo: Photo, fileBlob?: Blob, originalFileBlob?: Blob): Observable<Photo> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Project Machine Hazard Id not set. Use setHazard() first');
    }

    this.logger.debug('Creating photo for hazard {0} of machine {1} from project {2}', this.hazardId, this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards', this.hazardId, 'photos', photo.id].join('/');

    const formData: FormData = new FormData();
    formData.append('photo', JSON.stringify(photo));

    if (fileBlob) {
      fileBlob['lastModified'] = Date.now().valueOf();
      fileBlob['name'] = photo.name;

      const file = <File>fileBlob;
      formData.append('file', file, file.name);
    }

    if (originalFileBlob) {
      originalFileBlob['lastModified'] = Date.now().valueOf();
      originalFileBlob['name'] = photo.name;

      const originalFile = <File>originalFileBlob;
      formData.append('originalFile', originalFile, originalFile.name);
    }

    return this.http
      .request<Photo>('PUT', url, {
        body: formData
      })
      .pipe(
        catchError((err, caught) => {
          this.logger.error('Error saving hazard photo: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public delete(photos: Array<string>): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Project Machine Hazard Id not set. Use setHazard() first');
    }

    this.logger.debug('Deleting {0} hazard photos', photos.length);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards', this.hazardId, 'photos'].join('/');

    return this.http
      .request<void>('DELETE', url, {
        body: photos
      })
      .pipe(
        tap(() => {
          this.logger.debug('Hazard photos deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting hazard photos: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public deleteBulk(hazardPhotoPairs: { [key: string]: Array<string> }): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Deleting {0} hazard photos', Object.keys(hazardPhotoPairs).length);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'hazards', 'photos'].join('/');

    return this.http
      .request<void>('DELETE', url, {
        body: hazardPhotoPairs
      })
      .pipe(
        tap(() => {
          this.logger.debug('Hazard photos deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting hazard photos: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public copyTo(otherProjectId: string, otherProjectMachineId: string, otherHazardId: string): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Project Machine Hazard Id not set. Use setHazard() first');
    }

    this.logger.debug(
      'Copying photo from hazard {0} of machine {1} from project {2} to hazard {3} and machine {4} and projec {5}',
      this.hazardId,
      this.machineId,
      this.projectId,
      otherHazardId,
      otherProjectMachineId,
      otherProjectId
    );

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'hazards',
      this.hazardId,
      'photos',
      'copyto',
      otherProjectId,
      otherProjectMachineId,
      otherHazardId
    ].join('/');

    return this.http.post<void>(url, 'empty body').pipe(
      tap((_) => {
        this.logger.debug('Hazard photos copied');
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving hazard photo: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public copyToBulk(otherProjectId: string, otherProjectMachineId: string, hazardsPairs: { [key: string]: string }): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug(
      'Copying {0} hazards photos of machine {1} from project {2} to machine {3} and project {4}',
      Object.keys(hazardsPairs).length,
      this.machineId,
      this.projectId,
      otherProjectMachineId,
      otherProjectId
    );

    let url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'hazards',
      'photos',
      'copyto',
      otherProjectId,
      otherProjectMachineId
    ].join('/');

    return this.http.post<void>(url, hazardsPairs).pipe(
      tap((_) => {
        this.logger.debug('Hazards photos copied');
      }),
      catchError((err, caught) => {
        this.logger.error('Error saving hazards photos: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public updateGallery(photos: Array<GalleryPhotoDto>): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    if (!this.hazardId) {
      throw Error('Project Machine Hazard Id not set. Use setHazard() first');
    }

    this.logger.debug('Updating {0} machine photos', photos.length);

    //end point:api/v{version}/projects/{projectId}/ProjectMachines/{machineId}/Hazards/{id}/Gallery
    let url = [this.apiUrl, this.projectId, 'ProjectMachines', this.machineId, 'Hazards', this.hazardId, 'Gallery'].join('/'); 

    this.logger.debug('url: {0}', url);

    return this.http
      .request<void>('PUT', url, {
        body: photos
      })
      .pipe(
        tap(() => {
          this.logger.debug('Gallery photos updated');
        }),
        catchError((err, caught) => {
          console.log(caught);
          this.logger.error('Error updating Gallery photos: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }

}
