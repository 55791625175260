import { Injectable, ErrorHandler } from '@angular/core';
import { AppAnalytics } from '../logging/shared/analytics';

@Injectable()
export class AnalyticsErrorHandlerService extends ErrorHandler {
  constructor() {
    super();
  }

  handleError(error: any): void {
    if (error?.code !== '404') {
      AppAnalytics.error(error);
    }

    super.handleError(error);
  }
}
