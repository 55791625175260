import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../notifications/shared/notifications.service';

@Directive({
  selector: 'input[type="file"][ngFileSelect]'
})
export class FileSelectDirective {
  @Input() public allowedMimeTypes: string[] = [];
  @Output() public filesSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

  private element: ElementRef;

  public constructor(element: ElementRef, private notificationsService: NotificationsService, private translateService: TranslateService) {
    this.element = element;
  }

  @HostListener('change')
  public selected(): any {
    const files = this.element.nativeElement.files;
    let allowedFiles: File[] = [];
    const preventedFiles: File[] = [];

    if (this.allowedMimeTypes && this.allowedMimeTypes.length > 0) {
      for (const file of files) {
        const fileExtension = ('.' + file.name.split('.').pop()).toLowerCase();
        if (this.allowedMimeTypes.indexOf(fileExtension) > -1) {
          allowedFiles.push(file);
        } else {
          preventedFiles.push(file);
        }
      }
    } else {
      allowedFiles = files;
    }

    if (allowedFiles.length > 0) {
      if (this.filesSelected) {
        this.filesSelected.emit(allowedFiles);
      }

      allowedFiles = [];

    } else {
      const details = preventedFiles.map((file) => file.name).join(', ');
      this.notificationsService.danger(this.translateService.instant('file-upload.notification.error-type-not-allowed'), details);
    }

    if (this.isEmptyAfterSelection()) {
      this.element.nativeElement.value = '';
    }
  }

  private isEmptyAfterSelection(): boolean {
    return !!this.element.nativeElement.attributes.multiple;
  }
}
