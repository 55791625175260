import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpinnerComponent } from './spinner/spinner.component';
import { ButtonSpinnerComponent } from './button-spinner/button-spinner.component';
import { PageSpinnerComponent } from './page-spinner/page-spinner.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SpinnerComponent,
    ButtonSpinnerComponent,
    PageSpinnerComponent
  ],
  providers: [
  ],
  exports: [
    SpinnerComponent,
    ButtonSpinnerComponent,
    PageSpinnerComponent
  ]
})
export class SpinnerModule { }