import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'calendar'
})
export class CalendarPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const date = moment(value);

    if (!date.isValid()) {
      return args && args.length > 0 && args[0] || value;
    }

    return date.calendar();
  }

}
