import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ServerFile } from '../../core-services/model/server-file';
import { ILogger, LoggerService } from '../../core/shared/logger.service';

@Injectable()
export class InternalCompanyImplementationsReportsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/implementations';
  private logger: ILogger;
  private companyId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalImplementationsReportsService');
  }

  public setCompany(companyId: string): InternalCompanyImplementationsReportsService {
    this.companyId = companyId;
    return this;
  }

  public generate(reportType: string, reportDefinition: any): Observable<ServerFile> {
    if (!this.companyId) {
      throw Error('Company Id not set. Use setCompany() first');
    }

    this.logger.debug('Generating report for company', this.companyId);

    const url = [this.apiUrl, this.companyId, 'reports', reportType].join('/');

    const httpOptions = {};
    httpOptions['responseType'] = 'blob';
    httpOptions['observe'] = 'response';

    return this.http.post<HttpResponse<Blob>>(url, reportDefinition, httpOptions).pipe(
      tap((_) => {
        this.logger.debug('Report generated');
      }),
      map((response) => {
        const disposition = response.headers.get('Content-Disposition');
        let filename = '';

        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        return {
          blob: response.body,
          name: filename
        };
      }),
      catchError((err, caught) => {
        this.logger.error('Error generating report: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
