import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILogger, LoggerService } from '../../core/shared/logger.service';
import { UserNotification } from '../model/user-notification';
import { UserNotificationHandler } from './user-notification-handler';

@Injectable({
  providedIn: 'root'
})
export class ProjectDocumentHandlerService implements UserNotificationHandler {

  private logger: ILogger;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('ProjectDocumentHandlerService');
  }

  public getNotificationTypes(): Array<{ id: string, label: string }> {
    const result = [
      { id: 'projectDocument_initial', label: '' },
      { id: 'projectDocument_in_review', label: '' },
      { id: 'projectDocument_rejected', label: '' },
      { id: 'projectDocument_reviewed', label: '' }
    ];

    result.forEach(item => {
      const state = item.id
        .split('_')
        .slice(1)
        .join('_');

      item.label =
        `${this.translateService.instant('user-notifications.handler.project-document.title')} ${this.translateService.instant('user-notifications.handler.project-document.' + state)}`;
    });

    return result;
  }

  formatTitle(notification: UserNotification): string {
    this.logger.debug('Formating notification title with id', notification.id);

    if (!notification || !notification.type) {
      this.logger.debug('Notification is null, skipping');
      return null;
    }

    const state = notification.type
      .split('_')
      .slice(1)
      .join('_');

    return `${this.translateService.instant('user-notifications.handler.project-document.title')} ${this.translateService.instant('user-notifications.handler.project-document.' + state)}`;
  }

  formatDescription(notification: UserNotification): string {
    this.logger.debug('Formating notification with id', notification.id);

    if (!notification || !notification.type || !notification.additionalInfo) {
      this.logger.debug('Notification is null, skipping');
      return null;
    }

    const project = notification.additionalInfo['project'] && notification.additionalInfo['project'].name || 'Unknown';
    const documentName = notification.additionalInfo['projectDocument'] && notification.additionalInfo['projectDocument'].name || 'Unknown';

    return `Project: ${project} | Document: ${documentName}`;
  }

  navigate(notification: UserNotification): Observable<void> {
    this.logger.debug('Navigating to user notification with id', notification.id);

    if (!notification || !notification.type || !notification.additionalInfo) {
      this.logger.debug('Notification is null, skipping');
      return null;
    }

    const projectId = notification.additionalInfo['project'] && notification.additionalInfo['project'].id || null;
    const documentId = notification.additionalInfo['machineDocument'] && notification.additionalInfo['machineDocument'].id || null;

    const route = ['assessments', projectId, 'documents', documentId, 'review'];

    return from(this.router.navigate(route))
      .pipe(
        map(_ => null)
      );
  }

}
