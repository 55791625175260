import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { AssessmentType } from '../model/AssessmentType';
import { ServerFile } from '../../../core-services/model/server-file';

@Injectable()
export class AssessmentTypesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/assessmenttypes';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('AssessmentTypesService');
  }

  public getAssessmentTypes(filter?: QueryFilter): Observable<QueryResult<AssessmentType>> {
    this.logger.debug('Getting assessment types');

    let url = this.apiUrl;

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<AssessmentType>>(url).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No assessment types found');
          return result;
        }

        this.logger.debug('{0} assessment types found', result.data.length);

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting assessment types: {0} - {1}', err['status'] || err['code'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getAssessmentType(id: string): Observable<AssessmentType> {
    this.logger.debug('Getting assessment type with id', id);

    return this.http.get<AssessmentType>(this.apiUrl + '/' + id).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No assessment type found');
          return result;
        }

        this.logger.debug('Assessment type found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting assessment type: {0} - {1}', err['status'] || err['code'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public generate(assessmentId: string): Observable<ServerFile> {
    const url = [this.apiUrl, assessmentId, 'export'].join('/');

    const httpOptions = {};
    httpOptions['responseType'] = 'blob';
    httpOptions['observe'] = 'response';

    return this.http.get<HttpResponse<Blob>>(url, httpOptions).pipe(
      tap((_) => {
        this.logger.debug('File generated');
      }),
      map((response) => {
        const disposition = response.headers.get('Content-Disposition');
        let filename = '';

        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        return {
          blob: response.body,
          name: filename
        };
      }),
      catchError((err, caught) => {
        this.logger.error('Error generating assessment type: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
