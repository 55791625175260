
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { Company } from '../model/company';

@Injectable()
export class CompaniesService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/companies';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('CompaniesService');
  }

  public getCompanies(filter?: QueryFilter): Observable<QueryResult<Company>> {
    this.logger.debug('Getting companies');

    let url = this.apiUrl;
    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<Company>>(url)
      .pipe(
        tap(result => {
          if (!result || !result.data || result.data.length === 0) {
            this.logger.debug('No company found');
            return result;
          }

          this.logger.debug('{0} companies found', result.data.length);

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting companies: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public getCompany(id: string): Observable<Company> {
    this.logger.debug('Getting company with id', id);

    if (!id) {
      this.logger.error('Company id is null, returning null');
      return of(null);
    }

    return this.http.get<Company>(this.apiUrl + '/' + id)
      .pipe(
        tap(result => {
          if (!result) {
            this.logger.debug('No company found');
            return result;
          }

          this.logger.debug('Company found');

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting company: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }
}
