
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MachineHazard } from '../../machine-services/model/machine-hazard';

@Injectable()
export class InternalMachineHazardsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private projectId: string;
  private machineId: string;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('InternalMachineHazardsService');
  }

  public setProject(projectId: string): InternalMachineHazardsService {
    this.projectId = projectId;
    return this;
  }

  public setProjectMachine(machineId: string): InternalMachineHazardsService {
    this.machineId = machineId;
    return this;
  }

  public delete(hazard: MachineHazard): Observable<void> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Deleting machine hazard {0} for project {1} and machine {2}', hazard.id, this.projectId, this.machineId);

    const url = [
      this.apiUrl,
      this.projectId,
      'projectmachines',
      this.machineId,
      'hazards',
      hazard.id
    ].join('/');

    return this.http.delete<void>(url)
      .pipe(
        tap(result => {
          this.logger.debug('Machine hazard deleted');
        }),
        catchError((err, caught) => {
          this.logger.error('Error deleting machine hazard: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

}
