
<ng-template #dateRangeDay let-date="date" let-focused="focused" let-currentMonth="currentMonth">
  <span class="custom-day" 
    [class.focused]="focused"
    [class.other-month]="currentMonth !== date.month"
    [class.today]="isToday(date)" 
    [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
    [class.faded]="isHovered(date) || isInside(date)">
    {{ date.day }}
  </span>
</ng-template>

<div class="input-group date-range-picker">
  <input class="form-control date-range" [ngModel]="formattedDate" readonly #datePickerPopover="ngbPopover" [ngbPopover]="datePickerPopoverTemplate" popoverTitle="Not used" [placement]="['bottom', 'top']" [autoClose]="'outside'">

  <ng-template #datePickerPopoverTemplate>
    <ngb-datepicker [displayMonths]="1" [dayTemplate]="dateRangeDay" navigation="select" (dateSelect)="onDateSelection($event)">
    </ngb-datepicker>
  </ng-template>

  <div class="input-group-append">
    <button type="button" class="btn btn-outline-secondary" (click)="datePickerPopover.toggle()">
      <i class="material-icons">calendar_today</i>
    </button>
  </div>
</div>