import { IGridColumn } from './grid-column.interface';

export class GridColumn implements IGridColumn {
  readonly dataField: string;
  readonly captionKey: string;
  readonly dataType: string = 'string';
  readonly alignment: string = 'left';
  readonly format: string;
  readonly allowEditing: boolean;
  readonly required: boolean;
  readonly min: number;
  readonly max: number;
  readonly definedByUser: boolean;
  static create(params: IGridColumn): GridColumn {
    return { ...new GridColumn(), ...params };
  }
}
