import { Injectable } from '@angular/core';
import { LoggerService, ILogger } from './logger.service';
import { Observable, Subject } from 'rxjs';

export enum Breakpoint {
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL
}

@Injectable()
export class ResponsiveService {

  private readonly breakpoints: { [key: string]: string } = {
    xs: '(max-width:575px)',
    sm: '(min-width:576px) and (max-width:767px)',
    md: '(min-width:768px) and (max-width:991px)',
    lg: '(min-width:992px) and (max-width:1280px)',
    xl: '(min-width:1281px) and (max-width:1399px)',
    xxl: '(min-width:1400px)'
  };
  private logger: ILogger;
  private resizedSubject: Subject<Breakpoint> = new Subject<Breakpoint>();
  private currentBreakpoint: Breakpoint;

  constructor(
    logger: LoggerService
  ) { 
    this.logger = logger.getLogger('ResizeService');
  }

  public init(): void {
    this.logger.debug('Registering window events');

    window.addEventListener('load', () => {
      this.onResize();
    });

    window.addEventListener('resize', () => {
      this.onResize();
    });

    this.onResize();
  }

  public current(): Breakpoint {
    return this.currentBreakpoint;
  }

  public resized(): Observable<Breakpoint> {
    return this.resizedSubject;
  }

  private onResize(): void {
    this.currentBreakpoint = Breakpoint.XS;

    Object.keys(this.breakpoints).forEach(key => {
      if (this.isBreakpoint(this.breakpoints[key])) {
        this.currentBreakpoint = Breakpoint[key.toUpperCase()];
      }
    });

    this.logger.debug('Window resized, current breakpoint is', this.currentBreakpoint);

    this.resizedSubject.next(this.currentBreakpoint);
  }

  private isBreakpoint(rule: string): boolean {
    return window.matchMedia(rule).matches;
  }
}
