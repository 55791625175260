
<div *ngIf="show">

  <edit-modal (onHide)="cancel()">

    <edit-modal-header>
      <div class="row align-items-center table-header">
        <div class="col text-left">
          <button type="button" class="close btn-close" (click)="cancel()">
            <span class="material-icons icon-2x">close</span>
          </button>
        </div>
      </div>
    </edit-modal-header>

    <edit-modal-content>
      
      <div class="h2 mb-4">{{'confirmation.title'|translate}}</div>

      <div class="mb-5">
        <ng-content></ng-content>
      </div>

      <div class="row">
        <div class="col text-right">
          <div class="list-inline">
            <div class="list-inline-item">
              <button type="button" class="btn btn-outline-primary btn-action" (click)="cancel()">{{ cancelButton }}</button>
            </div>
            <div class="list-inline-item">
              <button type="button" class="btn btn-primary btn-action" (click)="confirm()">{{ confirmButton }}</button>
            </div>
          </div>
        </div>
      </div>

    </edit-modal-content>

  </edit-modal>

</div>