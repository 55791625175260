import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UserProfile } from '../../model/user-profile';
import { UpdateUserProfileDto } from '../../model/user-profile-dto';
import { QueryResult } from '../../query-filter/model/QueryResult';
import { ILogger, LoggerService } from '../../shared/logger.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/userprofile';
  private logger: ILogger;
  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('UserProfileService');
  }
  public getUserProfile(): Observable<QueryResult<UserProfile>> {
    this.logger.debug('Getting userprofiles');

    const url = this.apiUrl;

    return this.http.get<QueryResult<UserProfile>>(url).pipe(
      tap((result) => {
        this.logger.debug('result', result);
        this.logger.debug('getUserProfile result', result);
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No userprofiles found');
          return result;
        }

        this.logger.debug('{0} userprofiles found', result.data.length);
        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting userprofiles: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }

  public setUserProfile(userProfile: UpdateUserProfileDto): Observable<UserProfile> {
    this.logger.debug('Setting userprofile', userProfile);

    const method = 'PUT';
    const url = this.apiUrl;

    return this.http
      .request<UserProfile>(method, url, {
        body: userProfile
      })
      .pipe(
        tap((result) => {
          this.logger.debug('userprofile saved');

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating userprofile: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }

  public setSignature(file: File): Observable<any> {
    this.logger.debug('saving signature');

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const method = 'POST';
    const url = [this.apiUrl, 'signature'].join('/');

    return this.http
      .request<any>(method, url, {
        body: formData
      })
      .pipe(
        tap((_) => {
          this.logger.debug('signature save');
        }),
        catchError((err, caught) => {
          this.logger.error('Error importing hazards: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      );
  }


  public getSignature(): void {
    this.logger.debug('getting signature');

    const method = 'GET';
    const url = [this.apiUrl, 'signature'].join('/');

    this.http
      .request<Blob>(method, url, { responseType: 'blob' as 'json' })
      .pipe(
        tap((_) => {
          this.logger.debug('signature get');
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting signature: {0} - {1}', err['status'], err['message']);
          return throwError(err);
        })
      )
      .subscribe((blob) => {
        const createdUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = createdUrl;
        link.setAttribute('download', 'signature');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  public deleteSignature(): Observable<any> {
    this.logger.debug('deleting signature');

    const method = 'DELETE';
    const url = [this.apiUrl, 'signature'].join('/');

    return this.http.request<any>(method, url).pipe(
      tap((_) => {
        this.logger.debug('signature deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting signature: {0} - {1}', err['status'], err['message']);
        return throwError(err);
      })
    );
  }
}
