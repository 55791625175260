import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxElectronModule } from 'ngx-electron';
import { filter } from 'rxjs/operators';

import localeDe from '@angular/common/locales/de';
import localeUs from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeUk from '@angular/common/locales/uk';
import localeZh from '@angular/common/locales/zh';

import { ResolveEnd, Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthInterceptor } from './auth/internal/auth.interceptor';
import { AuthNGuardService } from './auth/shared/auth-n-guard.service';
import { IsAdministratorGuardService } from './auth/shared/is-administrator-guard.service';
import { ConfirmationModule } from './core/confirmation/confirmation.module';
import { ConfirmationService } from './core/confirmation/shared/confirmation.service';
import { CoreModule } from './core/core.module';
import { CanDeactivateGuardService } from './core/guard/can-deactivate-guard.service';
import { LayoutModule } from './core/layout/layout.module';
import { AppAnalytics } from './core/logging/shared/analytics';
import { NotificationsModule } from './core/notifications/notifications.module';
import { NotificationsService } from './core/notifications/shared/notifications.service';
import { AnalyticsErrorHandlerService } from './core/shared/analytics-error-handler.service';
import { BlobErrorInterceptor } from './core/shared/blob-error-interceptor';
import { LoggerService } from './core/shared/logger.service';
import { MessageBrokerService } from './core/shared/message-broker.service';
import { ModelService } from './core/shared/model.service';
import { QueueService } from './core/shared/queue.service';
import { ResponsiveService } from './core/shared/responsive.service';
import { SpinnerService } from './core/spinner/shared/spinner.service';
import { SpinnerModule } from './core/spinner/spinner.module';

// import environment specific providers from corresponding module (Web or UWP) - due to AOT
import { AppProvidersModule } from './app-providers.module';
import { RoleGuard } from './auth/shared/role-guard.service';
import { ClipboardService } from './core/shared/clipboard.service';
import { ComponentStateService } from './core/shared/component-state.service';
import { RequestFileInterceptor } from './core/shared/request-file-interceptor';
import { TranslationService } from './core/shared/translation.service';
import { IsInternalEngineerRoleGuard } from './auth/shared/is-internal-engineer-role.guard';


registerLocaleData(localeDe, 'de');
registerLocaleData(localeUk, 'en-UK');
registerLocaleData(localeUs, 'en-US');
registerLocaleData(localeKo, 'ko');
registerLocaleData(localeZh, 'zh');
registerLocaleData(localeJa, 'ja');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeFr, 'fr');

export function initAppAnalytics(injector: Injector) {
  return (): Promise<any> => new Promise<void>((resolve) => {
    if (AppAnalytics.init()) {
      const router = injector.get(Router);
      router.events
        .pipe(
          filter(event => event instanceof ResolveEnd)
        )
        .subscribe((event: ResolveEnd) => AppAnalytics.navigation(event.state.root, event.urlAfterRedirects));
    }

    resolve();
  });
}

export function initResponsiveService(responsiveService: ResponsiveService) {
  return (): Promise<any> => new Promise<void>((resolve) => {
    responsiveService.init();
    resolve();
  });
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

export function initTranslations(translationService: TranslationService) {
  return (): Promise<any> => translationService.init().toPromise();
}

export function getLanguage(translationService: TranslationService) {
  return translationService.currentLanguage || 'en';
}



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppProvidersModule,
    CoreModule,
    AuthModule,
    AppRoutingModule,
    LayoutModule,
    NotificationsModule,
    SpinnerModule,
    ConfirmationModule,
    NgbModule,
    HttpClientModule,
    NgxElectronModule
  ],
  providers: [
    TranslationService,
    { provide: APP_INITIALIZER, useFactory: initTranslations, deps: [TranslationService], multi: true },
    { provide: LOCALE_ID, deps: [TranslationService], useFactory: getLanguage },
    AuthNGuardService,
    IsAdministratorGuardService,
    RoleGuard,
    IsInternalEngineerRoleGuard,
    CanDeactivateGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BlobErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestFileInterceptor, multi: true},
    { provide: APP_INITIALIZER, useFactory: initAppAnalytics, deps: [Injector], multi: true },
    { provide: ErrorHandler, useClass: AnalyticsErrorHandlerService },
    LoggerService,
    ResponsiveService,
    { provide: APP_INITIALIZER, useFactory: initResponsiveService, deps: [ResponsiveService], multi: true },
    NotificationsService,
    SpinnerService,
    ConfirmationService,
    ComponentStateService,
    ClipboardService,
    MessageBrokerService,
    ModelService,
    QueueService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

