import { QueryResult } from '../model/QueryResult';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { QueryFilter } from '../model/QueryFilter';


export class QueryFilterHelper {

	public static preservePaging<T>(filter: QueryFilter, getObservable: () => Observable<QueryResult<T>>): Observable<QueryResult<T>> {
		return getObservable()
			.pipe(
				switchMap(result => {
					if (filter.adjustFrom(result.count)) { 
						return getObservable();
					} else {
						return of(result)
					}
				})
			);
	}

	public static apply<T>(filter: QueryFilter, items: Array<T>): QueryResult<T> {
		let result = [].concat(items || []);

		if (!filter || !result || result.length === 0) {
			return {
				data: result,
				count: result && result.length || 0
			}
		}

		const getProperty = (path, object) => {
			return path.reduce((xs, x) => (xs && xs[x] != null) ? xs[x] : null, object);
		};

		const wheres = filter.whereArray();

		if (wheres) {
			Object.keys(wheres).forEach(property => {
				const conditions = wheres[property];
				const path = property.split('.');

				Object.keys(conditions).forEach(condition => {
					const whereConditions = {
						$contains: (item: T, value: string): boolean => {
							const itemProperty = getProperty(path, item);
							return itemProperty && typeof itemProperty === 'string' && itemProperty.search(new RegExp(value, 'i')) > -1;
						},
						$eq: (item: T, value: string): boolean => {
							const itemProperty = getProperty(path, item);
							return itemProperty === value;
						},
						$gt: (item: T, value: any): boolean => {
							const itemProperty = getProperty(path, item);

							if (typeof value.getMonth === 'function') {
								return new Date(itemProperty.valueOf()) > value.valueOf();
							} else {
								return itemProperty > value;
							}
						},
						$gte: (item: T, value: any): boolean => {
							const itemProperty = getProperty(path, item);

							if (typeof value.getMonth === 'function') {
								return new Date(itemProperty).valueOf() >= value.valueOf();
							} else {
								return itemProperty >= value;
							}
						},
						$lt: (item: T, value: any): boolean => {
							const itemProperty = getProperty(path, item);

							if (typeof value.getMonth === 'function') {
								return new Date(itemProperty.valueOf()) < value.valueOf();
							} else {
								return itemProperty < value;
							}
						},
						$lte: (item: T, value: any): boolean => {
							const itemProperty = getProperty(path, item);

							if (typeof value.getMonth === 'function') {
								return new Date(itemProperty.valueOf()) <= value.valueOf();
							} else {
								return itemProperty <= value;
							}
						},
						$in: (item: T, values: Array<string>): boolean => {
							const itemProperty = getProperty(path, item);
							return values && Array.isArray(values) && values.includes(itemProperty) || false;
						},
						$exists: (item: T, value: boolean): boolean => {
							const itemProperty = getProperty(path, item);
							if (value === true) {
								return itemProperty != null;
							} else {
								itemProperty == null;
							}
						}
					}

					result = result.filter(item => {
						const filterMethod = whereConditions[condition];

						if (!filterMethod) {
							throw Error(`Condition ${condition} not found`);
						}

						return filterMethod(item, conditions[condition]);
					});
				});
			});
		}

		const orders = filter.ordersArray();

		if (orders && orders.length > 0) {
			orders.forEach(order => {
				const descending = order.startsWith('-');
				const direction = descending ? -1 : 1;
				const property = descending ? order.substring(1) : order;
				const path = property.split('.');

				result = result.sort((a: T, b: T) => {
					const aProperty = getProperty(path, a);
					const bProperty = getProperty(path, b);

					if (!aProperty && !bProperty) return 0;
					if (!aProperty && bProperty) return descending ? 1 : -1;
					if (aProperty && !bProperty) return descending ? -1 : 1;

					if (typeof aProperty === 'number') {
						return direction * (aProperty - bProperty);
					} else if (typeof aProperty === 'string') {
						return direction * (aProperty.localeCompare(bProperty));
					}
				});
			});
		}

		const count = result.length;

		if (filter.offset < result.length && filter.limit > 0) {
			const start = filter.offset;
			const end = filter.offset + filter.limit;
			const last = result.length;

			result = result.slice(start, end > last ? last : end);
		}

		return {
			data: result,
			count: count
		};
	}
}