import { Directive, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';

@Directive({
  selector: '[isEngineerOrPowerEngineer]'
})
export class IsEngineerOrPowerEngineerDirective implements OnInit {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

  public ngOnInit(): void {
    if (this.authService.isEngineer() || this.authService.isPowerEngineer()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
