import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { first } from 'rxjs/operators';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { EditModalComponent } from '../../edit-modal/edit-modal/edit-modal.component';
import { Confirmation } from '../model/Confirmation';
import { ConfirmationService } from '../shared/confirmation.service';

@Component({
  selector: 'modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit, OnDestroy, Confirmation {
  @ViewChild(EditModalComponent) editModal: EditModalComponent;

  @Input() name: string;
  @Input() group: string;
  @Input('confirm') confirmButton: string = 'modal.confirm';
  @Input('cancel') cancelButton: string = 'modal.cancel';

  @Input()
  get show(): boolean {
    return this.isShowing;
  }
  set show(val: boolean) {
    this.isShowing = val;
    this.showChange.emit(this.isShowing);
  }

  @Output() showChange = new EventEmitter();

  private isShowing = false;

  constructor(private confirmationService: ConfirmationService, private translateService: TranslateService) {}

  public ngOnInit(): void {
    if (!this.name) throw new Error("Confirmation must have a 'name' attribute.");

    this.confirmationService.register(this);

    this.confirmButton = this.translateService.instant(this.confirmButton);
    this.cancelButton = this.translateService.instant(this.cancelButton);
  }

  public ngOnDestroy(): void {
    this.confirmationService.unregister(this);
  }

  public cancel(): void {
    this.editModal
      .hide()
      .pipe(first())
      .subscribe((_) => {
        this.show = false;
        this.confirmationService.cancel(this);
      });
  }

  public confirm() {
    this.editModal
      .hide()
      .pipe(first())
      .subscribe((_) => {
        this.show = false;
        this.confirmationService.confirm(this);
      });
  }

  public scrollToConfirmation(): void {
    //not needed here
  }
}
