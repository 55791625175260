import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { Site } from '../model/site';

@Injectable()
export class InternalSitesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/sites';
  private logger: ILogger;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('InternalSitesService');
  }

  public save(site: Site): Observable<Site> {
    this.logger.debug('Saving site with id', site.id);

    const method = site.id != null ? 'PUT' : 'POST';
    const url = this.apiUrl + (site.id != null ? '/' + site.id : '');

    return this.http
      .request<Site>(method, url, {
        body: site
      })
      .pipe(
        tap((result) => {
          this.logger.debug('Site saved');
          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error updating site: {0} - {1}', err['status'] || err['code'], err['message']);
          return observableThrowError(err);
        })
      );
  }

  public delete(site: Site): Observable<void> {
    this.logger.debug('Deleting site with id', site.id);

    return this.http.delete<void>(this.apiUrl + '/' + site.id).pipe(
      tap((result) => {
        this.logger.debug('Site deleted');
      }),
      catchError((err, caught) => {
        this.logger.error('Error deleting site: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
