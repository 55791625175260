import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PhotosURLResolver } from '../../machine-photos/shared/photos-url-resolver';
import { environment } from '../../../../environments/environment';
import { Photo } from '../../machine-services/model/photo';
import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class MachineHazardPhotosURLResolverService {

  private apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('MachineHazardPhotosURLResolverService');
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): PhotosURLResolver {
    const projectId = route.params['projectId'];
    const machineId = route.params['machineId'];
    const hazardId = route.params['hazardId'];

    this.logger.debug('Creating MachineHazardPhotosURLResolverService for project {0} and machine {1} and hazard {2}', projectId, machineId, hazardId);


    return {
      getURL: (photo: Photo, size: string, extra?: string): Observable<string> => {
        const url =
          [
            this.apiUrl,
            projectId,
            'projectmachines',
            machineId,
            'hazards',
            hazardId,
            'photos',
            photo.id
          ].join('/') + '?size=' + size + (extra ? '&' + extra : '');

        return this.http.get(url, { responseType: 'blob' }).pipe(map((blob) => window.URL.createObjectURL(blob)));
      }
    };
  }
}
