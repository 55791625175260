import { Injectable } from '@angular/core';

import Logger from 'log4ts/build/Logger';
import BasicLayout from 'log4ts/build/layouts/BasicLayout';
import ConsoleAppender from 'log4ts/build/appenders/ConsoleAppender';
import LoggerConfig from 'log4ts/build/LoggerConfig';
import { AnalyticsAppender } from '../logging/internal/analytics-appender';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoggerService {
  public log: Logger;

  constructor() {
    const logLevel: number = environment.logLevel;

    const config = new LoggerConfig();
    config.setLevel(logLevel);

    const layout = new BasicLayout();
    const analyticsAppender = new AnalyticsAppender();
    const consoleAppender = new ConsoleAppender();
    consoleAppender.setLayout(layout);

    config.addAppender(analyticsAppender);
    config.addAppender(consoleAppender);

    Logger.setConfig(config);
  }

  getLogger(forClass?: string): ILogger {
    return new Logger(forClass);
  }
}

export interface ILogger {
  info(message: string, ...params: any[]);
  fatal(message: string, ...params: any[]);
  error(message: string, ...params: any[]);
  debug(message: string, ...params: any[]);
  warn(message: string, ...params: any[]);
  trace(message: string, ...params: any[]);
}
