import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, filter, first, finalize } from 'rxjs/operators';

import { LoggerService, ILogger } from '../../../core/shared/logger.service';
import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';
import { environment } from '../../../../environments/environment';
import { ProjectMachine } from '../model/project-machine';

@Injectable()
export class ProjectMachinesService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;
  private isLoadingMachine: { [key: string]: boolean } = {};
  private machinesResolver: { [key: string]: BehaviorSubject<ProjectMachine> } = {};
  private machinesTimeout: { [key: string]: any } = {};
  private projectId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('ProjectMachinesService');
  }

  public setProject(id: string): ProjectMachinesService {
    this.projectId = id;
    return this;
  }

  public getProjectMachines(queryFilter?: QueryFilter): Observable<QueryResult<ProjectMachine>> {
    this.logger.debug('Getting project machines for project with id', this.projectId);

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    const url = [this.apiUrl, this.projectId, 'projectmachines'].join('/');

    return this.http.put<QueryResult<ProjectMachine>>(url, queryFilter && queryFilter.toJson()).pipe(
      tap((result) => {
        if (!result || !result.data || result.data.length === 0) {
          this.logger.debug('No project machines found');
          return result;
        }

        this.logger.debug('{0} project machines found', result.data.length);

        return result;
      }),
      catchError((err) => {
        this.logger.error('Error getting project machines: {0} - {1}', err['status'], err['message']);
        return observableThrowError(err);
      })
    );
  }

  public getProjectMachine(id: string): Observable<ProjectMachine> {
    this.logger.debug('Getting project machine with id {0} for project {1}', id, this.projectId);

    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    let resolver = this.machinesResolver[id];

    if (!resolver) {
      resolver = this.machinesResolver[id] = new BehaviorSubject<ProjectMachine>(undefined);
    }

    if (resolver.getValue() === undefined && !this.isLoadingMachine[id]) {
      this.logger.debug('Project machine {0} is not loaded, processing', id);

      this.isLoadingMachine[id] = true;
      if (this.machinesTimeout[id]) {
        clearTimeout(this.machinesTimeout[id]);
      }

      const url = [this.apiUrl, this.projectId, 'projectmachines', id].join('/');

      this.http
        .get<ProjectMachine>(url)
        .pipe(
          first(),
          finalize(() => (this.isLoadingMachine[id] = false))
        )
        .subscribe(
          (result) => {
            if (!result) {
              this.logger.debug('No project machine found');
            } else {
              this.logger.debug('Project machine found');
            }

            this.machinesTimeout[id] = setTimeout((_) => {
              //cache for 2 seconds and then clear
              resolver.next(undefined);
            }, 2000);

            resolver.next(result);
          },
          (error) => {
            this.logger.error('Error getting project machine: {0} - {1}', error['status'] || error['code'], error['message']);
            resolver.error(error);
          }
        );
    } else {
      this.logger.debug('Project machine {0} is cached, returning', id);
    }

    return resolver.pipe(filter((result) => result !== undefined));
  }
}
