
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { HttpClient } from '@angular/common/http';
import { HazardGroup } from '../model/hazard-group';
import { tap, catchError} from 'rxjs/operators';

import { QueryResult } from '../../../core/query-filter/model/QueryResult';
import { QueryFilter } from '../../../core/query-filter/model/QueryFilter';

@Injectable()
export class HazardGroupsService {

  private readonly apiUrl: string = environment.serverUrl + '/v1/HazardGroups';
  private logger: ILogger;

  constructor(
    private http: HttpClient,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('HazardGroupsService');
  }

  public getHazardGroups(filter?: QueryFilter): Observable<QueryResult<HazardGroup>> {
    this.logger.debug('Getting hazard groups');

    let url = this.apiUrl;

    if (filter) {
      url += filter.toQueryString();
    }

    return this.http.get<QueryResult<HazardGroup>>(url)
      .pipe(
        tap(result => {
          if (!result || !result.data || result.data.length === 0) {
            this.logger.debug('No hazardGroups found');
            return result;
          }

          this.logger.debug('{0} hazardGroups found', result.data.length);

          return result;
        }),
        catchError((err, caught) => {
          this.logger.error('Error getting hazardGroups: {0} - {1}', err['status'], err['message']);
          return observableThrowError(err);
        })
      );
  }

}
