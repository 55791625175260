import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Examination } from '../../../settings-modules/assessment-types/model/Examination';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { MachineAssessmentExamination } from '../../machine-services/model/machine-assessment-examination';

@Injectable()
export class ExaminationsService {
  private readonly apiUrl: string = environment.serverUrl + '/v1/projects';
  private logger: ILogger;

  private projectId: string;
  private machineId: string;

  constructor(private http: HttpClient, logger: LoggerService) {
    this.logger = logger.getLogger('ExaminationsService');
  }

  public setProject(id: string): ExaminationsService {
    this.projectId = id;
    return this;
  }

  public setProjectMachine(id: string): ExaminationsService {
    this.machineId = id;
    return this;
  }

  public getExamination(id: string): Observable<MachineAssessmentExamination> {
    if (!this.projectId) {
      throw Error('Project Id not set. Use setProject() first');
    }

    if (!this.machineId) {
      throw Error('Project Machine Id not set. Use setProjectMachine() first');
    }

    this.logger.debug('Getting examination with id {0} for machine {1} from project {2}', id, this.machineId, this.projectId);

    let url = [this.apiUrl, this.projectId, 'projectmachines', this.machineId, 'examinations', id].join('/');

    return this.http.get<MachineAssessmentExamination>(url).pipe(
      tap((result) => {
        if (!result) {
          this.logger.debug('No machine examination found');
          return result;
        }

        this.logger.debug('Machine examination found');

        return result;
      }),
      catchError((err, caught) => {
        this.logger.error('Error getting machine examination: {0} - {1}', err['status'] || err['code'], err['message']);
        return observableThrowError(err);
      })
    );
  }
}
