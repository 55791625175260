<div class="row">
  <div class="col-auto">
    <label for="fontsize">{{'menu.font-size' | translate}}</label>
  </div>
  <div class="col text-right">
    <ng-template #tooltipFontsize>
      <div class="tooltip-content">
        <label>{{'menu.tooltip.font-size'|translate}}</label>
        <p>{{'menu.tooltip.font-size-content'|translate}}</p>
      </div>
    </ng-template>
    <button type="button" class="btn btn-link btn-tooltip" [ngbTooltip]="tooltipFontsize" placement="top-right" triggers="click"
      tabindex="-1">
      <i class="material-icons">help</i>
    </button>
  </div>
</div>
<div class="row align-items-center">
  <div class="col-auto">
    {{'menu.enable-large-fonts'|translate}}
  </div>
  <div class="col text-right">
    <span class="switch">
      <input type="checkbox" class="switch" [checked]="large" (change)="toggle()" id="font-size">
      <label for="font-size"></label>
    </span>
  </div>
</div>
