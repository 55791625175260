import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sweh'
})
export class SwehPipe implements PipeTransform {
  transform(value: string, isSweh: boolean): string {
    return (isSweh && value === 'FES' ? 'SWEH' : value);
  }
}
