import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DxDataGridModule } from 'devextreme-angular';
import { DateValueAccessorDirective } from './accessor/date-value-accessor.directive';
import { NumberValueAccessorDirective } from './accessor/number-value-accessor.directive';
import { AutofocusDirective } from './directive/autofocus.directive';
import { AutosizeDirective } from './directive/autosize.directive';
import { IsDesktopDirective } from './directive/is-desktop.directive';
import { IsNotDesktopDirective } from './directive/is-not-desktop.directive';
import { StopEnterPropagationDirective } from './directive/stop-enter-propagation.directive';
import { GridComponent } from './grid/grid.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoPlaceholderComponent } from './logo-placeholder/logo-placeholder.component';
import { CalendarPipe } from './pipe/calendar.pipe';
import { DateFormatedPipe } from './pipe/dateFormated.pipe';
import { FromDatePipe } from './pipe/from-date.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';
import { FormService } from './shared/form.service';
import { SubGridComponent } from './grid/sub-grid/sub-grid.component';
import { MinMaxEditComponent } from './grid/min-max-edit/min-max-edit.component';
import { EditModalModule } from './edit-modal/edit-modal.module';
import { ConfirmationModule } from './confirmation/confirmation.module';
import { RequiredLabelDirective } from './directive/required-label.directive';
import { SwehPipe } from './pipe/sweh.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    DragDropModule,
    DxDataGridModule,
    EditModalModule,
    ConfirmationModule
  ],
  declarations: [
    LoadingComponent,
    NumberValueAccessorDirective,
    AutofocusDirective,
    AutosizeDirective,
    StopEnterPropagationDirective,
    DateValueAccessorDirective,
    TruncatePipe,
    IsDesktopDirective,
    IsNotDesktopDirective,
    FromDatePipe,
    DateFormatedPipe,
    CalendarPipe,
    LogoPlaceholderComponent,
    GridComponent,
    SubGridComponent,
    MinMaxEditComponent,
    RequiredLabelDirective,
    SwehPipe
  ],
  providers: [FormService, DateFormatedPipe],
  exports: [
    AutofocusDirective,
    AutosizeDirective,
    NumberValueAccessorDirective,
    StopEnterPropagationDirective,
    DateValueAccessorDirective,
    TruncatePipe,
    IsDesktopDirective,
    IsNotDesktopDirective,
    FromDatePipe,
    DateFormatedPipe,
    CalendarPipe,
    LogoPlaceholderComponent,
    GridComponent,
    SubGridComponent,
    MinMaxEditComponent,
    RequiredLabelDirective,
    SwehPipe
  ]
})
export class CoreModule {}
