import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';

import { SpinnerService } from '../shared/spinner.service';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {
  
  constructor(private spinnerService: SpinnerService) { }

  @Input() name: string;
  @Input() group: string;

  private isShowing = false;

  @Input()
  get show(): boolean {
    return this.isShowing;
  }

  @Output() showChange = new EventEmitter();

  set show(val: boolean) {
    this.isShowing = val;
    this.showChange.emit(this.isShowing);
  }

  ngOnInit(): void {
    if (!this.name) throw new Error("Spinner must have a 'name' attribute.");

    this.spinnerService.register(this);
  }

  ngOnDestroy(): void {
    this.spinnerService.unregister(this);
  }
}
