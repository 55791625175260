import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ElectronService } from 'ngx-electron';
import { Observable, forkJoin } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthUser } from '../../../auth/model/AuthUser';
import { AuthService } from '../../../auth/shared/auth.service';
import { Company } from '../../../settings-modules/companies/model/company';
import { Organization } from '../../../settings-modules/companies/model/organization';
import { OrganizationsService } from '../../../settings-modules/companies/shared/organizations.service';
import { UserNotification } from '../../../user-notifications/model/user-notification';
import { UserNotificationsService } from '../../../user-notifications/shared/user-notifications.service';
import { ConfirmationService } from '../../confirmation/shared/confirmation.service';
import { SyncState } from '../../model/sync-state';
import { QueryFilter } from '../../query-filter/model/QueryFilter';
import { Form, FormService } from '../../shared/form.service';
import { ILogger, LoggerService } from '../../shared/logger.service';
import { SynchronizationService } from '../../shared/synchronization.service';
import { TranslationService } from '../../shared/translation.service';
import { SpinnerService } from '../../spinner/shared/spinner.service';
import { Role } from '../../../auth/model/Role';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public environmentName: string = environment.name;
  public installationUrl: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(environment.installationUrl);
  public version = `v${environment.version}`;
  public currentLanguage: string;
  public hidden: { [key: string]: boolean } = {
    sync: true,
    notifications: true,
    user: true
  };
  public organization: Organization;
  public syncState: SyncState;
  public syncDate: Observable<Date>;
  public isOnline: Observable<boolean>;
  public notifications: Array<UserNotification>;
  public showNotificationsBadge = false;
  public notificationsCount = 0;
  public organizations: Array<Organization> = [];
  public currentOrganization: string;
  public form: Form;
  dashboardLink: string[];

  zIndex = 59;

  showNavBarIcons = true;
  private logger: ILogger;

  //TODO: missing translation
  private displayRoles = {
    administrator: 'Administrator',
    engineer: 'Engineer',
    powerengineer: 'Power Engineer',
    customeradministrator: 'Customer Administrator',
    customerengineer: 'Customer Engineer',
    customerimplementer: 'Customer Engineer Lite',
    customerreader: 'Customer Reader'
  };

  private readonly helpLinks: { [key: string]: string } = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/dashboard': '/Dashboard.65597.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/settings/companies': '/Companies.720972.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/settings/machines': '/Machines.753740.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/settings/sites': '/Sites.786546.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/settings/assessments': '/Assessment-Types.753747.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/settings/hazards': '/Hazards.786563.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/settings/standards': '/Standards.753754.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/settings/users': '/Users.720989.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/settings/machine-types': '/Machine-Types.786570.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects': '/Projects.33070.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/project': '/Project-Overview.688270.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/machines': '/Project-Machines.753781.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/assessment': '/Assessment.753798.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/hazards': '/Hazards_.754161.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/controlintegrities': '/Control-Integrities.754225.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/measurements': '/Measurements.721428.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/components': '/Components.721446.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/photos': '/Machine-Photos.688812.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/standards': '/Standards_.754287.html ',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/declarations': '/Declarations.754310.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/projects/documents': '/Documents.94404613.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/documents': '/Documents.94404613.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/implementations': '/Implementations.196698.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/implementations/machines': '/Assessed-Machines.688284.html',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/implementations/hazards': '/Hazard-Implementation.753815.html'
  };

  constructor(
    public authService: AuthService,
    private router: Router,
    private organizationsService: OrganizationsService,
    private formService: FormService,
    private translationService: TranslationService,
    private sanitizer: DomSanitizer,
    private syncService: SynchronizationService,
    private userNotificationsService: UserNotificationsService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService,
    private electron: ElectronService,
    logger: LoggerService
  ) {
    this.logger = logger.getLogger('LayoutComponent');
  }

  public ngOnInit(): void {
    this.logger.debug('Initializing component');
    this.currentLanguage = this.translationService.currentLanguage;
    this.currentOrganization = this.authService.user.organization;

    this.syncService.synchronization().subscribe((result) => (this.syncState = result));
    this.syncDate = this.syncService.lastSynchronization();
    this.isOnline = this.syncService.isOnline();

    this.form = this.formService.form({
      organization: []
    });

    this.form
      .get('organization')
      .valueChanges.pipe(
        filter((toggledOrganization) => this.organization?.id !== toggledOrganization?.id),
        tap((toggledOrganization) => {
          this.changeOrganization(toggledOrganization);
        })
      )
      .subscribe();

    this.refreshNotifications();

    //this should be for all internal users.
    // if (this.authService.isEngineer() || this.authService.isPowerEngineer()) { isEngineer also returns true for customer engineer!
    if (this.authService.hasAtLeastOneOfGivenRoles([Role.engineer, Role.powerEngineer])) {
      this.dashboardLink = ['/dashboard', 'internal-dashboard'];
    } else {
      this.dashboardLink = ['/dashboard'];
    }
  }

  public canViewDashboard(): boolean {
    return (
      this.authService.isEngineer() ||
      this.authService.isPowerEngineer() ||
      this.authService.isCustomerImplementer() ||
      this.authService.isCustomerManufacturer() ||
      this.authService.isReader()
    );
  }

  public canViewAssessments(): boolean {
    return (
      this.authService.isEngineer() ||
      this.authService.isPowerEngineer() ||
      this.authService.isCustomerImplementer() ||
      this.authService.isCustomerManufacturer() ||
      this.authService.isReader()
    );
  }

  public canViewDocuments(): boolean {
    return (
      this.authService.isEngineer() ||
      this.authService.isPowerEngineer() ||
      this.authService.isCustomerImplementer() ||
      this.authService.isCustomerManufacturer()
    );
  }

  public canViewImplementations(): boolean {
    return (
      this.authService.isEngineer() ||
      this.authService.isPowerEngineer() ||
      this.authService.isCustomerImplementer() ||
      this.authService.isReader()
    );
  }

  public changeLanguage(language: string): void {
    this.logger.debug('Changing language to', language);
    this.translationService.changeLanguage(language).pipe(first()).subscribe();
  }

  public changeOrganization(organization: Organization): void {
    this.logger.debug('Changing organization to', organization);
    this.authService.changeOrganization(organization.id);
    this.organization = organization;
  }

  public synchronize(): void {
    this.logger.debug('Running synchronization');
    this.syncService.synchronize().pipe(first()).subscribe();
  }

  public isDesktopDevelopment(): boolean {
    return this.electron && this.electron.isElectronApp;
  }

  public showDevTools(): void {
    if (this.electron && this.electron.isElectronApp) {
      this.electron.ipcRenderer.send('show-dev-tools');
    }
  }

  public getAvatarClass(id: string): string {
    let index = 0;

    if (id) {
      id.replace(/[^0-9]/g, '')
        .split('')
        .forEach((i) => (index += +i));
    }

    const classes = [
      'avatar-green',
      'avatar-blue',
      'avatar-yellow',
      'avatar-red',
      'avatar-turquis',
      'avatar-brown',
      'avatar-lila',
      'avatar-orange'
    ];
    return classes[index % 8];
  }

  public formatRoles(user: AuthUser): string {
    if (!user) {
      return null;
    }

    if (user.roles) {
      return user.roles
        .sort()
        .map((role) => this.displayRoles[role.toLowerCase()])
        .join(', ');
    }
  }

  public formatDivisions(divisions: string | Array<string>): string {
    if (!this.organization || !divisions) {
      return null;
    }

    if (Array.isArray(divisions)) {
      let formattedDivisions = divisions
        .map((division) => {
          const company = this.organization as Company;
          const companyDivision = company.divisions.find((item) => item.id === division);
          return companyDivision && companyDivision.name;
        })
        .join(', ');

      if (formattedDivisions === null || formattedDivisions === undefined) {
        formattedDivisions = '';
      }
      return formattedDivisions;
    } else {
      const company = this.organization as Company;
      const companyDivision = company.divisions.find((item) => item.id === divisions);
      let formattedDivision = companyDivision && companyDivision.name;

      if (formattedDivision === null || formattedDivision === undefined) {
        formattedDivision = '';
      }
      return formattedDivision;
    }
  }

  public formatSites(sites: string | Array<string>): string {
    if (!this.organization || !sites) {
      return null;
    }

    if (Array.isArray(sites)) {
      let formattedSites = sites
        .map((site) => {
          const company = this.organization as Company;
          const companySite = company.sites.find((item) => item.id === site);
          return companySite && companySite.name;
        })
        .join(', ');

      if (formattedSites === null || formattedSites === undefined) {
        formattedSites = '';
      }
      return formattedSites;
    } else {
      const company = this.organization as Company;
      const companySite = company.sites.find((item) => item.id === sites);
      let formattedSite = companySite && companySite.name;

      if (formattedSite === null || formattedSite === undefined) {
        formattedSite = '';
      }

      return formattedSite;
    }
  }

  public logout(): void {
    this.logger.debug('Logging out');

    if (environment.uwp === true) {
      this.toggle('user');

      this.confirmationService
        .show('user-logout')
        .pipe(first())
        .subscribe((result) => {
          if (result) {
            this.spinnerService.show('clear-data');
            this.authService.logout();
          }
        });
    } else {
      this.authService.logout();
    }
  }

  public editProfile(): void {
    this.logger.debug('Edit profile');
    this.authService.editProfile();
  }

  public keyup(event: any, menu: string): void {
    if (event.key === 'Escape') {
      this.hidden[menu] = true;
    }
  }

  public isSiteRestricted() {
    return this.authService.isRestricted();
  }

  public help(): void {
    this.logger.debug('Showing help');

    const url = this.router.url;
    let helpUrl = '/';

    if (url.startsWith('/dashboard')) {
      helpUrl = '/dashboard';
    } else if (url.startsWith('/settings')) {
      const segments = url.split('/');

      if (segments.length > 6) {
        // this is for companies -> sites -> machines
        const segment = segments[6];

        if (segment === 'machines') {
          helpUrl = `/settings/${segment}`;
        }
      } else if (segments.length > 4) {
        const segment = segments[4];

        helpUrl = '/settings/assessments';
        if (segment === 'machines' || segment === 'sites') {
          helpUrl = `/settings/${segment}`;
        }
      } else {
        const segment = segments[2];
        if (segments.length > 2) {
          helpUrl = `/settings/${segment}`;
        }
      }
    } else if (url.startsWith('/assessments')) {
      const segments = url.split('/');

      if (segments.length > 5) {
        const segment = segments[5];
        helpUrl = `/projects/${segment}`;
      } else {
        if (segments.length <= 5) {
          helpUrl = '/projects/machines';
        }
        if (segments.length <= 3) {
          helpUrl = '/projects/project';
        }
        if (segments.length <= 2) {
          helpUrl = '/projects';
        }
      }
    } else if (url.startsWith('/implementations')) {
      const segments = url.split('/');

      helpUrl = '/implementations';

      if (segments.length >= 4) {
        helpUrl = '/implementations/machines';
      }
      if (segments.length > 6) {
        helpUrl = '/implementations/hazards';
      }
    } else if (url.startsWith('/documents')) {
      helpUrl = '/documents';
    }

    if (this.helpLinks[helpUrl]) {
      helpUrl = this.helpLinks[helpUrl];
    } else {
      helpUrl = '/';
    }

    this.logger.debug('Determined help url {0} for {1}, opening', helpUrl, url);

    window.open(environment.helpUrl + helpUrl, 'mcom_help');
  }

  public manual(): void {
    this.logger.debug('Redirecting to help');
    window.open(environment.helpUrl, '_blank');
  }

  public toggle(menu: string): void {
    this.hidden[menu] = !this.hidden[menu];

    if (this.hidden[menu] === false) {
      Object.keys(this.hidden)
        .filter((key) => key !== menu)
        .forEach((key) => (this.hidden[key] = true));
    }

    if (menu === 'notifications' && this.hidden[menu]) {
      this.resetNotificationsLastSeen();
    }

    if (!this.hidden[menu] && !this.organization) {
      const organizationId = this.authService.user.organization;

      this.logger.debug('Loading organization {0} from user {1}', organizationId, this.authService.user.id);

      this.organizationsService
        .getOrganization(organizationId)
        .pipe(first())
        .subscribe((organization) => {
          this.organization = organization;
        });
    }

    if (!this.hidden[menu]) {
      const observables: Array<Observable<Organization>> = [];
      this.organizations = [];
      this.authService.user.organizations.forEach((organizationsId) => {
        observables.push(
          this.organizationsService.getOrganization(organizationsId).pipe(tap((organization) => this.organizations.push(organization)))
        );
      });
      forkJoin(observables).subscribe((_) => {
        this.organizations = [...this.organizations];
        this.form.get('organization').patchValue(this.organizations.find((o) => o.id === this.authService.user.organization));
      });
    }
  }

  public refreshNotifications(): void {
    this.logger.debug('Refreshing notifications');

    this.notifications = [];
    this.spinnerService.show('layout-user-notifications');

    this.userNotificationsService
      .getNotifications(new QueryFilter().take(5).descending('createdAt'))
      .pipe(first())
      .subscribe(
        (result) => {
          this.spinnerService.hide('layout-user-notifications');

          this.notifications = (result && result.data) || [];
          this.notificationsCount = (result && result.count) || 0;

          const lastSeen = (this.getNotificationsLastSeen() && moment(this.getNotificationsLastSeen())) || moment(0);

          this.notifications.forEach((item) => {
            const createdAt = moment(item.createdAt);

            if (createdAt.isAfter(lastSeen)) {
              this.showNotificationsBadge = true;
              item['isNew'] = true;
            }
          });
        },
        (error) => {
          //do not show any error, just leave table empty
          this.spinnerService.hide('layout-user-notifications');
        }
      );
  }

  public notificationsNotFound(): boolean {
    return !this.spinnerService.isShowing('layout-user-notifications') && this.notifications && this.notifications.length === 0;
  }

  public formatNotificationType(notification: UserNotification): string {
    if (!notification || !notification.type) {
      return null;
    }

    const handler = this.userNotificationsService.getHandler(notification);
    return handler.formatTitle(notification);
  }

  public formatNotification(notification: UserNotification): string {
    const handler = this.userNotificationsService.getHandler(notification);
    return handler.formatDescription(notification);
  }

  public selectNotification(notification: UserNotification): void {
    this.logger.debug('Selecting notification');

    this.toggle('notifications');

    //wait for panel closing
    setTimeout((_) => {
      const handler = this.userNotificationsService.getHandler(notification);
      handler.navigate(notification).pipe(first()).subscribe();
    }, 150);
  }

  public resetNotificationsLastSeen(): void {
    this.notifications.forEach((item) => delete item['isNew']);
    this.showNotificationsBadge = false;

    this.setNotificationsLastSeen(new Date());
  }

  // might not need this for css fix
  toggleHigherZIndex(goHigher: boolean) {
    if (goHigher) {
      this.zIndex++;
    } else {
      this.zIndex--;
    }
  }

  toggleNavBarIcons(goHigher: boolean) {
    this.showNavBarIcons = !goHigher;
  }

  canViewEquipmentMenuAndAutoSavePanel(): boolean {
    return environment.featureFlags.measurements && this.authService.hasAtLeastOneOfGivenRoles([Role.engineer, Role.powerEngineer]);
  }

  getSafeHTML(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  private getNotificationsLastSeen(): Date {
    const lastSeen = localStorage.getItem(`mcomplus/${this.authService.user.id}/notifications/lastSeen`);

    if (lastSeen) {
      return new Date(lastSeen);
    }

    return null;
  }

  private setNotificationsLastSeen(lastSeen: Date) {
    localStorage.setItem(`mcomplus/${this.authService.user.id}/notifications/lastSeen`, lastSeen.toISOString());
  }
}
